export default {
    keywords: () => {
        return [
            "computadora",
            "computadoras",
            "laptop",
            "laptops",
            "notebook",
            "notebooks",
            "tablet",
            "tablets",
            "accesorio",
            "accesorio para computadora",
            "accesorios para computadoras",
            "teclado",
            "teclados",
            "mouse",
            "mouses",
            "monitor",
            "monitores",
            "pantalla",
            "pantallas",
            "impresor",
            "impresora",
            "impresoras",
            "escáner",
            "escáneres",
            "auricular",
            "auriculares",
            "altavoz",
            "altavoces",
            "cable",
            "cable HDMI",
            "cables HDMI",
            "cable USB",
            "cables USB",
            "alimentación",
            "cable de alimentación",
            "cables de alimentación",
            "cable de red",
            "cables de red",
            "cargador",
            "cargadores",
            "batería",
            "baterías",
            "adaptador",
            "adaptadores",
            "base para laptop",
            "bases para laptop",
            "maletín para laptop",
            "maletines para laptop",
            "protector de pantalla",
            "protectores de pantalla",
            "soporte para monitor",
            "soportes para monitor",
            "almohadilla para mouse",
            "almohadillas para mouse",
            "limpiador",
            "limpiador de pantalla",
            "limpiadores de pantalla",
            "tarjeta",
            "video",
            "red",
            "sonido",
            "tarjeta de sonido",
            "tarjetas de sonido",
            "tarjeta de red",
            "tarjetas de red",
            "tarjeta gráfica",
            "tarjetas gráficas",
            "placa madre",
            "placas madre",
            "cámara",
            "cámara web",
            "cámaras web",
            "micrófono",
            "micrófonos",
            "almacenamiento",
            "unidad de almacenamiento",
            "unidades de almacenamiento",
            "disco duro",
            "discos duros",
            "disco SSD",
            "discos SSD",
            "disco duro externo",
            "discos duros externos",
            "RAM",
            "memoria RAM",
            "memorias RAM",
            "procesador",
            "procesadores",
            // "sistema operativo",
            // "sistemas operativos",
            "software",
            "tarjeta de memoria",
            "tarjetas de memoria",
            "estabilizador de voltaje",
            "estabilizadores de voltaje",
            "carga",
            "cable de carga",
            "inalámbrica",
            "inalámbrico",
            "inalámbricos",
            "inalambrica",
            "inalambrico",
            "inalambricos",
            "smartwatch"
        ];
    } 
}