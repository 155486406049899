
export default {
    add: (productT,emitter,notify,cookies,router) => {
      let COMPARES = cookies.get("compares") ? cookies.get("compares") : [];

      let INDEX = COMPARES.findIndex(product => product.uniqd == productT.uniqd);
      
      if(INDEX != -1){
          notify({
              title: "Producto agregado a comparar",
              // text: `Tu producto '${productT.title}' ya se encuentra en la lista de comparación!`,
              type:'error',
              duration: 4000,
          });
          return;
      }else{
          COMPARES.push({
            title: productT.title ,
            imagen: productT.image,
            uniqd: productT.uniqd ,
            id: productT.id,
            slug: productT.slug ,
            summary: productT.summary ,
            avg_rating: productT.avg_rating,
            reviews_count: productT.reviews_count,
            categorie_first: productT.categorie_first,
            categorie_second: productT.categorie_second,
            categorie_third: productT.categorie_third,
            precio_general: productT.precio_general,
          });
      }

      cookies.set('compares',COMPARES);
      emitter.emit('compare-product', COMPARES);
      notify({
          title: "Producto agregado a comparar",
          // text: `Tu producto '${productT.title}' se agrego a la lista de comparación!`,
          duration: 4000,
          type:'success',
      });
      setTimeout(() => {
        if(COMPARES.length > 1){
            router.push({
              name: 'CompareProduct',
            })
        }
      }, 25);
    },
}