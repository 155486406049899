import DetailProduct from './components/DetailProduct.vue'
import Home from './components/Home.vue'
import Login from './views/auth/LoginSection.vue'
import Register from './views/auth/RegisterSection.vue'
import PageNotFound from './components/layouts/PageNotFound.vue'
import CartShop from './views/guest/CartShop.vue'
import CompareProduct from './views/guest/CompareProduct.vue'
import Wishlist from './views/guest/Wishlist.vue'
// import Checkout from './views/guest/Checkout.vue'
import NewCheckout from './views/guest/NewCheckout.vue'

import ProfileClient from './views/auth/ProfileClient.vue'
import Offers from './views/guest/Offers.vue'
import FilterOffer from './views/guest/FilterOffer.vue'
import FilterSearch from './views/guest/FilterSearch.vue'
import FilterTienda from './views/guest/FilterTienda.vue'
import FilterCategorie from './views/guest/FilterCategorie.vue'
import FilterTags from './views/guest/FilterTags.vue'
import FilterMarca from './views/guest/FilterMarca.vue'
import FilterMarcaD from './views/guest/FilterMarcaD.vue'
import FilterBestSellers from './views/guest/FilterBestSellers.vue'

import ForgotPassword from './views/guest/password/ForgotPassword.vue'
import CodeOtp from './views/guest/password/Code.vue'
import ResetPassword from './views/guest/password/ResetPassword.vue'
import OrderSuccess from './views/guest/layouts/OrderSuccess.vue'

import CondicionYOferta from './views/guest/configuration/CondicionYOferta.vue'
import AvisoDeMarca from './views/guest/configuration/AvisoDeMarca.vue'
import GarantiaRMA from './views/guest/configuration/GarantiaRMA.vue'
import Privacidad from './views/guest/configuration/Privacidad.vue'
import DeliveryInfo from './views/guest/configuration/DeliveryInfo.vue'
import TerminoYCondiciones from './views/guest/configuration/TerminoYCondiciones.vue'
import ContactoPanel from './views/guest/configuration/ContactoPanel.vue'
import SucursalPanel from './views/guest/configuration/Sucursal.vue'
import BlogList from './views/guest/blog/BlogList.vue';
import BlogShow from './views/guest/blog/BlogShow.vue';

import CardHolder from './views/guest/CardHolder.vue'
import PcBuild from './views/pcbuild/PcBuild.vue';
import HistoriaSection from './views/guest/configuration/HistoriaSection.vue'

import { createRouter, createWebHistory } from 'vue-router'
import store from './plugins/store'
import decode from 'jwt-decode'

const routes = [
    // {
    //     path: '/',
    //     redirect: '/home'
    // },
    {
        name: 'Home',
        component: Home,
        path: '/',
        meta: null,
    },
    {
        name: 'Login',
        component: Login,
        path: '/login',
        meta: {
            requiresVisitor: true,
        }
    },
    {
        name: 'PageNotFound',
        component: PageNotFound,
        path: '/404',
        meta: {
            requiresVisitor: true,
        }
    },
    {
        name: 'Register',
        component: Register,
        path: '/registro',
        meta: {
            requiresVisitor: true,
        }
    },
    {
        name: 'DetailProduct',
        component: DetailProduct,
        path: '/product/:slug',
        meta: {
            requiresVisitor: true,
        }
    },
    {
      name: 'CartShop',
      component: CartShop,
      path: '/cart',
      meta: {
          requiresVisitor: true,
      }
    },
    {
      name: 'CompareProduct',
      component: CompareProduct,
      path: '/comparar-producto',
      meta: {
          requiresVisitor: true,
      }
    },
    {
      name: 'Wishlist',
      component: Wishlist,
      path: '/lista-deseos',
      meta: {
          requiresVisitor: true,
      }
    },
    // {
    //   name: 'Checkout',
    //   component: Checkout,
    //   path: '/generar-orden-otro',
    //   meta: {
    //       requiresVisitor: true,
    //   }
    // },
    {
      name: 'Checkout',
      component: NewCheckout,
      path: '/checkout',
      meta: {
          requiresVisitor: true,
      }
    },
    {
      name: 'OfferC',
      component: Offers,
      path: '/discount',
      meta: {
          requiresVisitor: true,
      }
    },
    {
      name: 'OfferFilter',
      component: FilterOffer,
      path: '/discount/:slug',
      meta: {
          requiresVisitor: true,
      }
    },
    {
      name: 'ForgotPassword',
      component: ForgotPassword,
      path: '/recuperar-credenciales',
      meta: {
          requiresVisitor: true,
      }
    },
    {
      name: 'CodeOtp',
      component: CodeOtp,
      path: '/codigo-de-recuperacion',
      meta: {
          requiresVisitor: true,
      }
    },
    {
      name: 'ResetPassword',
      component: ResetPassword,
      path: '/reset-credenciales',
      meta: {
          requiresVisitor: true,
      }
    },
    // 
    {
      name: 'FilterSearch',
      component: FilterSearch,
      path: '/search',
      meta: {
          requiresVisitor: true,
      }
    },
    {
      name: 'FilterTienda',
      component: FilterTienda,
      path: '/shop',
      meta: {
          requiresVisitor: true,
      }
    },
    {
      name: 'FilterCategorie',
      component: FilterCategorie,
      path: '/shop/categorie/:id',
      meta: {
          requiresVisitor: true,
      }
    },
    {
      name: 'FilterTags',
      component: FilterTags,
      path: '/shop/tag',
      meta: {
          requiresVisitor: true,
      }
    },
    {
      name: 'FilterBestSellers',
      component: FilterBestSellers,
      path: '/shop/best-sellers',
      meta: {
          requiresVisitor: true,
      }
    },
    {
      name: 'FilterMarca',
      component: FilterMarca,
      path: '/shop/brands',
      meta: {
          requiresVisitor: true,
      }
    },
    {
      name: 'FilterMarcaD',
      component: FilterMarcaD,
      path: '/shop/brands/:id',
      meta: {
          requiresVisitor: true,
      }
    },
    {
      name: 'ProfileClient',
      component: ProfileClient,
      path: '/perfil-cliente',
      meta: {
        requiresAuth: true,
      }
    },
    {
      name: 'OrderSuccess',
      component: OrderSuccess,
      path: '/orden-completa/:uniqd',
      meta: {
        requiresVisitor: true,
      }
    },
    // 
    {
      name: 'CondicionYOferta',
      component: CondicionYOferta,
      path: '/condiciones-de-ofertas',
      meta: {
        requiresVisitor: true,
      }
    },
    {
      name: 'AvisoDeMarca',
      component: AvisoDeMarca,
      path: '/aviso-de-marca-comercial',
      meta: {
        requiresVisitor: true,
      }
    },
    {
      name: 'GarantiaRMA',
      component: GarantiaRMA,
      path: '/politica-de-garantia',
      meta: {
        requiresVisitor: true,
      }
    },
    {
      name: 'Privacidad',
      component: Privacidad,
      path: '/politica-de-privacidad',
      meta: {
        requiresVisitor: true,
      }
    },
    {
      name: 'DeliveryInfo',
      component: DeliveryInfo,
      path: '/entregas-y-envios',
      meta: {
        requiresVisitor: true,
      }
    },
    {
      name: 'TerminoYCondiciones',
      component: TerminoYCondiciones,
      path: '/termino-y-condiciones',
      meta: {
        requiresVisitor: true,
      }
    },
    {
      name: 'ContactoPanel',
      component: ContactoPanel,
      path: '/contactos',
      meta: {
        requiresVisitor: true,
      }
    },
    {
      name: 'SucursalPanel',
      component: SucursalPanel,
      path: '/sucursales',
      meta: {
        requiresVisitor: true,
      }
    },
    {
      name: 'HistoriaSection',
      component: HistoriaSection,
      path: '/historia',
      meta: {
        requiresVisitor: true,
      }
    },
    // 
    {
      name: 'BlogList',
      component: BlogList,
      path: '/blog',
      meta: {
        requiresVisitor: true,
      }
    },
    {
      name: 'BlogListSS',
      component: BlogList,
      path: '/blog/categoria/:categorie',
      meta: {
        requiresVisitor: true,
      }
    },
    {
      name: 'BlogListSD',
      component: BlogList,
      path: '/blog/tag/:tag',
      meta: {
        requiresVisitor: true,
      }
    },
    {
      name: 'BlogShow',
      component: BlogShow,
      path: '/blog/:slug',
      meta: {
        requiresVisitor: true,
      }
    },
    // 
    {
      name: 'PcBuild',
      component: PcBuild,
      path: '/pcbuilder',
      meta: {
        requiresVisitor: true,
      }
    },
    // 
    {
      name: 'CardHolder',
      component: CardHolder,
      path: '/card-credit',
      meta: {
        requiresVisitor: true,
      }
    },
    { path: "/:catchAll(.*)", redirect: '/404' }
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // console.log(decode(store.auth.token) && (decode(store.auth.token).exp * 1000) > new Date().getTime())
      var decode_t = store.state.token ? decode(store.state.token) : null;
      if (!store.state.token) {
        next({
          name: 'Login',
        })
      } else if (!(decode_t && (decode_t.exp * 1000) > new Date().getTime())) {
        store.dispatch('logout')
        next({
          name: 'Login',
        })
      } else {
        next()
      }
    } else if (to.matched.some(record => record.meta.requiresVisitor || !record.meta)) {
      if (store.state.token && !store.getters.loggedIn) {
        store.dispatch("logout");
        next({
          name: 'Login',
        })
      } else {
        next()
      }
    } else {
      next()
    }
})

export default router;