<template>
    <div class="modal view-product fade" id="viewReview" tabindex="-1" aria-labelledby="viewReview" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" v-if="product_selected_modal">
            <div class="modal-header">
                <h5 class="modal-title" id="addNewAddressLabel">RESEÑA DEL PRODUCTO </h5>
                <!-- <span class="close-modal" data-bs-dismiss="modal"><i data-feather="x"></i></span> -->
            </div>
            <div class="modal-body">
                <span class="close-modal d-none d-md-block" data-bs-toggle="modal" data-bs-target="#viewReview"><i data-feather="x"></i></span>
                <div class="row gy-4 g-md-0 justify-content-center">
                    <div class="col-12">
                        <p class="text-rating">Elige la calificación de 1 a 5 estrellas, donde 5 estrellas representan el mejor producto, 3 estrellas indican una calificación promedio y 1 estrella denota la peor calificación.</p>
                        <ul class="rating" style="justify-content: center;">
                            <!-- class="fill" -->
                              <li :class="{'fill': selected_start >= 1}" style="cursor:pointer;" @click="selectedStar(1)"><i style="width: 80px;height: 45px;" data-feather="star"></i></li>
                              <li :class="{'fill': selected_start >= 2}" style="cursor:pointer;" @click="selectedStar(2)"><i style="width: 80px;height: 45px;" data-feather="star"></i></li>
                              <li :class="{'fill': selected_start >= 3}" style="cursor:pointer;" @click="selectedStar(3)"><i style="width: 80px;height: 45px;" data-feather="star"></i></li>
                              <li :class="{'fill': selected_start >= 4}" style="cursor:pointer;" @click="selectedStar(4)"><i style="width: 80px;height: 45px;" data-feather="star"></i></li>
                              <li :class="{'fill': selected_start >= 5}" style="cursor:pointer;" @click="selectedStar(5)"><i style="width: 80px;height: 45px;" data-feather="star"></i></li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-6 py-4">
                        <div class="input-box">
                            <textarea
                                class="form-control"
                                placeholder="Dejanos un comentario"
                                v-model="description"
                                style="height: 100px"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="btn-box">
                <button type="button" class="btn btn-outline rounded-pill" data-bs-dismiss="modal" aria-label="Close">Cerrar</button>
                <button type="button" class="btn btn-solid line-none rounded-pill" @click="reviewRegister()">Enviar <i class="arrow"></i></button>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>
<script>
import miApi from '../../../plugins/axios'

export default {
    name: 'ModalReviewProduct',
    data(){
        return {
            product_selected_modal:null,
            selected_start: 0,
            description: null,
            review:null,
        }
    },
    methods:{
        selectedStar(value){
            this.selected_start = value;
        },
        async reviewRegister(){
            if(this.selected_start == 0){
                this.$notify({
                    title: "VALIDACIÓN",
                    text: "SELECCIONA UNA CALIFICACIÓN DE ESTRELLA , LA MINIMA ES 1",
                    duration: 3000,
                    type:'error',
                });
                return;
            }
            let data = {
                rating: this.selected_start,
                description: this.description,
                product_id: this.product_selected_modal.product_id,
                order_detail_id: this.product_selected_modal.id,
                review_id: this.review ? this.review.id : null,
            }
            const resp = await miApi.post("ecommerce/review",data);
            if(resp.data.error){
                for (let key in resp.data.error) {
                    this.$notify({
                            title: "VALIDACIÓN",
                            text: resp.data.error[key],
                            duration: 1000,
                            type:'error',
                    });
                }
            }else{
                // this.product_selected_modal.review = resp.data.review;
                this.$notify({
                    title: "RESEÑA",
                    text: "Su reseña acerca del producto se ha registrado correctamente",
                    duration: 3500,
                });

                this.emitter.emit('review-modal-refresh', {item: this.product_selected_modal, review: resp.data.review});
                setTimeout(() => {
                    document.location.reload();
                }, 1500);
            }
        }
    },
    mounted(){
        this.emitter.on('review-detail-modal',data => {
            this.product_selected_modal = data;
            // console.log(this.product_selected_modal);
            this.review = data.review;
            if(this.review){
                this.selected_start = this.review.rating;
                this.description = this.review.description;
            }else{
                this.selected_start = null;
                this.description = null;
            }
            const script2 = document.createElement('script');
            script2.src = '../../assets/js/feather/feather.min.js';
            document.body.appendChild(script2);
            script2.onload = () => {
                console.log('archivo.js cargado 2');
            }
        });
    }
}
</script>