<template>
    <div>
        <!-- Main Start -->
        <main class="main">
            <!-- Breadcrumb Start -->
            <!-- <div class="breadcrumb-wrap">
                <div class="banner">
                <img class="bg-img bg-top" src="/assets/images/bg/bg-header-001.jpg" alt="banner" />

                <div class="container-lg">
                    <div class="breadcrumb-box">
                    <div class="heading-box">
                        <h1>Ofertas</h1>
                    </div>
                    <ol class="breadcrumb">
                        <li><a href="/">Inicio </a></li>
                        <li>
                        <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                        </li>

                        <li class="current"><a href="javascript:void(0)">Oferta</a></li>
                    </ol>
                    </div>
                </div>
                </div>
            </div> -->

            <div class="breadcrumb-wrap">
                <div class="banner3" style="background-image: url('/assets/images/bg/bg-header-001.jpg'); backgound-color:#1d233d">
                    <div class="container-lg">
                        <div class="breadcrumb-box">
                            <div class="heading-box hero-img">
                                <h1>¡No te pierdas estas ofertas <br> en productos tecnológicos!</h1>
                                <img src="/assets/images/ofertas/zeta_ofertas.svg" alt="Ofertas">
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <section class="container ofertas-secc">
                <template v-for="(product_offerta, index) in OFFERS" :key="index">
                    <section  class="border oferta ratio_asos">
                        <div class=flex-marcas>
                            <h3 class="title-oferta">
                                <a :href="'/discount/'+product_offerta.slug" :style="{'color': product_offerta.color_tag +'!important' }">{{ product_offerta.title }}</a>
                            </h3>
                            <div class="back-to2">
                                    <button class="btn" >
                                        <a :style="{'background': product_offerta.color_tag +'!important' }" class="back-shop2" :href="'/discount/'+product_offerta.slug">Ver ➜</a>
                                    </button>
                                </div>
                        </div>
                        <div class="row ofertas-flex">  
                        
                            <div class="col oferta-img"> 
                            
                                <img  :src="product_offerta.imagen" alt="">
                                
                            </div>
    
                            <div class="col-md-8 slider-ofertas">

                                <Carousel v-bind="settings" :breakpoints="breakpoints">
                                    <Slide v-for="(product_off, index) in product_offerta.products.data" :key="index">
                                    <div class="carousel__item">
                                        <CardProductA  v-if="product_off" :product="product_off"></CardProductA>
                                    </div>
                                    </Slide>

                                    <template #addons>
                                    <Navigation />
                                    </template>
                                </Carousel>

                                <!-- <div class="swiper product-slider-offertas">
                                    <div class="swiper-wrapper">
                                        <div class="swiper-slide" v-for="(product_off, index) in product_offerta.products.data" :key="index">
                                        <CardProductA v-if="product_off" :product="product_off"></CardProductA>
                                        </div>
                                    </div>
                                </div> -->
                        
                            </div>
                        </div>
                    </section>
                </template>
            </section>

        </main>
        <!-- Main End -->
        <DetailProduct />
    </div>
</template>
<script>
// import store from '../../plugins/store'
import myApi from '../../plugins/axios'
import CardProductA from '../../components/cards/CardProductA.vue'
import { useMeta } from 'vue-meta'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import DetailProduct from "./modal/DetailProduct.vue";

export default {
    name: 'OfferC',
    data(){
        return {
            OFFERS: [],
            // 
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            breakpoints: {
                700: {
                itemsToShow: 2,
                snapAlign: 'center',
                },
                1024: {
                itemsToShow: 3,
                snapAlign: 'start',
                },
            },
        }
    },
    components:{
        CardProductA,
        // 
        Carousel,
        Slide,
        Navigation,
        DetailProduct,
    },
    methods:{
        async listOffers(){
            try {
                const resp = await myApi.get(
                    "ecommerce/offers/"
                );
                console.log(resp);
                
                let OFFERTS = resp.data.offers;
                OFFERTS = OFFERTS.map(offer => {
                    offer.products.data = offer.products.data.map(product => {
                        product.total_news_p = this.getAmountTotal(product)
                        return product;
                    });
                    return offer;
                });
                OFFERTS = OFFERTS.map(offer => {
                    offer.products.data = this.sortingAsc(offer.products.data,'total_news_p');
                    return offer;
                });
                setTimeout(() => {
                    this.OFFERS = OFFERTS;
                    this.reloadMyHomeJs();
                }, 50);
            } catch (error) {
                console.log(error);
            }
        },
        sortingAsc(json_object, key_to_sort_by) {
            function sortByKey(a, b) {
                var x = a[key_to_sort_by];
                var y = b[key_to_sort_by];
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            }
            return json_object.sort(sortByKey);
        },
        getAmountTotal(product){
            if(product.discount_g && !product.code_offer){
                if(product.discount_g.discount_id){
                return Number(product.discount_g.new_amount.toFixed(2));
                // percentage
                }else{
                if(product.discount_g.type_discount == 1){//%
                    return Number((product.precio_general - (product.precio_general*product.discount_g.discount*0.01)).toFixed(2));
                }else{
                    return Number((product.precio_general - product.discount_g.discount).toFixed(2));
                }
                }
            }
            if(product.code_offer){
                if(product.code_offer.new_amount){
                return Number(product.code_offer.new_amount.toFixed(2));
                // percentage
                }else{
                if(product.code_offer.type_discount == 1){//%
                    return Number((product.precio_general - (product.precio_general*product.code_offer.discount*0.01)).toFixed(2));
                }else{
                    return Number((product.precio_general - product.code_offer.discount).toFixed(2));
                }
                }
            }
            return product.precio_general;
        },
        reloadMyHomeJs(){
            const script_myjs = document.createElement('script');
            script_myjs.src = '../../assets/js/myjs/sliderhome.js';
            document.body.appendChild(script_myjs);
            script_myjs.onload = () => {
                console.log('archivo.js cargado script_myjs');
            }
        },
    },
    created(){
        this.listOffers();
    },  
    mounted(){
        this.useMetaT = useMeta({
            title: 'OFERTAS',
            htmlAttrs: { lang: 'en', amp: true },
            meta: [],
        })
    },
}
</script>