import store from '../plugins/store'

// function getAmountTotal(product){
//     if(product.discount_g && !product.code_offer){
//         if(product.discount_g.discount_id){
//         return product.discount_g.new_amount;
//         }else{
//         if(product.discount_g.type_discount == 1){//%
//             return product.precio_general - (product.precio_general*product.discount_g.discount*0.01);
//         }else{
//             return product.precio_general - product.discount_g.discount;
//         }
//         }
//     }
//     if(product.code_offer){
//         if(product.code_offer.new_amount){
//             return product.code_offer.new_amount;
//             // percentage
//         }else{
//             if(product.code_offer.type_discount == 1){//%
//                 return product.precio_general - (product.precio_general*product.code_offer.discount*0.01);
//             }else{
//                 return product.precio_general - product.code_offer.discount;
//             }
//         }
//     }
//     return product.precio_general;
// }
function getAmountTotal(product){
    if(product.discount_g && !product.code_offer){
        if(product.discount_g.discount_id){
            return Number(product.discount_g.new_amount.toFixed(2));
            // percentage
        }else{
            if(product.discount_g.type_discount == 1){//%
                return Number((product.precio_general - (product.precio_general*product.discount_g.discount*0.01)).toFixed(2));
            }else{
                return Number((product.precio_general - product.discount_g.discount).toFixed(2));
            }
        }
    }
    if(product.code_offer){
        if(product.code_offer.new_amount){
            return Number(product.code_offer.new_amount.toFixed(2));
            // percentage
        }else{
        if(product.code_offer.type_discount == 1){//%
                return Number((product.precio_general - (product.precio_general*product.code_offer.discount*0.01)).toFixed(2));
            }else{
                return Number((product.precio_general - product.code_offer.discount).toFixed(2));
            }
        }
    }
    return product.precio_general;
}
function getTypeDiscount(product){
    if(product.discount_g && !product.code_offer){
        if(product.discount_g.discount_id){
            return 1;
        }else{
            return product.discount_g.type_discount;
        }
    }
    if(product.code_offer){
        if(product.code_offer.new_amount){
            return 1;
        }else{
            return product.code_offer.type_discount;
        }
    }
    return null;
}
function getAmountDiscount(product){
    if(product.discount_g){
        if(product.discount_g.discount_id){
            return product.discount_g.percentage;
        }else{
        if(product.discount_g.type_discount == 1){//%
            return product.discount_g.discount;
        }else{
            return product.discount_g.discount;
        }
        }
    }
    if(product.code_offer){
        if(product.code_offer.new_amount){
            return product.code_offer.percentage;
        }else{
        if(product.code_offer.type_discount == 1){//%
            return product.code_offer.discount;
        }else{
            return product.code_offer.discount;
        }
        }
    }
    return 0;
}

function register_guest (PRODUCT) {
    return {
        title: PRODUCT.title ,
        imagen: PRODUCT.image,
        uniqd: PRODUCT.uniqd ,
        slug: PRODUCT.slug ,
        avg_rating: PRODUCT.avg_rating,
        reviews_count: PRODUCT.reviews_count,
        quantity: 1,
        price_unit: PRODUCT.precio_general,
        subtotal:(getAmountTotal(PRODUCT)),
        total:(getAmountTotal(PRODUCT)) * 1,
        type_discount: getTypeDiscount(PRODUCT),
        discount: getAmountDiscount(PRODUCT),
        code_cupon: null,
        code_discount: PRODUCT.discount_g ? PRODUCT.discount_g.uniqd : null,
        code_offer: PRODUCT.code_offer ? PRODUCT.code_offer.uniqd : null,
        sucursales: PRODUCT.sucursales,
    }
}

function register_client (PRODUCT, quantity = 1) {
    return {
        user_id: PRODUCT.user_id,
        title: PRODUCT.title ,
        imagen: PRODUCT.image,
        product_id: PRODUCT.product_id ? PRODUCT.product_id : PRODUCT.id,
        uniqd: PRODUCT.uniqd,
        slug: PRODUCT.slug ,
        quantity: quantity,
        avg_rating: PRODUCT.avg_rating,
        reviews_count: PRODUCT.reviews_count,
        price_unit: PRODUCT.precio_general,
        subtotal: getAmountTotal(PRODUCT),
        total: getAmountTotal(PRODUCT) * quantity,
        type_discount: getTypeDiscount(PRODUCT),
        discount: getAmountDiscount(PRODUCT),
        code_cupon: null,
        code_discount: PRODUCT.discount_g ? PRODUCT.discount_g.uniqd : null,
        code_offer: PRODUCT.code_offer ? PRODUCT.code_offer.uniqd : null,
        sucursales: PRODUCT.sucursales,
    }
}

function update (CART) {
    return {
        cart_id: CART.id,
        user_id: CART.user_id,
        title: CART.product.title ,
        imagen: CART.product.image,
        product_id: CART.product_id,
        uniqd: CART.product.uniqd,
        slug: CART.product.slug ,
        quantity: CART.quantity,
        avg_rating: CART.product.avg_rating,
        reviews_count: CART.product.reviews_count,
        price_unit: CART.price_unit,
        subtotal: CART.subtotal,
        total: CART.total,
        type_discount: CART.type_discount,
        discount: CART.discount,
        code_cupon: CART.code_cupon,
        code_discount: CART.code_discount,
        code_offer: CART.code_offer,
        product: CART.product,
        sucursales: CART.product.sucursales,
        id: CART.id,
    }
}

export default {
    register_guest: (PRODUCT) => {
       return register_guest(PRODUCT);
    },
    register_client: (PRODUCT, quantity = 1) => {
        return register_client(PRODUCT, quantity);
    },
    update: (CART) => {
        return update(CART);
    },
    add: (CARTS,PRODUCTY,emitter,notify,cookies,quantity=1) => {
        let INDEX = CARTS.findIndex(product => product.uniqd == PRODUCTY.uniqd);
        

        let errors = [];
        // let IS_EXISTENCIA = false;
        let COUNT_CART = INDEX != -1 ? CARTS[INDEX].quantity : 0;
        console.log(PRODUCTY);
        if(PRODUCTY.sucursales){
            PRODUCTY.sucursales.every((sucursal) => {
                if(parseInt(sucursal.stock) < (quantity + COUNT_CART)){
                    errors.push("LA CANTIDAD SOLICITADA NO SE ENCUENTRA DISPONIBLE EN NINGUNA SUCURSAL");
                    // return false;
                }
                // else{
                //     IS_EXISTENCIA = true;
                // }
                return true;
            });
        }
        if(PRODUCTY.sucursales && errors.length ==  PRODUCTY.sucursales.length){//&& !IS_EXISTENCIA
            // notify({
            //     title: "VALIDACIÓN DE EXISTENCIA",
            //     text: "LA CANTIDAD SOLICITADA NO SE ENCUENTRA DISPONIBLE EN NINGUNA SUCURSAL",
            //     type:'error',
            //     duration: 1000,
            // });
            emitter.emit("add-error-cart-shop", "La cantidad de producto que deseas no se encuentra disponible en este momento. Te sugerimos intentar con una cantidad diferente o ponerte en contacto con servicio al cliente para obtener una solución");
            // errors.forEach((err) => {
            // })
            return;
        }

        if(!store.getters.loggedIn){
          let data = register_guest(PRODUCTY);
          if(INDEX != -1){
            CARTS[INDEX].quantity = CARTS[INDEX].quantity + quantity;
            CARTS[INDEX].total =  (CARTS[INDEX].subtotal * CARTS[INDEX].quantity).toFixed(2);
          }else{
              CARTS.push(data);
          }
          cookies.set('carts',CARTS);
          emitter.emit("cart-shop", CARTS);
           notify({
              title: "Producto agregado al carrito",
            //   text: `Tu producto '${PRODUCTY.title}' se agrego al carrito correctamente!`,
              duration: 2000,
              type: 'success'
          });
        }else{
          var data = {};
          if(INDEX != -1){
            CARTS[INDEX].quantity = CARTS[INDEX].quantity + quantity;
            CARTS[INDEX].total =  (CARTS[INDEX].subtotal * CARTS[INDEX].quantity).toFixed(2);
            data = update(CARTS[INDEX]);
          }else{
            PRODUCTY.user_id = store.state.user.id;
            data = register_client(PRODUCTY,quantity);
          }
          emitter.emit('save-cart', data);
        }
    },
    deleteCoupon: (CARTS,COUPON,emitter,notify,cookies) => {
        if(!store.getters.loggedIn){
            CARTS = CARTS.map((cart) => {
                if(cart.code_cupon == COUPON){
                    cart.code_cupon = null;
                    cart.discount = 0;
                    cart.type_discount = null;
                    cart.subtotal = cart.price_unit;
                    cart.total = cart.subtotal*cart.quantity;
                }
                return cart;
            });
            cookies.set('carts',CARTS);
            emitter.emit("cart-shop", CARTS);
            notify({
                title: "Carrito de compra",
                text: `A TU CARRITO SE LE QUITO EL CUPON '${COUPON}' CORRECTAMENTE!`,
                duration: 2000,
                type: 'success'
            });
        }else{
            CARTS = CARTS.map((cart) => {
                if(cart.code_cupon == COUPON){
                    cart.code_cupon = null;
                    cart.discount = 0;
                    cart.type_discount = null;
                    cart.subtotal = cart.price_unit;
                    cart.total = cart.subtotal*cart.quantity;
                }
                return cart;
            });
            setTimeout(() => {
                emitter.emit('update-carts', CARTS);
            }, 25);
        }
    },
    getAmountTotalProduct: (product) => {
        product.precio_general = Number(product.precio_general);
        return getAmountTotal(product);
    }
}