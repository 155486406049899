<template>
    <div>
        <!-- Main Start -->
        <main class="main">
            <!-- Breadcrumb Start -->
            <div class="breadcrumb-wrap">
                <div class="banner">
                <img class="bg-img bg-top" src="/assets/images/bg/bg-header-001.jpg" alt="banner" />

                <div class="container-lg">
                    <div class="breadcrumb-box">
                    <div class="heading-box">
                        
                        <h1> {{ slug_tags ? (slug_tags.charAt(0).toUpperCase() + slug_tags.slice(1)) : ''}}</h1>
                    </div>
                    <!-- <ol class="breadcrumb">
                        <li><a href="/">Inicio</a></li>
                        <li>
                        <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                        </li>
                        <li class="current"><a href="#" onclick="return false;">Tags</a></li>
                    </ol> -->
                    </div>
                </div>
                </div>
            </div>
            <!-- Breadcrumb End -->
            <FilterProduct v-if="slug_tags" :slug_tags="slug_tags"></FilterProduct>
        </main>
        <!-- Main End -->

    </div>
</template>
<script>
import FilterProduct from './layouts/FilterProduct.vue'
import { useMeta } from 'vue-meta'

export default {
    name: 'FilterOffer',
    components:{
        FilterProduct,
    },
    data(){
        return {
            slug_tags: null,
        }
    },
    methods: {
    },
    created(){
        this.slug_tags = this.$route.query.text;
    },
    mounted(){
        this.useMetaT = useMeta({
            title: this.slug_tags.charAt(0).toUpperCase() + this.slug_tags.slice(1),
            htmlAttrs: { lang: 'en', amp: true },
            meta: [],
        })
    }
}
</script>