<template>
  <div>
    <!-- Main Start -->
    <main class="main">
      <!-- Breadcrumb Start -->
      <!-- <div class="breadcrumb-wrap">
        <div class="banner">
          <img
            class="bg-img bg-top"
            src="../assets/images/inner-page/banner-p.jpg"
            alt="banner"
          />

          <div class="container-lg">
            <div class="breadcrumb-box">
              <div class="heading-box">
                <h1>Producto</h1>
              </div>
              <ol class="breadcrumb">
                <li><a href="/">Inicio</a></li>
                <li>
                  <a href="javascript:void(0)"
                    ><i data-feather="chevron-right"></i
                  ></a>
                </li>
                <li v-if="product_selected"><a href="javascript:;">{{product_selected.categorie_first.name }}</a></li>
                <li>
                  <a href="javascript:void(0)"
                    ><i data-feather="chevron-right"></i
                  ></a>
                </li>
                <li v-if="product_selected"><a href="javascript:;">{{product_selected.categorie_second.name }}</a></li>
                <li>
                  <a href="javascript:void(0)"
                    ><i data-feather="chevron-right"></i
                  ></a>
                </li>
                <li v-if="product_selected"><a href="javascript:;">{{product_selected.categorie_third.name }}</a></li>
              </ol>
            </div>
          </div>
        </div>
      </div> -->
      <!-- Breadcrumb End -->

      
      <main class="main mt-3" v-if="!product_selected && isLoadingProduct">
        <!-- <img src="/assets/svg/loader_producto.svg" alt="" srcset=""> -->
        <div class="container-lg">
          <div class="row g-3 g-xl-4">
            <div class="col-5">
              <img src="/assets/svg_load/product-gallery-preloader.svg" alt="" srcset="">
            </div>
            <div class="col-6">
              <img src="/assets/svg_load/product-info-preloader.svg" alt="" srcset="">
            </div>
          </div>
        </div>
      </main>

      <!-- Product Section Start -->
      <section class="product-page py-0" v-if="product_selected">
        <div class="view-product">
          <div class="container-lg">
            <div class="row g-3 g-xl-4">

              <div class="col-md-6">
                <ol class="breadcrumb">
                  <li><router-link to="/">Inicio</router-link></li>
                  <li>
                    <a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg></a>
                  </li>
                  <template v-if="product_selected">
                    <li v-if="product_selected.categorie_first">
                      <router-link :to="'/shop/categorie/'+product_selected.categorie_first.id+'?text='+product_selected.categorie_first.name"
                      >{{ product_selected.categorie_first.name }}</router-link></li>
                    <li v-if="product_selected.categorie_second">
                      <a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg></a>
                    </li>
                    <li v-if="product_selected.categorie_second">
                      <router-link :to="'/shop/categorie/'+product_selected.categorie_first.id+'?text='+product_selected.categorie_second.name+'&categorie_second_id='+product_selected.categorie_second.id"
                      >{{ product_selected.categorie_second.name }}</router-link></li>
                    <li v-if="product_selected.categorie_third">
                      <a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg></a>
                    </li>
                    <li class="current" v-if="product_selected.categorie_third">
                      <router-link :to="'/shop/categorie/'+product_selected.categorie_first.id+'?text='+product_selected.categorie_third.name+'&categorie_third_id='+product_selected.categorie_third.id+'&categorie_s_second_id='+product_selected.categorie_second.id"
                      >{{ product_selected.categorie_third.name }}</router-link></li>
                  </template>

                </ol>
                
                <div class="slider-box sticky off-50 position-sticky">
                  <div class="row g-2">
                    <div class="col-2">
                      <div class="thumbnail-box">
                        <div class="swiper thumbnail-img-box thumbnailSlider2">
                          <div class="swiper-wrapper">
                            <div
                              class="swiper-slide"
                              v-for="(image, index) in product_selected.images"
                              :key="index"
                            >
                              <img :src="image.imagen" alt="img" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-10 ratio_square">
                      <div class="swiper mainslider2">
                        <div class="swiper-wrapper">
                          <!-- <div
                            class="swiper-slide"
                            v-for="(image, index) in product_selected.images"
                            :key="index"
                          >
                            <img class="bg-img" :src="image.imagen" alt="img" />
                          </div> -->
                          <template v-for="(image, index) in product_selected.images" :key="index">
                            <figure class="swiper-slide zoom" @mousemove="zoom"
                              :style="{'background-image': 'url('+image.imagen+')'}">
                                <a href="javascript:void(0)" @click="openImagensProduct(image,index)" data-bs-toggle="modal"
                                    :data-bs-target="'#video'+index" >
                                    <img :src="image.imagen" alt="" />
                                </a>
                            </figure>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="product-detail-box">
                  <div class="product-option">
                    
                    <!-- <div class="option rating-option" v-if="product_selected.marca"> !product_selected.color_tag && -->
                      <div class="flex-marcas" v-if="product_selected.marca">

                        <div class="marca-item">
                          <img :src="product_selected.marca.imagen" style="border-radius: 10px;" width="128" alt="">
                        </div>

                        <span v-if="!product_selected.discount_g && !product_selected.code_offer && getTagNew(product_selected)" class="arrow-label bg-theme-green-label label-prod"
                          style="background-color: #3b73f1;">{{ getNameTagNew(product_selected) }}</span>

                        <span v-if="product_selected.color_tag && !product_selected.discount_g && !product_selected.code_offer && !getTagNew(product_selected)" class="arrow-label bg-theme-green-label label-prod"
                        :style="{'background-color': product_selected.color_tag+' !important'}"				 
                        style=""
                        >{{ product_selected.name_tag }}</span>

                        <span v-if="product_selected.discount_g && (typeof product_selected.discount_g.discount === 'string' || product_selected.discount_g.discount instanceof String) && !product_selected.code_offer" class="arrow-label bg-theme-green-label label-prod"
                        :style="{'background-color': product_selected.discount_g.color_tag+'  !important'}" >{{ product_selected.discount_g.title }} - {{ getDiscountPrice(product_selected) }}</span>

                        <span v-if="product_selected.discount_g && !(typeof product_selected.discount_g.discount === 'string' || product_selected.discount_g.discount instanceof String) && !product_selected.code_offer" class="arrow-label bg-theme-green-label label-prod"
                        :style="{'background-color': product_selected.discount_g.discount.color_tag+'  !important'}" >{{ product_selected.discount_g.discount.title }} - {{ getDiscountPrice(product_selected) }}</span>

                        <span v-if="product_selected.code_offer" class="arrow-label bg-theme-green-label label-prod"
                        :style="{'background-color': product_selected.code_offer.color_tag+'  !important'}" > Descuento - {{ getOfferPrice(product_selected) }}</span>
                        
                      </div>
                    <!-- </div> -->

                    <h1>{{ product_selected.title }}</h1>

                    <!-- <div class="option rating-option">
                      <span class="" v-if="!product_selected.color_tag && !product_selected.discount_g && !product_selected.code_offer && getTagNew(product_selected)"> 
                          <span class="arrow-label bg-success p-1 rounded"> NUEVO</span> 
                      </span>

                      <span class="" style="width: 128px;" v-if="product_selected.color_tag && !product_selected.discount_g && !product_selected.code_offer"> <span class="arrow-label bg-primary p-1 rounded" 
                          :style="{'background-color': product_selected.color_tag+' !important'}" style="width: 100%;"> {{ product_selected.name_tag }}</span> 
                      </span>

                      <span class="" style="width: 128px;" v-if="product_selected.discount_g && !product_selected.code_offer"> <span class="arrow-label bg-primary p-1 rounded" 
                          :style="{'background-color': product_selected.discount_g.color_tag+'  !important'}" style="width: 100%;"> Descuento - {{ getDiscountPrice(product_selected) }}</span> 
                      </span>

                      <span class="" style="width: 128px;" v-if="product_selected.code_offer"> <span class="arrow-label bg-primary p-1 rounded" 
                        :style="{'background-color': product_selected.code_offer.color_tag+'  !important'}" style="width: 100%;"> Descuento - {{ getOfferPrice(product_selected) }}</span> 
                      </span>

                    </div> -->
                    <div class="details-flex mt-2">
                      <div>
                        <p>UPC: {{ product_selected.codigo_barra }}</p>
                      </div>
                      <div class="option rating-option">
                        <ul class="rating p-0">
                          <li>
                            <i :class="{'fill': product_selected.avg_rating >= 1}"  data-feather="star"></i>
                          </li>
                          <li>
                            <i :class="{'fill': product_selected.avg_rating >= 2}"  data-feather="star"></i>
                          </li>
                          <li>
                            <i :class="{'fill': product_selected.avg_rating >= 3}"  data-feather="star"></i>
                          </li>
                          <li>
                            <i :class="{'fill': product_selected.avg_rating >= 4}"  data-feather="star"></i>
                          </li>
                          <li>
                            <i :class="{'fill': product_selected.avg_rating >= 5}" data-feather="star"></i>
                          </li>
                        </ul>
                        <span>{{ product_selected.reviews_count }} Rating</span>
                      </div>
                    </div>
                    
                    <!-- <div>
                      <small> <b>UPC</b>: {{ product_selected.codigo_barra }} </small>
                    </div> -->
                    
                    <div class="option price">
                      <span>$ {{ getAmountTotal(product_selected) }}</span> 
                      <del v-if="product_selected.discount_g || product_selected.code_offer" class="px-2">$ {{ getAmountDiscount(product_selected) }} </del>
                    </div>

                    <div class="option">
                      <p class="content-color">
                        {{ product_selected.summary }}
                      </p>
                    </div>

                    <!-- <div class="status">
                      <div class="circulo-disponible">
                      </div>
                      <p>EN STOCK</p>
                    </div>
                    <div class="sucursales">
                      <h5>Disponible en: </h5>
                      <div class="sucursales-flex">
                        <template
                            v-for="(sucursale, index) in product_selected.sucursales"
                            :key="index"
                          >
                            <div class="mt-1">
                              <img :src="sucursale.imagen" :alt="sucursale.name"
                                  tittle="Metrocentro ">
                            </div>
                          </template>
                      </div>
                    </div> -->

                    <div class="sucursales-box2">
                      <div class="texto-entrega">
                          <div class="status">
                              <div class="circulo-disponible">
                              </div>
                              <p>EN STOCK <span class="disponible"> disponible en sucursal(es)</span></p>
                            
                          </div>
                          <div class="sucursales-flex">
                            <template
                              v-for="(sucursale, index) in product_selected.sucursales"
                              :key="index"
                            >
                              <img :src="sucursale.imagen" v-if="Number(sucursale.stock) > 0 && white" :alt="sucursale.name"
                                :title="sucursale.name ">

                              <img :src="sucursale.imagen_dark" v-if="Number(sucursale.stock) > 0 && dark" :alt="sucursale.name"
                                :title="sucursale.name ">
                            </template>
                          </div>
                      </div>
                    </div>
                    <!-- <div class="py-4">
                        <span>Compartir: </span>
                        <template v-if="product_selected"> 
                            <ShareNetwork network="facebook" :url="url" :title="product_selected.title"
                                :description="product_selected.summary"
                                :image="product_selected.image" >
                                <img src="/assets/icons/svg/social/fb.svg" width="35" alt="Facebook"> Facebook
                            </ShareNetwork>
                            <ShareNetwork network="twitter" :url="url" :title="product_selected.title"
                                :description="product_selected.summary"
                                :image="product_selected.image" >
                                <img src="/assets/svg/twitter.png" width="35" alt="Twitter"> Twitter
                            </ShareNetwork>
                        </template>
                    </div> -->

                    <div class="option-side">
                      <!-- <div class="option">
                                  <div class="title-box4">
                                      <h4 class="heading">Your Brand Color:<span class="bg-theme-blue"></span></h4>
                                  </div>
                                  <ul class="filter-color">
                                      <li>
                                      <div class="color-box bg-theme-pink"></div>
                                      </li>
                                      <li class="active">
                                      <div class="color-box bg-theme-yellow"></div>
                                      </li>
                                      <li>
                                      <div class="color-box bg-theme-orange"></div>
                                      </li>
                                      <li>
                                      <div class="color-box bg-theme-blue"></div>
                                      </li>
                                  </ul>
                                  </div> -->
                      <!-- <div class="option">
                        <div class="title-box4">
                          <h4 class="heading">
                            Quantity: <span class="bg-theme-blue"></span>
                          </h4>
                        </div>
                        <div class="plus-minus">
                          <a href="javascript:void(0)"  @click="minusQuantity()"><i class="sub" data-feather="minus"></i></a>
                          <input type="number" v-model="quantity" min="1" max="10" />
                          <a href="javascript:void(0)" @click="addQuantity()"><i class="add" data-feather="plus" ></i></a> 
                        </div>
                      </div> -->
                      <div class="btn-group">
                          <div class="plus-minus">
                            <a href="javascript:void(0)"  @click="minusQuantity()"><i class="sub" data-feather="minus"></i></a>
                            <input type="number" v-model="quantity" min="1" max="10" />
                            <a href="javascript:void(0)" @click="addQuantity()"><i class="add" data-feather="plus" ></i></a> 
                          </div>
                          <a href="javascript:void(0)"  @click="addCart()" class="btn-solid btn-sm addtocart-btn">Agregar al carrito </a>
                          <a href="javascript:void(0)"  @click="addBuy()" class="btn-outline btn-sm wishlist-btn">Comprar ahora</a>
                      </div>

                      
                    </div>

                    <div class="flex-buttons">
                      <li data-bs-toggle="tooltip" data-bs-placement="top" title="Wishlist">
                          <a href="javascript:void(0)" @click="addWishList()"  class="wishlist-btn">
                              <i data-feather="heart"></i>
                          </a>
                      </li>
                      <li data-bs-toggle="tooltip" data-bs-placement="top" title="Compare">
                          <a href="javascript:void(0)" @click="goToCompare()" ><i data-feather="repeat"></i></a>
                      </li>
                    </div>

                    <!-- <div class="option-side py-2">
                      <div class="btn-group">
                          <a href="javascript:void(0)" @click="goToCompare()" class="btn-outline btn-sm wishlist-btn">Comparar Producto</a>
                          <a href="javascript:void(0)" @click="addWishList()" class="btn-outline btn-sm wishlist-btn">Lista de deseo</a>
                      </div>
                    </div> -->
                    <!-- <div class="option size">
                                  <div class="title-box4">
                                  <h4 class="heading">Size: <span class="bg-theme-blue"></span></h4>
                                  <span class="size-chart" data-bs-toggle="modal" data-bs-target="#sizeChart">Size Chart</span>
                                  </div>
                                  <ul class="size-list">
                                  <li class="active">S</li>
                                  <li>M</li>
                                  <li>L</li>
                                  <li>XL</li>
                                  </ul>
                              </div> -->
                      <div class="message-alert" v-if="ERROR_TEXT">
                          <span class="close-button" @click="ERROR_TEXT = null">&times;</span>
                          <p> {{ ERROR_TEXT }}</p>
                            <!-- La cantidad de producto que deseas no se encuentra disponible en este
                              momento. Te sugerimos intentar con una cantidad diferente o ponerte en
                              contacto con servicio al cliente para obtener una solución.</p> -->
                      </div>

                    <div class="entrega">
                        <div class="icon-camion">
                            <img src="/assets/images/product/camion-white.png" alt="">
                        </div>
                        
                        <div class="texto-entrega" v-if="today">
                            <p>¡Entrega apróximada <span class="bold-date">{{ next_name_day + ' ' +next_num_day }} a {{ next_name_two_day }} {{ next_num_two_day }} de {{ next_num_month }}
                                </span> si ordenas hoy
                                <span class="bold-date">{{today}}!</span>
                            </p>
                        </div>
                    </div>

                    <div class="option sale-details pt-10" v-if="DISCOUNT_GENERAL">
                        <div class="title-box4">
                          <!-- <span class="bg-theme-blue"></span -->
                        <h4 class="heading">¡Apresútare, la oferta terminará pronto! </h4>
                        </div>
                        <ul class="timer" id="time-watch" :data-time="getDateTimeDiscount()">
                          <li><span class="days time-value"></span> <span class="timer-label">Dias</span></li>
                          <li><span class="hours time-value"></span> <span class="timer-label">Horas</span></li>
                          <li><span class="minutes time-value"></span> <span class="timer-label">Minutos</span></li>
                          <li><span class="seconds time-value"></span> <span class="timer-label">Segundos</span></li>
                        </ul>
                    </div>

                    <!-- <div class="btn-group">
                      <a
                        href="javascript:void(0)"
                        @click="addCart()"
                        class="btn-solid btn-sm addtocart-btn"
                        >Add To Cart
                      </a>
                      <a
                        href="javascript:void(0)"
                        @click="addBuy()"
                        class="btn-outline btn-sm wishlist-btn"
                        >Buy now</a
                      >
                    </div> -->
                  </div>
                </div>
              </div>

              <div class="py-4 share">
                <div class="share-box">
                    <span>Compartir </span>

                    <ShareNetwork network="facebook" :url="url" :title="product_selected.title"
                      :description="product_selected.summary"
                      :image="product_selected.image" >
                      <img class="share-icon" src="../assets/icons/svg/social/fb.svg" alt="Facebook">
                    </ShareNetwork>
                    <ShareNetwork network="twitter" :url="url" :title="product_selected.title"
                      :description="product_selected.summary"
                      :image="product_selected.image" >
                      <img class="share-icon" src="../assets/icons/svg/social/tw.svg" alt="Twitter">
                    </ShareNetwork>

                    <!-- <a  href="javascript:void(0)" image="">
                        <img class="share-icon" src="../assets/icons/svg/social/fb.svg" alt="Facebook">
                    </a>
                    <a href="javascript:void(0)" image="">
                        <img class="share-icon" src="../assets/icons/svg/social/tw.svg" alt="Twitter">
                    </a> -->
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <!-- Product Section End -->

            <section class="cross-selling" v-if="product_selected && product_selected.packages.length > 0">
              <div class="container-lg">
                  <div class="title-box4">
                      <h4 class="heading font-2xl">Comprados juntos habitualmente</h4>
                  </div>

                  <div class="box-paquete">
                    <div class="card-wrap">

                      <div class="product-card-side product-side2">
                        <div class="img-wrap">
                          <a :href="'/product/'+product_selected.slug"
                          target="_blank"><img class="img-fluid"
                                :src="product_selected.image" alt="bag"></a></div>
                        <div class="content-box"><a :href="'/product/'+product_selected.slug"
                                >
                                <!-- <p>{{product_selected.marca.name}}</p> -->
                                <h5 class="">{{product_selected.title}}</h5><span>$
                                  {{product_selected.precio_general}}</span>
                            </a></div>
                      </div>
                      
                      <template
                        v-for="(pack, index) in this.product_selected.packages"
                        :key="index"
                      >
                        <div class="product-card-side product-side2">
                            <div class="img-wrap">
                              <a :href="'/product/'+pack.slug"
                              target="_blank"><img class="img-fluid"
                                    :src="pack.image" alt="bag"></a></div>
                            <div class="content-box"><a :href="'/product/'+pack.slug"
                                    >
                                    <!-- <p>{{pack.marca.name}}</p> -->
                                    <h5 class="">{{pack.title}}</h5><span>$
                                      {{pack.precio_general}}</span>
                                </a></div>
                        </div>
                      </template>

                    </div>

                    
                    <div class="text-end text-btn">
                          <div class="option price">
                              <p>Precio total: <span>${{ GetTotalPackegs(this.product_selected.packages,this.product_selected) }}</span> </p>
                          </div>
                          <a  href="javascript:void(0)"
                              @click="addPackage()" class="btn-solid btn-sm addtocart-btn">
                              Agregar todos al carrito </a>
                      </div>
                  </div>

              </div>
            </section>

            <!-- New Arrived Section Start -->
            <section class="pt-4 ratio_asos" v-if="product_selected">
              <div class="container-lg">
                <div class="title-box">
                  <h4 class="unique-heading">
                    ✔ Productos que podrían gustarte
                  </h4>
                </div>

                <!-- <div class="swiper product-slider">
                  <div class="swiper-wrapper">
                    <template
                      v-for="(product, index) in product_relateds"
                      :key="index"
                    >
                      <div class="swiper-slide">
                        <CardProductA v-if="product" :product="product"></CardProductA>
                      </div>
                    </template>
                  </div>
                </div> -->
                <Carousel v-bind="settings" :breakpoints="breakpoints">
                  <Slide v-for="(product, index) in product_relateds"
                      :key="index">
                    <div class="carousel__item">
                      <CardProductA v-if="product" :product="product"></CardProductA>
                    </div>
                  </Slide>

                  <template #addons>
                    <Navigation />
                  </template>
                </Carousel>

              </div>
            </section>
            <!-- New Arrived Section End -->

            <div class="pt-4 ratio_asos" v-if="!isMobile() && !product_selected && isLoadingProduct">
              <div class="container-lg">
                  <div class="title-box">
                      <h4 class="unique-heading">🔥  Otros clientes también compraron</h4>
                  </div>
                  <div class="col-12">
                    <img src="/assets/svg_load/shop-4cards-preloader.svg" alt="" srcset="">
                  </div>
                </div>
            </div>

            <div class="pt-4 ratio_asos" v-if="isMobile() && !product_selected && isLoadingProduct">
              <div class="container-lg">
                <div class="title-box">
                    <h4 class="unique-heading">👍 Productos recomendados  </h4>
                </div>
                <div class="col-12">
                  <img src="/assets/svg_load/shop-2cards-preloader.svg" alt="" srcset="">
                </div>
              </div>
            </div>

            <section class="tab-especificaciones" v-if="product_selected">
              <div class="container-lg">
                  <div class="title-box4">

                  </div>
                  <!-- Tabs Start -->
                  <div class="description-box">
                    <div class="row gy-4">
                      <div class="col-12">
                        <!-- Tabs Filter Start -->
                        <ul
                          class="nav nav-pills nav-tabs2 row-tab"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link active"
                              id="description-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#description"
                              type="button"
                              role="tab"
                              aria-controls="description"
                              aria-selected="true"
                            >
                              Descripción
                            </button>
                          </li>
          
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="specification-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#specification"
                              type="button"
                              role="tab"
                              aria-controls="specification"
                              aria-selected="false"
                            >
                              Especificaciones
                            </button>
                          </li>
          
                          <!-- <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="seller-tab" data-bs-toggle="pill" data-bs-target="#seller" type="button" role="tab" aria-controls="seller" aria-selected="false">Seller</button>
                                    </li> -->
          
                          <!-- <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="review-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#review"
                              type="button"
                              role="tab"
                              aria-controls="review"
                              aria-selected="false"
                            >
                              Recomendaciones <span>{{REVIEW_TOTAL}}</span>
                            </button>
                          </li> -->
                        </ul>
                        <!-- Tabs Filter End -->
                      </div>
          
                      <div class="col-12">
                        <!-- Tab Content Start -->
                        <div class="tab-content d-block" id="pills-tabContent">
                          <!-- Description Tab Content Start -->
                          <div
                            class="tab-pane fade show active"
                            id="description"
                            role="tabpanel"
                            aria-labelledby="description-tab"
                          >
                            <template v-if="product_selected.embed_youtube">
                              <div class="video-destacado" style="padding-bottom:20px" v-html="product_selected.embed_youtube">
                                  
                              </div>
                            </template>
                            <div class="details-product" v-if="product_selected.description">
                              <div v-html="product_selected.description"></div>
                            </div>
                          </div>
                          <!-- Description Tab Content End -->
          
                          <!-- Specification Tab Content Start -->
                          <div
                            class="tab-pane fade"
                            id="specification"
                            role="tabpanel"
                            aria-labelledby="specification-tab"
                          >
                            <div class="specification-wrap">
                              <!-- <p class="font-md content-color">
                                            The Model is wearing a white blouse from our stylist's collection, see the image for a mock-up of what the actual blouse would look like.it has text written on it in a black
                                            cursive language which looks great on a white color.
                                        </p> -->
                              <div class="table-responsive">
                                <table
                                  class="specification-table table striped"
                                  v-if="product_selected"
                                >
                                  <template
                                    v-for="(attribute, index) in this.product_selected
                                      .attributes"
                                    :key="index"
                                  >
                                    <tr>
                                      <th>
                                        {{
                                          attribute.attribute_categorie.attribute.name
                                        }}
                                      </th>
                                      <td>
                                        {{
                                          getAttributeN(attribute)
                                        }}
                                        <!-- attribute.value
                                            ? attribute.value
                                            : attribute.attribute_sub.name -->
                                        <!-- <template
                                          v-if="
                                            attribute.attribute_categorie.attribute
                                              .value
                                          "
                                        >
                                          {{
                                            attribute.attribute_categorie.attribute
                                              .value
                                          }}
                                        </template> -->
                                      </td>
                                    </tr>
                                  </template>
                                </table>
                              </div>
                            </div>
                          </div>
                          <!-- Specification Tab Content End -->
                        </div>
                        <!-- Tab Content End -->
                      </div>
                    </div>
                  </div>
                  <!-- Tabs End -->
              </div>
            </section>

      <div class="recomendacion description-box" v-if="product_selected && REVIEWS.length > 0">
        <div class="container-lg">
            <div class="title-box">
                <h4 class="unique-heading">⭐ Recomendaciones</h4>
            </div>
            <section class="product-page" style="padding-top:0px">
                <div class="container-lg">
                    <!---->
                    <div class="description-box">

                        <div class="col-12">
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade active show" id="review" role="tabpanel"
                                    aria-labelledby="review-tab">
                                    <div class="review-section">
                                      <div class="row gy-4 gy-md-5 g-4 g-xxl-5">
                                        <div class="col-md-8 col-xxl-7 order-2 order-md-1">
                                          <div class="review-left">
                                            <div class="title-box4">
                                              <h4 class="heading">
                                                Recomendaciones
                                                <!-- <span class="bg-theme-blue"></span> -->
                                              </h4>
                                            </div>
                                            <div class="question-wrap">
                                              <template v-for="REVIEW in REVIEWS" :key="REVIEW.id">
                                                <div class="comment-box">
                                                    <div class="img-box">
                                                    <img
                                                        :src="REVIEW.user.avatar"
                                                        alt="avatar"
                                                    />
                                                    </div>
                                                    <div class="avatar-content">
                                                    <div class="name-box">
                                                        <div class="user-info">
                                                        <h5>
                                                            <i data-feather="user"></i> {{ REVIEW.user.full_name }}
                                                        </h5>
                                                        <span>
                                                            <i data-feather="clock"></i> {{ REVIEW.created_at }}</span
                                                        >
                                                        </div>
                                                        <div class="action-box ms-auto">
                                                        <ul
                                                            class="rating p-0 mb d-none d-xl-flex"
                                                        >
                  
                                                        <li>
                                                            <i :class="{'fill': REVIEW.rating >= 1}"  data-feather="star"></i>
                                                        </li>
                                                        <li>
                                                            <i :class="{'fill': REVIEW.rating >= 2}"  data-feather="star"></i>
                                                        </li>
                                                        <li>
                                                            <i :class="{'fill': REVIEW.rating >= 3}"  data-feather="star"></i>
                                                        </li>
                                                        <li>
                                                            <i :class="{'fill': REVIEW.rating >= 4}"  data-feather="star"></i>
                                                        </li>
                                                        <li>
                                                            <i :class="{'fill': REVIEW.rating >= 5}" data-feather="star"></i>
                                                        </li>
                  
                                                        </ul>
                                                        <!-- <a
                                                            href="#replaySection"
                                                            class="replay-btn"
                                                            ><i data-feather="corner-up-left"></i>
                                                            Replay</a
                                                        > -->
                                                        </div>
                                                    </div>
                                                    <p>
                                                        {{ REVIEW.description }}
                                                    </p>
                                                    </div>
                                                </div>
                                              </template>
                  
                                            </div>
                                          </div>
                                        </div>
                  
                                        <div class="col-md-4 col-xxl-5 order-1 order-md-2">
                                          <div class="review-right sticky">
                                            <div class="customer-rating">
                                              <!-- <div class="title-box4">
                                                <h4 class="heading">
                                                  Customers Review<span
                                                    class="bg-theme-blue"
                                                  ></span>
                                                </h4>
                                              </div> -->
                  
                                              <div class="global-rating">
                                                <div>
                                                  <h5>{{REVIEW_AVG}}</h5>
                                                </div>
                                                <div>
                                                  <h6>Promedio de recomendaciones</h6>
                                                  <ul class="rating p-0 mb">
                                                    <li>
                                                        <i :class="{'fill': REVIEW_AVG >= 1}"  data-feather="star"></i>
                                                    </li>
                                                    <li>
                                                        <i :class="{'fill': REVIEW_AVG >= 2}"  data-feather="star"></i>
                                                    </li>
                                                    <li>
                                                        <i :class="{'fill': REVIEW_AVG >= 3}"  data-feather="star"></i>
                                                    </li>
                                                    <li>
                                                        <i :class="{'fill': REVIEW_AVG >= 4}"  data-feather="star"></i>
                                                    </li>
                                                    <li>
                                                        <i :class="{'fill': REVIEW_AVG >= 5}" data-feather="star"></i>
                                                    </li>
                                                    <li>
                                                      <span>({{ REVIEW_TOTAL }})</span>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                  
                                              <ul class="rating-progess">
                                                <li>
                                                  <h5>5 Star</h5>
                                                  <div class="progress">
                                                    <div
                                                      class="progress-bar"
                                                      role="progressbar"
                                                      style="width: 78%"
                                                      aria-valuenow="50"
                                                      aria-valuemin="0"
                                                      aria-valuemax="100"
                                                    ></div>
                                                  </div>
                                                  <h5>{{ getPercentageStart(5) }}%</h5>
                                                </li>
                                                <li>
                                                  <h5>4 Star</h5>
                                                  <div class="progress">
                                                    <div
                                                      class="progress-bar"
                                                      role="progressbar"
                                                      style="width: 62%"
                                                      aria-valuenow="50"
                                                      aria-valuemin="0"
                                                      aria-valuemax="100"
                                                    ></div>
                                                  </div>
                                                  <h5>{{ getPercentageStart(4) }}%</h5>
                                                </li>
                                                <li>
                                                  <h5>3 Star</h5>
                                                  <div class="progress">
                                                    <div
                                                      class="progress-bar"
                                                      role="progressbar"
                                                      style="width: 44%"
                                                      aria-valuenow="50"
                                                      aria-valuemin="0"
                                                      aria-valuemax="100"
                                                    ></div>
                                                  </div>
                                                  <h5>{{ getPercentageStart(3) }}%</h5>
                                                </li>
                                                <li>
                                                  <h5>2 Star</h5>
                                                  <div class="progress">
                                                    <div
                                                      class="progress-bar"
                                                      role="progressbar"
                                                      style="width: 30%"
                                                      aria-valuenow="50"
                                                      aria-valuemin="0"
                                                      aria-valuemax="100"
                                                    ></div>
                                                  </div>
                                                  <h5>{{ getPercentageStart(2) }}%</h5>
                                                </li>
                                                <li>
                                                  <h5>1 Star</h5>
                                                  <div class="progress">
                                                    <div
                                                      class="progress-bar"
                                                      role="progressbar"
                                                      style="width: 18%"
                                                      aria-valuenow="50"
                                                      aria-valuemin="0"
                                                      aria-valuemax="100"
                                                    ></div>
                                                  </div>
                                                  <h5>{{ getPercentageStart(1) }}%</h5>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
      </div>


      <section class="videos" v-if="product_selected && product_selected.videos.length > 0">
        <div class="container-lg">
            <div class="title-box">
                <h4 class="unique-heading">Videos sobre este producto</h4>
            </div>

            <div class="row justify-content-center">

              <template
                      v-for="(video, index) in product_selected.videos"
                      :key="index"
                      >
                <div class="col-3">
                  <!-- <a href="javascript:void(0)" @click="openVideoProduct(video)" data-bs-toggle="modal" data-bs-target="#viewModalVideo">
                      <video height="360">
                          <source :src="video.video" type="video/mp4">
                      </video>
                    </a> -->
                    <div v-html="video.embed"></div>
                </div>
              </template>
              
            </div>

            <!-- <div class="swiper product-slider">
                <div class="swiper-wrapper">

                    <div class="swiper-slide">
                        <a href="javascript:void(0)" @click="openVideoProduct()" data-bs-toggle="modal" data-bs-target="#viewModalVideo">

                            <video height="360">
                                <source src="/assets/video/reel1.mp4" type="video/mp4">
                            </video>
                        </a>
                    </div>

                    <div class="swiper-slide">
                        <a href="javascript:void(0)" @click="openVideoProduct()" data-bs-toggle="modal" data-bs-target="#viewModalVideo">

                            <video height="360">
                                <source src="/assets/video/reel2.mp4" type="video/mp4">
                            </video>
                        </a>
                    </div>

                    <div class="swiper-slide">
                        <a href="javascript:void(0)" @click="openVideoProduct()" data-bs-toggle="modal" data-bs-target="#viewModalVideo">

                            <video height="360">
                                <source src="/assets/video/reel3.mp4" type="video/mp4">
                            </video>
                        </a>
                    </div>

                    <div class="swiper-slide">
                        <a href="javascript:void(0)" @click="openVideoProduct()" data-bs-toggle="modal" data-bs-target="#viewModalVideo">

                            <video height="360">
                                <source src="/assets/video/reel2.mp4" type="video/mp4">
                            </video>
                        </a>
                    </div>



                </div>
            </div> -->

            <div class="follow-us">
                <p>Síguenos en Instagram <a href="https://www.instagram.com/zonadigitalsv/" target="_blank">
                        <img src="../assets/icons/svg/social/inta.svg" alt="Instagram"> zonadigitalsv </a></p>
            </div>

        </div>
      </section>

      
      <div class="pt-4 ratio_asos" v-if="product_selected">
        <div class="container-lg">
            <div class="title-box">
                <h4 class="unique-heading">🔥 Otros clientes también compraron</h4>
            </div>

            <Carousel v-bind="settings" :breakpoints="breakpoints">
              <Slide v-for="(product, index) in product_hots"
                  :key="index">
                <div class="carousel__item">
                  <CardProductA v-if="product" :product="product"></CardProductA>
                </div>
              </Slide>

              <template #addons>
                <Navigation />
              </template>
            </Carousel>

            <!-- <div class="swiper product-slider-2">
                <div class="swiper-wrapper">
                <template
                  v-for="(product, index) in product_hots"
                  :key="index"
                >
                  <div class="swiper-slide">
                    <CardProductA v-if="product" :product="product"></CardProductA>
                  </div>
                </template>
                </div>
            </div> -->
        </div>
      </div>

      <main class="main" v-if="!product_selected && !isLoadingProduct">
        <section class="page-not-found">
          <div class="container">
            <div class="row gx-md-2 gx-0 gy-md-0 gy-3">
              <div class="col-md-8 m-auto">

                <div class="page-image error-404">
                  <h1 class="title-error">Ups, página no encontrada.</h1>
                  <img src="/assets/images/404/zeta-404.svg" class="img-fluid blur-up lazyload" alt="Error 404" />
                  <a href="/" class="btn-solid mb-line">Volver al inicio </a>
                </div>

              </div>
            </div>
          </div>
        </section>
      </main>

      <!-- New Arrived Section Start -->
      <section class="pt-4 ratio_asos" v-if="product_selected">
        <div class="container-lg">
          <div class="title-box">
            <h4 class="unique-heading">
              👍 Productos recomendados 
            </h4>
          </div>

          <Carousel v-bind="settings" :breakpoints="breakpoints">
            <Slide v-for="(product, index) in product_destacados"
                :key="index">
              <div class="carousel__item">
                <CardProductA v-if="product" :product="product"></CardProductA>
              </div>
            </Slide>

            <template #addons>
              <Navigation />
            </template>
          </Carousel>

          <!-- <div class="swiper product-slider">
            <div class="swiper-wrapper">
              <template
                v-for="(product, index) in product_destacados"
                :key="index"
              >
                <div class="swiper-slide">
                  <CardProductA v-if="product" :product="product"></CardProductA>
                </div>
              </template>
            </div>
          </div> -->
        </div>
      </section>

      <div class="pt-4 ratio_asos" v-if="!isMobile() && !product_selected && isLoadingProduct">
          <div class="container-lg">
            <div class="title-box">
                <h4 class="unique-heading">👍 Productos recomendados  </h4>
            </div>
            <div class="col-12">
              <img src="/assets/svg_load/shop-4cards-preloader.svg" alt="" srcset="">
            </div>
          </div>
      </div>

      <div class="pt-4 ratio_asos" v-if="isMobile() && !product_selected && isLoadingProduct">
          <div class="container-lg">
            <div class="title-box">
                <h4 class="unique-heading">👍 Productos recomendados  </h4>
            </div>
            <div class="col-12">
              <img src="/assets/svg_load/shop-2cards-preloader.svg" alt="" srcset="">
            </div>
          </div>
      </div>

      <section class="service-section services-flex">
        <div class="container-lg">
            <div class="row g-3 g-md-4 g-lg-0">
                <div class="col-6 col-lg-3">
                    <div class="service-box">
                        <div class="media">
                        <img class="img-fluid" src="/assets/images/features/envios-a-todo-el-pais.png" alt="Envios rápidos a todo el pais" />
                        </div>
                    </div>
                </div>

                <div class="col-6 col-lg-3">
                    <div class="service-box">
                        <div class="media">
                        <img class="img-fluid" src="/assets/images/features/nuevos-productos.png" alt="Nuevos productos todas las semanas" />
                        </div>
                    </div>
                </div>

                <div class="col-6 col-lg-3">
                    <div class="service-box">
                        <div class="media">
                        <img class="img-fluid" src="/assets/images/features/soporte-tecnico.png" alt="Soporte técnico" />
                            
                        </div>
                    </div>
                </div>

                <div class="col-6 col-lg-3">
                    <div class="service-box">
                        <div class="media">
                        <img class="img-fluid" src="/assets/images/features/seguridad-ssl.png" alt="Compras en línea seguras con certificado SSL" />
                            
                        </div>
                    </div>
                </div>

            </div>
        </div>
      </section>
      <!-- New Arrived Section End -->
      
    </main>
    <!-- Main End -->
    <!-- View Product Modal Start -->
    <DetailProduct />
    <!-- View Product Modal End -->
    <!-- View Product Modal Start -->
    <VideoProduct />
    <!-- View Product Modal End -->
    <template v-if="product_selected">
      <template v-for="(image, index) in product_selected.images" :key="index">
        <ImagenProduct :index="index" />
      </template>
    </template>

  </div>
</template>
<script>
import myApi from "../plugins/axios";
import DetailProduct from "../views/guest/modal/DetailProduct.vue";
import CardProductA from './cards/CardProductA.vue'
import CartResource from '../resource/cart'
import WishListResource from '../resource/wishlist'
import CompareResource from '../resource/compare'
import VideoProduct from './modal/VideoProduct.vue'
import ImagenProduct from './modal/ImagenProduct.vue'
import { useMeta } from 'vue-meta'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default {
  name: "Detalle_de_producto",
  components: {
    DetailProduct,
    CardProductA,
    VideoProduct,
    ImagenProduct,
    // 
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      product_slug: null,
      product_selected: null,
      quantity: 1,
      product_relateds: [],
      product_destacados: [],
      product_hots: [],
      CARTS:[],
      WISHLIST:[],
      REVIEWS: [],
      REVIEW_TOTAL: 0,
      REVIEW_AVG: 0,
      REVIEW_TOTALS: [],
      URL_QUERY_PARAMS: '',
      OFFER: null,
      DISCOUNT_GENERAL: null,
      // 
      url: "",
      // 
      today: null,
      next_name_day: null,
      next_num_day: null,
      next_name_two_day: null,
      next_num_two_day: null,
      next_num_month: null,
      isLoadingProduct: false,
      // 
      ERROR_TEXT: null,
      useMetaT: null,
      white: null,
      dark: null,
      // 
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 3.5,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: 'start',
        },
      },
    };
  },
  methods: {
    getAttributeN(attribute){
      if(attribute.value){
        let text_before = attribute.attribute_categorie.attribute.s_option == 1 ? attribute.attribute_categorie.attribute.value :  "";
        let text_after = attribute.attribute_categorie.attribute.s_option == 2 ? attribute.attribute_categorie.attribute.value : "";
        return  text_before + " " + attribute.value + " " + text_after ;
      }else if(attribute.sub_attributes.length > 0){ 
        let CONCAT = "";
        attribute.object_sub_attributes.forEach((SUBA) => {
          let text_before = SUBA.s_propiedad == 1 ? " "+ SUBA.value_propiedad :  "";
          let text_after = SUBA.s_propiedad == 2 ? " "+ SUBA.value_propiedad : "";
          CONCAT = CONCAT + (text_before+SUBA.name+text_after+ " / ")
        });
        return  CONCAT;
      }else{
        let text_before = attribute.attribute_sub.s_propiedad == 1 && attribute.attribute_sub.value_propiedad ? attribute.attribute_sub.value_propiedad :  "";
        let text_after = attribute.attribute_sub.s_propiedad == 2 ? attribute.attribute_sub.value_propiedad : "";
        return  text_before + " " + attribute.attribute_sub.name + " " + text_after ;
      }

    },
    getAmountTotal(product){
        if(product.discount_g && !product.code_offer){
            if(product.discount_g.discount_id){
            return Number(product.discount_g.new_amount.toFixed(2));
            // percentage
            }else{
            if(product.discount_g.type_discount == 1){//%
                return Number((product.precio_general - (product.precio_general*product.discount_g.discount*0.01)).toFixed(2));
            }else{
                return Number((product.precio_general - product.discount_g.discount).toFixed(2));
            }
            }
        }
        if(product.code_offer){
            if(product.code_offer.new_amount){
            return Number(product.code_offer.new_amount.toFixed(2));
            // percentage
            }else{
            if(product.code_offer.type_discount == 1){//%
                return Number((product.precio_general - (product.precio_general*product.code_offer.discount*0.01)).toFixed(2));
            }else{
                return Number((product.precio_general - product.code_offer.discount).toFixed(2));
            }
            }
        }
        return product.precio_general;
    },
    getAmountDiscount(product){
        return product.precio_general;
    },
    actualizarMetaetiquetas(titulo, descripcion, imagen,url) {
      const ogTitle = document.querySelector('meta[property="og:title"]');
      const ogDescription = document.querySelector('meta[property="og:description"]');
      const ogImage = document.querySelector('meta[property="og:image"]');
      const ogUrl = document.querySelector('meta[property="og:url"]');
      
      ogTitle.content = titulo;
      ogDescription.content = descripcion ? descripcion : '';
      ogImage.content = imagen;
      ogUrl.content = url;
    },
    async showProduct() {
      this.isLoadingProduct = true;
      try {
        const resp = await myApi.get(
          "ecommerce/show_product/" + this.product_slug+this.URL_QUERY_PARAMS
        );
        console.log(resp);
        // this.OFFER = resp.data.OFFER;
        this.isLoadingProduct = false;
        if(resp.data.message == 404){
          this.product_selected = null;
        }else{
          resp.data.product.code_offer = resp.data.OFFER;
          
          this.product_selected = resp.data.product;

          this.DISCOUNT_GENERAL = this.product_selected.discount_g;

          this.today = resp.data.today;
          this.next_name_day = resp.data.next_name_day;
          this.next_num_day = resp.data.next_num_day;
          this.next_name_two_day = resp.data.next_name_two_day;
          this.next_num_two_day = resp.data.next_num_two_day;
          this.next_num_month = resp.data.next_num_month;
          this.reloadProductRelateds();
          this.reloadMyJs();

          this.url = process.env.VUE_APP_FRONTED + 'product/' + this.product_selected.slug;

          this.actualizarMetaetiquetas(this.product_selected.title, this.product_selected.summary,
                this.product_selected.image,this.url);

          // this.useMetaT.meta.meta = [
            //     {
            //         property: 'og:site_name',
            //         content: 'TIENDA | ZONA DIGITAL',
            //         // vmid: 'og:site_name'
            //     },
            //     {
            //         property: 'fb:app_id',
            //         content: '146177904330672',
            //         // vmid: 'og:title'
            //     },
            //     {
            //         property: 'og:title',
            //         content: this.product_selected.title + ' | ZONA DIGITAL ',
            //         // vmid: 'og:title'
            //     },
            //     {
            //         property: 'og:description',
            //         content: this.product_selected.summary,
            //         // vmid: 'og:description'
            //     },
            //     {
            //         property: 'og:image',
            //         content: this.product_selected.image,
            //         // vmid: 'og:image'
            //     },
            //     {
            //         property: 'og:url',
            //         content: this.url,
            //         // vmid: 'og:url'
            //     },
            //     {
            //         property: 'og:type',
            //         content: 'website',
            //         // vmid: 'og:url'
            //     },
            //     // 

            //     {
            //         property: 'twitter:title',
            //         content: (this.product_selected.title) + ' | ZONA DIGITAL',
            //         // vmid: 'twitter:title'
            //     },
            //     {
            //         property: 'twitter:description',
            //         content: this.product_selected.summary,
            //         // vmid: 'twitter:description'
            //     },
            //     {
            //         property: 'twitter:image',
            //         content: this.product_selected.image,
            //         // vmid: 'twitter:image'
            //     },
            //     {
            //         property: 'twitter:url',
            //         content: this.url,
            //         // vmid: 'twitter:url'
            //     }
          // ];
        }
      } catch (error) {
        this.isLoadingProduct = false;
      }
    },
    async reloadProductRelateds(){
      const resp = await myApi.get(
        "ecommerce/products_relateds/" + this.product_selected.id
      );
      console.log(resp);
      this.product_relateds = resp.data.product_relateds.data;
      this.product_destacados = resp.data.product_destacados.data;
      this.product_hots = resp.data.product_hots.data;
      this.reloadMyJsTwo();
      this.reloadReviews();
    },
    async reloadReviews(){
      const resp = await myApi.get(
        "ecommerce/show_reviews/" + this.product_selected.id
      );
      console.log(resp);
      this.REVIEWS = resp.data.reviews;
      this.REVIEW_TOTAL = resp.data.total;
      this.REVIEW_AVG = resp.data.avg_review;
      this.REVIEW_TOTALS = resp.data.reviews_count;
      this.reloadMyJs();
    },
    getPercentageStart(VAL){
        let RAT = this.REVIEW_TOTALS.find(item => item.rating == VAL);
        return RAT ? Math.round((RAT.total/this.REVIEW_TOTAL)*100,0) : 0;
    },
    goDetailProduct(product) {
      this.$router.push({
        name: "DetailProduct",
        params: {
          slug: product.slug,
        },
      });
    },
    openDetailProduct(product) {
      this.emitter.emit("product-detail-modal", product);
    },
    reloadMyJsTwo(){
      const script3 = document.createElement("script");
      script3.src = "../../assets/js/timer.js";
      document.body.appendChild(script3);
      script3.onload = () => {
        console.log("archivo.js cargado 3");
      };
    },
    reloadMyJs() {
      const detail_product = document.createElement("script");
      detail_product.src = "../../assets/js/myjs/detailproduct.js";
      document.body.appendChild(detail_product);
      detail_product.onload = () => {
        console.log("archivo.js cargado detail_product");
      };
      const script2 = document.createElement("script");
      script2.src = "../../assets/js/feather/feather.min.js";
      document.body.appendChild(script2);
      script2.onload = () => {
        console.log("archivo.js cargado 2");
      };
      const script3 = document.createElement("script");
      script3.src = "../../assets/js/myjs/loadredirect.js";
      document.body.appendChild(script3);
      script3.onload = () => {
        console.log("archivo.js cargado 4");
      };
    },
    addCart(PRODUCT_SELECTED = null) {
      let CARTS = this.CARTS;
      this.ERROR_TEXT = null;
       CartResource.add(CARTS,(!PRODUCT_SELECTED ? this.product_selected : PRODUCT_SELECTED),this.emitter,this.$notify,this.$cookies,this.quantity);
      // let INDEX = CARTS.findIndex(
      //   (product) => product.uniqd == this.product_selected.uniqd
      // );

      // if (INDEX != -1) {
      //   CARTS[INDEX].quantity = CARTS[INDEX].quantity + this.quantity;
      //   CARTS[INDEX].total = CARTS[INDEX].subtotal * CARTS[INDEX].quantity;
      // } else {
      //   CARTS.push({
      //     title: this.product_selected.title,
      //     imagen: this.product_selected.image,
      //     uniqd: this.product_selected.uniqd,
      //     slug: this.product_selected.slug,
      //     quantity: this.quantity,
      //     price_unit: Math.round(this.product_selected.precio_general),
      //     subtotal: Math.round(this.product_selected.precio_general),
      //     total:
      //       Math.round(this.product_selected.precio_general) * this.quantity,
      //     type_discount: null,
      //     discount: null,
      //     code_cupon: null,
      //     code_discount: null,
      //   });
      // }

      // this.$cookies.set("carts", CARTS);

      // this.emitter.emit("cart-shop", CARTS);

      // this.$notify({
      //   title: "CARRITO DE COMPRA",
      //   text: `TU PRODUCTO '${this.product_selected.title}' SE AGREGO AL CARRITO CORRECTAMENTE!`,
      //   duration: 1000,
      // });
    },
    addBuy(){
      this.addCart();
      setTimeout(() => {
        this.$router.push({
          name: "Checkout"
        });
      }, 100);
    },
    addPackage(){
        let CARTS = this.CARTS;//this.$cookies.get("carts") ? this.$cookies.get("carts") : [];

        this.product_selected.packages.forEach((pack) => {
            let INDEX = CARTS.findIndex(
                (product) => product.uniqd == pack.uniqd
            );
            if(INDEX == -1){
              CartResource.add(CARTS,pack,this.emitter,this.$notify,this.$cookies);
                // CARTS.push({
                //     title: pack.title,
                //     imagen: pack.image,
                //     uniqd: pack.uniqd,
                //     slug: pack.slug,
                //     quantity: 1,
                //     price_unit: Math.round(pack.precio_general),
                //     subtotal: Math.round(pack.precio_general),
                //     total:
                //         Math.round(pack.precio_general) * 1,
                //     type_discount: null,
                //     discount: null,
                //     code_cupon: null,
                //     code_discount: null,
                // });
            }
        });

        this.addCart();
        // this.$cookies.set("carts", CARTS);

        // this.emitter.emit("cart-shop", CARTS);

        // this.$notify({
        //     title: "CARRITO DE COMPRA",
        //     text: `LOS PRODUCTOS SE AGREGARON AL CARRITO CORRECTAMENTE!`,
        //     duration: 1000,
        // });
    },
    GetTotalPackegs(packages,product_selected){
      return parseFloat(packages.reduce((sum,item) => sum + parseFloat(item.precio_general), 0) + parseFloat(product_selected.precio_general)).toFixed(2);
    },
    minusQuantity(){
      if(this.quantity == 1){
        this.quantity = 1;
        return;
      }
      this.quantity --;
    },
    addQuantity(){
      this.quantity ++;
    },
    openVideoProduct(video = 1){
      console.log(video);
      this.emitter.emit('product-video-modal', video);
    },
    openImagensProduct(imagen,index){
      console.log(imagen);
      this.emitter.emit('product-imagenes-modal-'+index, this.product_selected.images);
    },
    // Productos relacionados
    addWishList(productT = null){
      let WISHLIST = this.WISHLIST;
      WishListResource.add(WISHLIST,(!productT ? this.product_selected : productT),this.emitter, this.$notify,this.$cookies);
    },
    goToCompare(productT = null){
      // console.log(productT);
      CompareResource.add((!productT ? this.product_selected : productT),this.emitter, this.$notify,this.$cookies,this.$router)
    },
    getDateTimeDiscount(){
      if(typeof this.DISCOUNT_GENERAL.discount === 'string' || this.DISCOUNT_GENERAL.discount instanceof String){
          return this.DISCOUNT_GENERAL.date_to;
      }else{
          if(this.DISCOUNT_GENERAL.discount){
              return this.DISCOUNT_GENERAL.discount.date_to;//discount.discount
          }
      }
      return null;
    },
    getDiscountPrice(product){
      if(typeof product.discount_g.discount === 'string' || product.discount_g.discount instanceof String){
          return parseInt(product.discount_g.discount)+"%";
      }else{
          if(product.discount_g.discount){
              return Math.ceil(product.discount_g.percentage)+"%";//discount.discount
          }
      }
      return "%"
    },
    getTagNew(product){
      if(product.tags_multiples.length > 0){
          let filterx = product.tags_multiples.filter(item => item == '1' || item == '3')
          return filterx.length > 0 ? true : false;
      }
      return false;
    },
    getNameTagNew(product){
      if(product.tags_multiples.length > 0){
        let tag_m = product.tags_multiples.find(item => item == '1');
        let tag_m_2 = product.tags_multiples.find(item => item == '3');
        if(tag_m || tag_m_2){
          if(tag_m_2){
            return "Reingreso";
          }
          if(tag_m){
            return "Nuevo";
          }
        }
      }
      return false;
    },
    getOfferPrice(product){
        if(product.code_offer.type_discount == 1){
            return parseInt(product.code_offer.discount)+"%";
        }
        if(product.code_offer.type_discount == 2){
            return "$";
        }
        if(!product.code_offer.type_discount){
          return this.getDiscountPrice(product);
        }
        return "%"
    },
    zoom(e) {
        var zoomer = e.currentTarget;
        var offsetX = e.offsetX;
        var offsetY = e.offsetY;
        var x = offsetX / zoomer.offsetWidth * 100;
        var y = offsetY / zoomer.offsetHeight * 100;
        zoomer.style.backgroundPosition = x + '% ' + y + '%';
    },
    makeTitle(slug) {
      var words = slug.split('-');
      for (var i = 0; i < words.length; i++) {
          var word = words[i];
          words[i] = word.charAt(0).toUpperCase() + word.slice(1);
      }
      return words.join(' ');
    },
    isMobile() {
      if(window.innerWidth <= 767 || screen.width <= 767) {
          return true;
      }
      else {
          return false;
      }
    },
    isDarkLigth(){
      let darkId = localStorage.getItem("darkId") ? localStorage.getItem("darkId") : null;
      if(darkId == 'light-btn'){
          this.white = null;
          this.dark = true;
      }

      if(darkId == 'dark-btn'){
          this.dark = null;
          this.white = true;
      }
    }
  },
  created() {
    this.product_slug = this.$route.params.slug;
    this.isDarkLigth();
  },
  mounted() {
    let TITLE_PRODUCT = this.makeTitle(this.product_slug);
    this.useMetaT = useMeta({
        title: TITLE_PRODUCT.charAt(0).toUpperCase() + TITLE_PRODUCT.slice(1),
        htmlAttrs: { lang: 'en', amp: true },
        meta: [],
    })

    if(this.$route.query.CODE_OFFER){
      this.URL_QUERY_PARAMS = "?CODE_OFFER="+this.$route.query.CODE_OFFER
    }
    this.showProduct();
    this.emitter.on('cart-shop',data => {
      // console.log(data);
         this.CARTS = data;
    });
    this.emitter.emit("cart-list", {});

    //PARA LA LISTA DE DESEO
    this.emitter.on('wish-list-product',data => {
         this.WISHLIST = data;
    });
    this.emitter.emit("wish-list-list", {});
    
    this.emitter.on('dark-ligth',() => {
      this.isDarkLigth();
    });
     //PARA ERROR
    this.emitter.on('add-error-cart-shop',data => {
        //  this.WISHLIST = data;
        this.ERROR_TEXT = data;
    });
  },
};
</script>
<style>
  /* .primary-img img{
    max-height: 254px !important;
    min-height: 254px !important;
    object-fit: contain;
  } */
</style>