<template>
    <!-- Main Start -->
    <div class="main">
      <section class="page-body p-0">
        <div class="row">
          <div class="order-2 order-lg-1 col-lg-5">
            <div class="content-box">
              <div>
                <h5>Iniciar Sesión </h5>
           
                <p>Ingresa tus datos al siguiente formulario</p>
                <form action="index.php" class="custom-form form-pill">
                  <div class="input-box1">
                    <label for="email">Correo Electrónico</label>
                    <input class="form-control" v-model="email" type="email" required name="email" id="email" />
                  </div>

                  <div class="input-box1">
                    <label for="password">Contraseña</label>
                    <div class="icon-input">
                      <input class="form-control" type="password" v-model="password" required name="password" id="password" />
                      <img class="showHidePassword" src="/assets/icons/svg/eye-1.svg" alt="eye" />
                    </div>
                  </div>

                  <router-link to="/recuperar-credenciales" class="forgot-link" >Olvidaste Contraseña?</router-link>

                  <button type="button" @click="login()" class="btn-solid rounded-pill line-none">Ingresar </button>
                  <a href="#" @click="tohome()" class="btn-solid rounded-pill line-none btn-outline mt-3 d-flex justify-content-center">Ir al sitio web </a>
                </form>

                <span class="backto-link font-default content-color text-decoration-none">Si eres nuevo, <a class="text-decoration-underline theme-color" href="#" @click="toregister()"> ¡Crea una cuenta ahora! </a> </span>
                
                <!-- <div id="g_id_onload"
                      data-client_id="820389707210-noeljepidcc5jh7h7tu3d91rsh7ra99r.apps.googleusercontent.com"
                      data-callback="handleCredentialResponse">
                  </div>
                  <div class="g_id_signin" data-type="standard"></div> -->

                <!--   <span class="line"><span>Or </span> </span>
                <div class="link-group">
                  <a href="https://www.google.com/" class="button-link"><img src="/assets/icons/png/google.png" alt="google" /> Sign in </a>
                  <a href="https://www.facebook.com/" class="button-link"><img src="/assets/icons/png/fb.png" alt="facebook" /> Sign in </a>
                </div> -->
              </div>
            </div>
          </div>

          <div class="order-1 order-lg-2 col-lg-7">
            <div class="img-box">
              <img class="bg-img" src="/assets/images/bg/bg-zd-000.jpg" alt="banner" />
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- Main End -->
</template>
<script>
// import FacebookLogin from "vue3-facebook-login";
import myApi from '../../plugins/axios'
import store from '../../plugins/store'
// import VFacebookLogin from 'vue-facebook-login-component'

export default {
    name: 'LoginSection',
    components: {
      // VFacebookLogin,
    },
    data(){
        return {
            email: null,
            password: null,
            params: {
              scope: 'email profile',
              prompt: 'select_account',
              fetch_basic_profile: true,
            },
            googleSignInParams: {
              client_id: '820389707210-noeljepidcc5jh7h7tu3d91rsh7ra99r.apps.googleusercontent.com'
            },
            gogleIn:{
              email: null,
              name: null,
              surname: null,
              imagen: null,
              google_id: null,
            },
            appId: "274941987794893",
        }
    },
    methods: {
        toregister(){
            this.$router.push({
                name: 'Register',
            })
        },
        tohome() {
            this.$router.push({
                name: 'Home',
            })
        },
        async login(){
          try {
            let resp = await myApi.post('ecommerce/login',{email: this.email,password: this.password});
            console.log(resp);
            if(resp.data.error){
              for (let key in resp.data.error) {
                this.$notify({
                    title: "VALIDACIÓN",
                    text: resp.data.error[key],
                    duration: 1000,
                    type:'error',
              });
              }
            }else{
              store.dispatch('saveToken',{
                token: resp.data.token,
                user: resp.data.user,
              });
              setTimeout(() => {
                window.location.href = process.env.VUE_APP_FRONTED;
              }, 50);
            }
          } catch (err) {
            if(err.response.data){
                  // alert(err.response.data.message)
                this.$notify({
                      title: "VALIDACIÓN",
                      text: err.response.data.message,
                      duration: 1000,
                      type:'error',
                });
              }else{
                this.$notify({
                      title: "VALIDACIÓN",
                      text: "Hubo un error al intentar registrarse, intentalo nuevamente",
                      duration: 1000,
                      type:'error',
                });
              }
              console.log(err);
          }
        },
        handleLogin(response) {
            console.log("User Successfully Logged In" , response)
        },
        handleLoginFailed() {
            console.log("User Cancelled or Abort the Login")
        },
        //  async handleCredentialResponse(response){
        //   console.log(response)
        //  },
        decodeJwtResponse (token) 
        {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        },
        actualizarMetaetiquetas(titulo, descripcion, imagen,url) {
            const ogTitle = document.querySelector('meta[property="og:title"]');
            const ogDescription = document.querySelector('meta[property="og:description"]');
            const ogImage = document.querySelector('meta[property="og:image"]');
            const ogUrl = document.querySelector('meta[property="og:url"]');
            ogTitle.content = titulo;
            ogDescription.content = descripcion ? descripcion : '';
            if(imagen){
                ogImage.content = imagen;
            }
            ogUrl.content = url;
        },
    },
    mounted() {
        const script2 = document.createElement('script');
        script2.src = '../../assets/js/password-showhide.js';
        document.body.appendChild(script2);
        script2.onload = () => {
            console.log('archivo.js cargado 2');
        }
        this.actualizarMetaetiquetas(
          'Iniciar Sesión',
          'Inicia sesión en tu cuenta para acceder a beneficios exclusivos y realizar un seguimiento de tus compras. ¡Bienvenido de nuevo a Zona Digital!',
          null,'https://storeszd.webunify.cloud/login'
        );
        window.handleCredentialResponse = async (response) => {
          // decodeJwtResponse() is a custom function defined by you
          // to decode the credential response.
          let responsePayload = this.decodeJwtResponse(response.credential);

          // console.log("ID: " + responsePayload.sub);
          // console.log('Full Name: ' + responsePayload.name);
          // console.log('Given Name: ' + responsePayload.given_name);
          // console.log('Family Name: ' + responsePayload.family_name);
          // console.log("Image URL: " + responsePayload.picture);
          console.log("Email: " + responsePayload.email);

          this.gogleIn.email = responsePayload.email;
          this.gogleIn.name = responsePayload.given_name;
          this.gogleIn.surname = responsePayload.family_name;
          this.gogleIn.imagen = responsePayload.picture;
          this.gogleIn.google_id = responsePayload.sub;

          try {
            let resp = await myApi.post('ecommerce/login-google',{google: this.gogleIn});
            console.log(resp);
            if(resp.data.error){
              for (let key in resp.data.error) {
                this.$notify({
                    title: "VALIDACIÓN",
                    text: resp.data.error[key],
                    duration: 1000,
                    type:'error',
              });
              }
            }else{
              store.dispatch('saveToken',{
                token: resp.data.token,
                user: resp.data.user,
              });
              setTimeout(() => {
                window.location.href = process.env.VUE_APP_FRONTED;
              }, 50);
            }
          } catch (err) {
            console.log(err);
              if(err.response.data){
                  // alert(err.response.data.message)
                this.$notify({
                      title: "VALIDACIÓN",
                      text: err.response.data.message,
                      duration: 1000,
                      type:'error',
                });
              }else{
                this.$notify({
                      title: "VALIDACIÓN",
                      text: "Hubo un error al intentar registrarse, intentalo nuevamente",
                      duration: 1000,
                      type:'error',
                });
              }
          }

        }
        setTimeout(() => {
          const script3 = document.createElement('script');
          script3.src = 'https://accounts.google.com/gsi/client';
          document.body.appendChild(script3);
          script3.onload = () => {
              console.log('archivo.js cargado 3');
          }
        }, 50);

        // https://cdn.jsdelivr.net/npm/vue-facebook-login-component/dist/vueFacebookLoginComponent.umd.min.js
    }, 
}
</script>
<style>
  .g_id_signin > div{
    display: flex;
    justify-content: center;
  }
</style>