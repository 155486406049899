<template>
    <div>
        <!-- Main Start -->
        <main class="main">
            <!-- Breadcrumb Start -->
            <div class="breadcrumb-wrap">
                <div class="breadcrumb-wrap">
                    <div class="banner">
                        <img class="bg-img bg-top" src="/assets/images/bg/bg-header-001.jpg" alt="banner" />

                        <div class="container-lg">
                            <div class="breadcrumb-box">
                            <div class="heading-box">
                                <h1> Condiciones de Ofertas</h1>
                            </div>
                            <ol class="breadcrumb">
                                <li><a href="/">Inicio</a></li>
                                <li>
                                <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                                </li>
                                <li class="current"><a href="#" onclick="return false;">Condiciones de Ofertas</a></li>
                            </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--  -->
            
            <section class="info-condiciones">
                <div class="box-condiciones">
                    <div class="img-mascota-box">
                        <img src="https://storeszd.webunify.cloud/assets/images/brands/zeta-brands.svg" alt="">
                    </div>
                    <div v-html="condition"></div>
                </div>
            </section>

            <template v-if="offert_id">
                <section class="info-condiciones ofertas" v-for="(discount, index) in discounts" :key="index">
                    <div class="box-condiciones" v-if="discount.politica && offert_id == discount.id">
                        <div>
                            <div class="title-condiciones" v-if="discount.politica">
                                <h2>{{ discount.title }}</h2>
                            </div>
                            <div class="img-mascota-oferta">
                                <img :src="discount.imagen_banner" alt="">
                            </div>
                        </div>
                        <div v-html="discount.politica"></div>
                    </div>
                </section>
            </template>

            <template v-if="!offert_id">

                <section class="info-condiciones ofertas" v-for="(discount, index) in discounts" :key="index">
                    <div class="box-condiciones" v-if="discount.politica">
                        <div>
                            <div class="title-condiciones" v-if="discount.politica">
                                <h2>{{ discount.title }}</h2>
                            </div>
                            <div class="img-mascota-oferta">
                                <img :src="discount.imagen_banner" alt="">
                            </div>
                        </div>
                        <div v-html="discount.politica"></div>
                    </div>
                </section>
                
            </template>
            
        </main>
        <!-- Main End -->
    </div>
</template>
<script>
import myApi from "../../../plugins/axios";
export default {
    name: 'CondicionYOferta',
    data() {
        return {
            condition: null,
            discounts: [],
            offert_id: null,
        }
    },
    methods: {
        async listConfiguration(){
            const resp = await myApi.get('ecommerce/config_aviso_politica');
            console.log(resp);
            this.condition = resp.data.config_general.condition;
            this.discounts = resp.data.discounts;
            // this.aviso_marca = resp.data.config_general.aviso_marca;
            // this.garantia = resp.data.config_general.garantia;
            // this.privacidad = resp.data.config_general.privacidad;
            // this.delivery = resp.data.config_general.delivery;

        },
    },
    created(){
        this.offert_id = this.$route.query.offer_id;
    },
    mounted() {
        this.listConfiguration();
    },
}
</script>