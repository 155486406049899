<template>
    <div>
        <!-- Main Start -->
        <main class="main">
            <!-- Breadcrumb Start -->
            <div class="breadcrumb-wrap">
                <div class="banner">
                    <img class="bg-img bg-top" src="/assets/images/bg/bg-header-001.jpg" alt="banner" />

                    <div class="container-lg">
                        <div class="breadcrumb-box">
                        <div class="heading-box">
                            <h1>Mi Carrito</h1>
                        </div>
                        <ol class="breadcrumb">
                            <li><a href="/">Inicio</a></li>
                            <li>
                            <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                            </li>
                            <li><a href="/shop">Tienda</a></li>
                            <li>
                            <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                            </li>
                            <li class="current"><a href="javascript:void(0)">Carrito</a></li>
                        </ol>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Breadcrumb End -->

            <!-- Cart Section Start -->
            <section class="section-b-space card-page">
                <div class="container-lg">
                    <div class="row g-3 g-md-4 cart">
                        <div class="col-md-7 col-lg-8">
                            <div class="cart-wrap">
                                <div class="items-list">
                                <table class="table cart-table m-md-0">
                                    <thead>
                                    <tr>
                                        <th class="d-none d-sm-table-cell">PRODUCTO</th>
                                        <th class="d-none d-sm-table-cell">PRECIO</th>
                                        <th class="d-none d-lg-table-cell">CANTIDAD</th>
                                        <th class="d-none d-xl-table-cell">TOTAL</th>
                                        <th></th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                        
                                        <template v-for="(CART, index) in CARTS" :key="index">
                                            <tr>
                                                <td>
                                                <div class="product-detail">
                                                    <img class="pr-img" v-bind:src="CART.imagen" alt="image" />
                                                    <div class="details">
                                                        <h4 class="title-color font-default2">{{CART.title}}</h4>
                                                        <!-- <span class="sold-by">Sold By: <span>Roger Group</span> </span> -->
                                                        <!-- <span class="size gap-2 d-flex">Size : <span>M</span> </span> -->
                                                        <span class="size gap-2 d-flex d-sm-none">Precio : <span>$ {{CART.total}} </span> </span>
                                                        <div class="plus-minus mt-2 d-inline-flex d-lg-none">
                                                            <a href="javascript:void(0)" @click="minusCart(CART)"> <i class="sub" data-feather="minus"></i> </a>
                                                            <input type="number" :value="CART.quantity" min="1" max="10" />
                                                            <a href="javascript:void(0)" @click="plusCart(CART)"> <i class="add" data-feather="plus"></i> </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                </td>

                                                <td class="price d-none d-sm-table-cell">$ {{CART.subtotal}}
                                                 
                                                    <template v-if="CART.code_cupon || CART.code_discount || CART.code_offer">
                                                        <br>
                                                        <small class="sold-by" v-if="CART.code_cupon && !CART.code_discount && !CART.code_offer">Cupon: <span>{{ CART.code_cupon }}</span> </small>
                                                        <small class="sold-by" v-if="CART.code_discount">Descuento: <span>{{ CART.code_discount }}</span> </small>
                                                        <small class="sold-by" v-if="CART.code_offer">Oferta: <span>{{ CART.code_offer }}</span> </small>
                                                        <br>
                                                        <small class="sold-by" v-if="CART.type_discount == 1">Descuento : <span>- {{ CART.discount }} %</span> </small>
                                                        <br v-if="CART.type_discount == 1">
                                                        <small class="sold-by" v-if="CART.type_discount == 2">Descuento : <span>- $ {{ CART.discount }} </span> </small>
                                                        <br v-if="CART.type_discount == 2">
                                                        <small class="sold-by">Precio original : <span> $ {{ CART.price_unit }} </span> </small>
                                                    </template>
                                                </td>
                                                <td class="d-none d-lg-table-cell">
                                                    <div class="plus-minus">
                                                        <a href="javascript:void(0)" @click="minusCart(CART)"> <i class="sub" data-feather="minus"></i> </a>
                                                        <input type="number" :value="CART.quantity" min="1" max="10" />
                                                        <a href="javascript:void(0)" @click="plusCart(CART)"> <i class="add" data-feather="plus"></i> </a>
                                                    </div>
                                                    </td>
                                                <td class="total d-none d-xl-table-cell">$ {{CART.total}} </td>
                                                <td>
                                                    <button class="remove-cart" @click="removeCart(CART)" style="color: white;background: #10121b;"><i data-feather="x"></i></button>
                                                </td>
                                            </tr>
                                        </template>

                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-5 col-lg-4">
                            <div class="summery-wrap">
                                <div class="coupon-box">
                                <h5 class="cart-title"><i data-feather="tag"></i> Cupón</h5>
                                <div class="text-wrap">
                                    <!-- <h4><i data-feather="tag"></i> Apply Coupon</h4> -->
                                    <div class="input-flex2" bis_skin_checked="1">
                                        <input
                                            class="form-control3"
                                            type="text"
                                            placeholder="Cupón....."
                                            name="coupon"
                                            v-model="code_cupon"
                                            v-on:keyup.enter="applyCupon"
                                        />
                                        <button @click="applyCupon()" class="btn-green">Aplicar</button>
                                    </div>
                                </div>

                                <span v-if="groups_coupons.length > 0">Cupones Aplicados: </span>
                                <template v-for="(groups_coup, index) in groups_coupons" :key="index" >
                                    <p class="content-color font-md mb-0"> {{ groups_coup }} <a href="javascript:void(0)" @click="removeCoupon(groups_coup)" class="theme-color">X</a></p>
                                </template>

                                </div>
                                <div class="cart-wrap grand-total-wrap">
                                <div>
                                    <div class="order-summery-box">
                                    <h5 class="cart-title">Resumen: </h5>
                                    <ul class="order-summery">
                                        <li>
                                        <span>SubTotal</span>
                                        <span>${{TOTAL_SUM.toFixed(2)}} </span>
                                        </li>

                                        <!-- <li>
                                        <span>Bag savings</span>
                                        <span class="theme-color">-$20.00</span>
                                        </li> -->

                                        <!-- <li>
                                            <span>Coupon Discount</span>
                                            <a href="javascript:void(0)" class="font-danger">Apply Coupon</a>
                                        </li>

                                        <li>
                                        <span>Delivery</span>
                                        <span>$0.00</span>
                                        </li> -->

                                        <li class="pb-0">
                                            <span>Total</span>
                                            <span>$ {{TOTAL_SUM.toFixed(2)}}</span>
                                        </li>
                                    </ul>
                                    <div class="row g-3 mt-2">
                                        <div class="col-6 col-md-12">
                                            
                                        <router-link to="/checkout" class="btn-solid checkout-btn" v-if="Number(TOTAL_SUM) > 0">Pagar <i class="arrow"></i></router-link>
                                        
                                        </div>
                                        <div class="col-6 col-md-12">
                                        <a href="/shop" class="btn-outline w-100 justify-content-center checkout-btn"> Volver a Tienda </a>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Cart Section End -->
        </main>
        <!-- Main End -->
    </div>
</template>
<script>
import store from '../../plugins/store'
import myApi from '../../plugins/axios'
import CartResource from '../../resource/cart'
import { useMeta } from 'vue-meta'

export default {
    name:'CartShop',
    data(){
        return {
            CARTS: [],
            TOTAL_SUM:0,
            code_cupon: null,
            groups_coupons: [],
        }
    },
    methods:{
        minusCart(CART){
            if(CART.quantity == 1){
                this.$notify({
                    title: "CARRITO DE COMPRA",
                    text: `AL PRODUCTO '${CART.title}' NO SE LE PUEDE DISMINUIR MAS!`,
                    type:'error',
                    duration: 2000,
                });
                return;
            }
            let INDEX = this.CARTS.findIndex(product => product.uniqd == CART.uniqd);
            
            if(INDEX != -1){
                // this.CARTS[INDEX].quantity = this.CARTS[INDEX].quantity - 1;
                // this.CARTS[INDEX].total =  this.CARTS[INDEX].subtotal * this.CARTS[INDEX].quantity;
                CartResource.add(this.CARTS,this.CARTS[INDEX],this.emitter,this.$notify,this.$cookies,-1)
            }
            // this.$cookies.set('carts',this.CARTS);
            // this.emitter.emit('cart-shop', this.CARTS);
        },
        plusCart(CART){
            let INDEX = this.CARTS.findIndex(product => product.uniqd == CART.uniqd);
            if(INDEX != -1){
                // this.CARTS[INDEX].quantity = this.CARTS[INDEX].quantity + 1;
                // this.CARTS[INDEX].total =  this.CARTS[INDEX].subtotal * this.CARTS[INDEX].quantity;
                CartResource.add(this.CARTS,this.CARTS[INDEX],this.emitter,this.$notify,this.$cookies,1)
            }
            // this.$cookies.set('carts',this.CARTS);
            // this.emitter.emit('cart-shop', this.CARTS);
        },
        removeCart(CART){
            // let CARTS = this.$cookies.get("carts") ? this.$cookies.get("carts") : [];
            let INDEX = this.CARTS.findIndex(product => product.uniqd == CART.uniqd);

            if(!store.getters.loggedIn){
                if(INDEX != -1){
                    this.CARTS.splice(INDEX,1);
                }
                this.$cookies.set('carts',this.CARTS);
                this.$notify({
                    title: "CARRITO DE COMPRA",
                    text: `EL PRODUCTO '${CART.title}' SE ELIMINO DEL CARRITO CORRECTAMENTE!`,
                    type: 'warn',
                    duration: 2000,
                });
                this.emitter.emit('cart-shop', this.CARTS);
            }else{
                if(INDEX != -1){
                    this.deleteCart(this.CARTS[INDEX],INDEX);
                }
            }
        },
        async deleteCart(data,index){
            try {
                const resp = await myApi.delete(
                    "ecommerce/carts/"+data.cart_id
                );
                console.log(resp);
                if(index != -1){
                    this.CARTS.splice(index,1);
                }
                this.emitter.emit('cart-shop', this.CARTS);
                this.$notify({
                    title: "CARRITO DE COMPRA",
                    text: `TU PRODUCTO '${data.title}' SE ELIMINO DEL CARRITO!`,
                    type: 'warn',
                    duration: 1000,
                });
            } catch (error) {
                console.log(error);
            }
        },
        async applyCupon(){
            let data = {
                code_cupon: this.code_cupon,
                CARTS: this.CARTS
            }
            const resp = await myApi.post('ecommerce/apply_coupon',data);
            if(resp.data.error){
                for (let key in resp.data.error) {
                    this.$notify({
                            title: "VALIDACIÓN",
                            text: resp.data.error[key],
                            duration: 1000,
                            type:'error',
                    });
                }
            }else{
                console.log(resp);
                if(resp.data.message == 403){
                    this.$notify({
                        title: "CUPON",
                        text: resp.data.message_text,
                        type: 'error',
                        duration: 2000,
                    });
                }else{
                    console.log(resp);
                    if(!store.getters.loggedIn){
                        this.$cookies.set('carts',resp.data.CARTS);
                    }
                    this.emitter.emit("cart-shop", resp.data.CARTS);
                    let APPLY_C = resp.data.CARTS.find(item => item.code_cupon == this.code_cupon && !item.code_discount && !item.code_offer)
                    if(APPLY_C){
                        this.groups_coupons.push(this.code_cupon);
                    }
                    this.code_cupon = null;
                    this.$notify({
                        title: "CUPON",
                        text: `EL CUPON SE APLICO CORRECTAMENTE`,
                        duration: 2000,
                    });
                }
            }
        },
        removeCoupon(groups_coup){
            let INDEX = this.groups_coupons.findIndex(item => item == groups_coup);
            if(INDEX != -1){
                this.groups_coupons.splice(INDEX,1);
            }
            CartResource.deleteCoupon(this.CARTS,groups_coup,this.emitter,this.$notify,this.$cookies)
        }
    },
    created(){

    },
    mounted(){
        this.useMetaT = useMeta({
            title: 'Mi Carrito',
            htmlAttrs: { lang: 'en', amp: true },
            meta: [],
        })
        let CARTS = this.$cookies.get("carts") ? this.$cookies.get("carts") : [];
        this.CARTS = CARTS;
        this.TOTAL_SUM = this.CARTS.reduce((sum,cart) => sum+ parseFloat(cart.total),0);
        this.emitter.on('cart-shop',data => {
         this.CARTS = data;
         this.TOTAL_SUM = this.CARTS.reduce((sum,cart) => sum+ parseFloat(cart.total),0);
        const script2 = document.createElement('script');
            script2.src = '../../assets/js/feather/feather.min.js';
            document.body.appendChild(script2);
            script2.onload = () => {
            console.log('archivo.js cargado 2');
            }
       });
       this.emitter.on('groups_coupons',data => {
            console.log(data);
           this.groups_coupons = data;
        });
       this.emitter.emit("cart-list", {});
    }
}
</script>