<template>
    <div>
        <!-- Main Start -->
        <main class="main">
            <!-- Breadcrumb Start -->
            <div class="breadcrumb-wrap">
                <div class="banner">
                    <img class="bg-img bg-top" src="/assets/images/inner-page/banner-p.jpg" alt="banner" />

                    <div class="container-lg">
                        <div class="breadcrumb-box">
                            <div class="heading-box py-3">
                                <h1 class="text-center">{{ makeTitle(blog_slug) }}</h1>
                            </div>
                            <ol class="breadcrumb">
                                <li><a href="/blog">Blog</a></li>
                                <li>
                                    <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                                </li>
                                <template v-if="blog_selected">
                                    <li class="current"><a :href="'/blog/categoria/' + blog_selected.categorie.title">{{
                                        blog_selected.categorie.title }}</a></li>
                                    <li>
                                        <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                                    </li>
                                    <li class="current"><a href="#" onclick="return false;">{{ blog_selected.title }}</a></li>
                                </template>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Breadcrumb End -->

            <!-- Blog Detail Section Start -->
            <section class="blog-padding">
                <div class="container-lg">
                    <div class="row gy-5 g-lg-3 g-xxl-4">

                        <div class="col-lg-9 order-1 order-lg-1 ratio_36">
                            <div class="blog-wrap">
                                <div class="blog-box blog-detail" v-if="blog_selected">
                                    <div class="img-box">
                                        <img class="bg-img" :src="blog_selected.imagen" alt="blog" />
                                    </div>
                                    <div class="content-box">
                                        <span><i data-feather="clock"> </i>{{ blog_selected.created_format }}</span>
                                        <!-- <h5>{{ blog_selected.title }}</h5> -->

                                        <div v-html="blog_selected.description">

                                        </div>

                                        <div class="share-box">
                                            <div class="sender-box">
                                                <router-link v-if="previus_blog" :to="'/blog/' + previus_blog.slug"
                                                    class="btn btn-solid btn-sm replay-btn">ANTERIOR</router-link>
                                                <!-- <div class="avatar-img">
                                                    <img src="/assets/images/logos/logo-w.png" alt="avatar" />
                                                </div> -->
                                                <!-- <div class="sender-details">
                                                    <h5>by John wiki on</h5>
                                                </div> -->
                                            </div>
                                            <div class="action-box">
                                                <!-- <span class="like"> <i class="like-btn" data-feather="thumbs-down"></i>125
                                                </span>
                                                <span class="like"> <i class="like-btn" data-feather="thumbs-up"></i>525
                                                </span> -->
                                                <router-link v-if="next_blog" :to="'/blog/' + next_blog.slug"
                                                    class="btn btn-solid btn-sm replay-btn">SIGUIENTE</router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="py-4">
                                    <span>Compartir: </span>
                                    <!-- <div class="fb-share-button" 
                                    :data-href="url" data-layout="" data-size="">
                                    <a target="_blank" :href="url+'&amp;src=sdkpreparse'" class="fb-xfbml-parse-ignore">Compartir</a>
                                    </div> -->
                                    
                                    <template v-if="blog_selected"> 
                                        <ShareNetwork network="facebook" class="share-network-facebook" :url="url" :title="blog_selected.title"
                                            :description="blog_selected.description_text_plain.substring(0, 60)"
                                            :image="blog_selected.imagen" >
                                            <img src="/assets/icons/svg/social/fb.svg" width="35" alt="Facebook"> Facebook
                                        </ShareNetwork>
                                        <ShareNetwork network="twitter" class="share-network-twitter" :url="url" :title="blog_selected.title"
                                            :description="blog_selected.description_text_plain.substring(0, 60)"
                                            :image="blog_selected.imagen" >
                                            <img src="/assets/svg/twitter.png" width="35" alt="Twitter"> Twitter
                                        </ShareNetwork>
                                    </template>
                                </div>
                            </div>
                            <div class="blog-wrap">
                                <div class="swiper blog-slider">
                                    <div class="swiper-wrapper">
                                        
                                        <template v-for="(blog, index) in BLOG_RELATEDS" :key="index">
                                            <div class="swiper-slide px-2" >
                                                <div class="blog-box blog-grid">
                                                    <router-link :to="'/blog/'+blog.slug" class="img-box">
                                                        <img class="bg-img" :src="blog.imagen" alt="blog" />
                                                    </router-link>
                                                    <div class="content-box">
                                                        <span><i data-feather="clock"> </i>{{ blog.created_format }}</span>
                                                        <router-link :to="'/blog/'+blog.slug"> <h5 class="truncate-2">{{ blog.title }}</h5> 
                                                        </router-link>
    
                                                            <p class="truncate-2">
                                                                {{ blog.description_text_plain }}
                                                            </p>
    
                                                        <div class="share-box">
                                                            <div class="sender-box">
                                                            
                                                            </div>
                                                            <div class="action-box">
                                                                <router-link :to="'/blog/'+blog.slug" class="btn btn-solid btn-sm replay-btn">Ver</router-link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 order-3 order-lg-2 blog-show">
                            <div class="sidebar-inner sticky">
                                <!-- <div class="search-box reverse" style="display: flex;align-items: center;width: 100%;">
                                    <input class="form-control" type="search" v-model="search" @keyup.enter="searchText"
                                        style="width: 100%;" placeholder="Titulo blog..." />
                                    <span class="search" @click="searchText()">
                                        <i data-feather="search"></i>
                                    </span>
                                </div> -->

                                <div class="row g-3 g-lg-4">
                                    <div class="col-sm-6 col-lg-12 order-2 order-lg-1">
                                        <div class="sidebar-box">
                                            <div class="title-box4">
                                                <h4 class="heading">Categories </h4>
                                            </div>
                                            <ul class="catagories-side">
                                                <template v-for="(categorie, index) in categories" :key="index">
                                                    <li>
                                                        <router-link :to="'/blog/categoria/' + categorie.title">
                                                            <span>
                                                                <i data-feather="arrow-right"></i>
                                                                {{ categorie.title }}
                                                            </span>
                                                            <span class="notification">{{ categorie.count_blogs }}</span>
                                                        </router-link>
                                                    </li>
                                                </template>
                                            </ul>
                                        </div>
                                    </div>

                                    <!-- <div class="col-sm-6 col-lg-12 order-3 order-lg-2">
                                        <div class="sidebar-box">
                                            <div class="title-box4">
                                                <h4 class="heading">Ultimas entradas 
                                                </h4>
                                            </div>
                                            <div class="post-wrap">
                                                <template v-for="(blog_destac, index) in blog_destacados" :key="index">
                                                    <router-link :to="'/blog/' + blog_destac.slug" class="post-box">
                                                        <div class="img-box">
                                                            <img :src="blog_destac.blog_imagen" alt="post" />
                                                        </div>
                                                        <div class="content-box">
                                                            <h5>{{ blog_destac.title }}</h5>
                                                            <span>{{ blog_destac.created_format }}</span>
                                                        </div>
                                                    </router-link>
                                                </template>

                                            </div>
                                        </div>
                                    </div> -->

                                    <!-- <div class="d-none d-lg-block col-lg-12 order-3">
                                        <div class="sidebar-box">
                                            <div class="title-box4">
                                                <h4 class="heading">Offers</h4>
                                            </div>

                                            <div class="banner-img">
                                                <img class="bg-img" src="/assets/images/fashion/banner/banner3.jpg"
                                                    alt="banner" />

                                                <div class="sale-card">
                                                    <div class="sale-card-wrap">
                                                        <div class="offer">
                                                            <span> Summer Sale </span>
                                                            <h5>
                                                                70 <span><span class="percent">%</span> <span class="off">
                                                                        OFF</span> </span>
                                                            </h5>
                                                        </div>
                                                        <a href="shop-categories.html" class="shop-btn bg-theme-sun">Shop
                                                            Now</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="col-lg-12 order-1 order-lg-4">
                                        <div class="sidebar-box">
                                            <div class="title-box4">
                                                <h4 class="heading">Etiquetas </h4>
                                            </div>
                                            <ul class="tags">
                                                <li>
                                                    <a href="javascript:void(0)"> T-Shirt </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0)"> Handbags</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0)"> Trends </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0)"> Fashion</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0)"> Designer </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <!-- Blog Detail Section End -->
        </main>
        <!-- Main End -->
    </div>
</template>
<script>
import myApi from '../../../plugins/axios'
import { useMeta } from 'vue-meta'
// import BlogJson from './Blog.json'

export default {
    name: 'BlogShow',
    data() {
        return {
            blog_selected: null,
            blog_destacados: [],
            // PAGINACIÓN
            currentPage: 1,
            per_page: 1,
            total: 0,
            total_pages: 1,
            maxVisibleButtons: 5,
            marcas_selected: [],
            // 
            categories: [],
            blog_slug: '',
            search: '',
            url: "",
            useMetaT: null,
            // 
            previus_blog: null,
            next_blog: null,
            BLOG_RELATEDS: [],
            // 
        }
    },
    components: {
    },
    methods: {
        searchText() {

        },
        actualizarMetaetiquetas(titulo, descripcion, imagen,url) {
            const ogTitle = document.querySelector('meta[property="og:title"]');
            const ogDescription = document.querySelector('meta[property="og:description"]');
            const ogImage = document.querySelector('meta[property="og:image"]');
            const ogUrl = document.querySelector('meta[property="og:url"]');
            
            ogTitle.content = titulo;
            ogDescription.content = descripcion;
            ogImage.content = imagen;
            ogUrl.content = url;
        },
        async showBlog() {
            const resp = await myApi.get(
                "ecommerce/blogs/" + this.blog_slug
            );
            console.log(resp);
            if (resp.data.message == 404) {
                this.blog_selected = null;
            } else {
                this.blog_selected = resp.data.blog;
                this.url = process.env.VUE_APP_FRONTED + 'blog/' + this.blog_selected.slug;
                
                let data = {
                    blog_id : this.blog_selected.id,
                    blog_categorie_id  : this.blog_selected.blog_categorie_id,
                }

                this.showBlogList(data)
                // this.useMetaT.meta.meta = [
                //     {
                //         property: 'og:site_name',
                //         content: 'TIENDA | ZONA DIGITAL',
                //         // vmid: 'og:site_name'
                //     },
                //     {
                //         property: 'fb:app_id',
                //         content: '146177904330672',
                //         // vmid: 'og:title'
                //     },
                //     {
                //         property: 'og:title',
                //         content: this.blog_selected.title + ' | ZONA DIGITAL ',
                //         // vmid: 'og:title'
                //     },
                //     {
                //         property: 'og:description',
                //         content: this.blog_selected.description_text_plain.substring(0, 60),
                //         // vmid: 'og:description'
                //     },
                //     {
                //         property: 'og:image',
                //         content: this.blog_selected.imagen,
                //         // vmid: 'og:image'
                //     },
                //     {
                //         property: 'og:url',
                //         content: this.url,
                //         // vmid: 'og:url'
                //     },
                //     {
                //         property: 'og:type',
                //         content: 'website',
                //         // vmid: 'og:url'
                //     },
                //     // 

                //     {
                //         property: 'twitter:title',
                //         content: (this.blog_selected.title) + ' | ZONA DIGITAL',
                //         // vmid: 'twitter:title'
                //     },
                //     {
                //         property: 'twitter:description',
                //         content: this.blog_selected.description_text_plain.substring(0, 60),
                //         // vmid: 'twitter:description'
                //     },
                //     {
                //         property: 'twitter:image',
                //         content: this.blog_selected.imagen,
                //         // vmid: 'twitter:image'
                //     },
                //     {
                //         property: 'twitter:url',
                //         content: this.url,
                //         // vmid: 'twitter:url'
                //     }
                // ];
                    // Obtén el encabezado del documento
                // const head = document.querySelector('head');

                // // Crea elementos meta para las etiquetas de Open Graph
                // const ogTitle = document.createElement('meta');
                // ogTitle.setAttribute('property', 'og:title');
                // ogTitle.content = this.blog_selected.title;

                // const ogDescription = document.createElement('meta');
                // ogDescription.setAttribute('property', 'og:description');
                // ogDescription.content = this.blog_selected.description_text_plain.substring(0, 60);

                // const ogImage = document.createElement('meta');
                // ogImage.setAttribute('property', 'og:image');
                // ogImage.content = this.blog_selected.imagen;

                this.actualizarMetaetiquetas(this.blog_selected.title, this.blog_selected.description_text_plain.substring(0, 60),
                this.blog_selected.imagen,this.url);

                // // Agrega los elementos meta al encabezado
                // const firstHeadChild = head.firstChild;
                // head.insertBefore(ogImage, firstHeadChild);
                // head.insertBefore(ogDescription, firstHeadChild);
                // head.insertBefore(ogTitle, firstHeadChild);

            }
            // setTimeout(() => {
            //     this.reloadMyJs();
            // }, 50);
        },
        async showBlogList(data) {
            const resp = await myApi.post(
                "ecommerce/show_list/",data
            );
            console.log(resp);
            this.previus_blog = resp.data.previus;
            this.next_blog = resp.data.next;

            this.BLOG_RELATEDS = resp.data.blog_relateds.data;

            setTimeout(() => {
                this.reloadMyJs();
            }, 50);
        },
        async config_all() {
            const resp = await myApi.get(
                "ecommerce/blogs/config_all/"
            );
            console.log(resp);
            this.categories = resp.data.categories;
            this.blog_destacados = resp.data.blog_destacado;
        },
        reloadMyJs() {
            // const detail_product = document.createElement("script");
            // detail_product.src = "../../assets/js/myjs/detailproduct.js";
            // document.body.appendChild(detail_product);
            // detail_product.onload = () => {
            //     console.log("archivo.js cargado detail_product");
            // };
            var script10 = document.createElement('script');
            script10.src = '../../assets/js/script.js';
            document.body.appendChild(script10);
            script10.onload = () => {
                console.log('archivo.js cargado 10');
            }
            const script4 = document.createElement('script');
            script4.src = '../../assets/js/swiper-slider/swiper-custom.min.js';
            document.body.appendChild(script4);
            script4.onload = () => {
                console.log('archivo.js cargado 4');
            }
            const script2 = document.createElement("script");
            script2.src = "../../assets/js/feather/feather.min.js";
            document.body.appendChild(script2);
            script2.onload = () => {
                console.log("archivo.js cargado 2");
            };

            const script_myjs = document.createElement('script');
            script_myjs.src = '../../assets/js/myjs/sliderhome.js';
            document.body.appendChild(script_myjs);
            script_myjs.onload = () => {
                console.log('archivo.js cargado script_myjs');
            }
        },
        makeTitle(slug) {
            var words = slug.split('-');
            for (var i = 0; i < words.length; i++) {
                var word = words[i];
                words[i] = word.charAt(0).toUpperCase() + word.slice(1);
            }
            return words.join(' ');
        }
    },
    created() {
        this.blog_slug = this.$route.params.slug;

        // let BLOG_S = BlogJson.find((item) => item.slug == this.blog_slug);
        // console.log(BLOG_S);
        // if(BLOG_S){
        //     this.actualizarMetaetiquetas(BLOG_S.title,BLOG_S.description,BLOG_S.image,BLOG_S.url);
        // }
        // const head = document.querySelector('head');
        // const title = document.querySelector('title');

        // const ogTitle = document.createElement('meta');
        // ogTitle.setAttribute('property', 'og:title');
        // ogTitle.content = '';
        // const ogUrl = document.createElement('meta');
        // ogUrl.setAttribute('property', 'og:url');
        // ogUrl.content = '';
        // const ogDescription = document.createElement('meta');
        // ogDescription.setAttribute('property', 'og:description');
        // ogDescription.content = '';
        // const ogImage = document.createElement('meta');
        // ogImage.setAttribute('property', 'og:image');
        // ogImage.content = '';
        // const ogType = document.createElement('meta');
        // ogType.setAttribute('property', 'og:type');
        // ogType.content = 'website';

        // head.insertBefore(ogTitle, title.nextSibling);
        // head.insertBefore(ogUrl, title.nextSibling);
        // head.insertBefore(ogType, title.nextSibling);
        // head.insertBefore(ogDescription, title.nextSibling);
        // head.insertBefore(ogImage, title.nextSibling);

        // const firstHeadChild = head.firstChild;
        // head.insertBefore(ogImage, firstHeadChild);
        // head.insertBefore(ogDescription, firstHeadChild);
        // head.insertBefore(ogTitle, firstHeadChild);

    },
    mounted() {
        let TITLE_BLOG = this.makeTitle(this.blog_slug);
        this.useMetaT = useMeta({
            title: TITLE_BLOG.charAt(0).toUpperCase() + TITLE_BLOG.slice(1),
            htmlAttrs: { lang: 'en', amp: true },
            meta: [],
        })

        this.showBlog();
        this.config_all();
        this.$router.afterEach((to, from, next) => {
            this.blog_slug = this.$route.params.slug;
            setTimeout(() => {
                this.showBlog();
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }, 50);
            next()
        })
    }
}
</script>

<style>.blog-show .sidebar-box {
    height: initial !important;
}</style>