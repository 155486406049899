<template>
  <div>
    <!-- Main Start -->
    <main class="main">
      <!-- Breadcrumb Start -->
      <!-- <div class="breadcrumb-wrap">
        <div class="banner">
          <img
            class="bg-img bg-top"
            src="/assets/images/inner-page/banner-p.jpg"
            alt="banner"
          />

          <div class="container-lg">
            <div class="breadcrumb-box">
              <div class="heading-box">
                <h1>User Dashboard</h1>
              </div>
              <ol class="breadcrumb">
                <li><a href="index.html">Home</a></li>

                <li>
                  <a href="javascript:void(0)"
                    ><i data-feather="chevron-right"></i
                  ></a>
                </li>

                <li class="current">
                  <a href="user-dashboard.html">User Dashboard</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div> -->
      <!-- Breadcrumb End -->

      <!-- Dashboard Start -->
      <section class="user-dashboard">
        <div class="container-lg">
          <div class="row g-3 g-xl-4 tab-wrap">
            <div class="col-lg-4 col-xl-3 sticky">
              <button class="setting-menu btn-solid btn-sm d-lg-none">
                Menu <i class="arrow"></i>
              </button>
              <div class="side-bar">
                <span class="back-side d-lg-none">
                  <i data-feather="x"></i
                ></span>
                <div class="profile-box">
                  <div class="img-box">
                    <img
                      class="img-fluid"
                      :src="avatar"
                      alt="user"
                    />
                    <div class="edit-btn">
                      <i data-feather="edit"></i>
                      <input class="updateimg" type="file" name="img" />
                    </div>
                  </div>

                  <div class="user-name">
                    <h5>{{fullname}}</h5>
                    <h6>{{email}}</h6>
                  </div>
                </div>

                <ul class="nav nav-tabs nav-tabs2" id="myTab" role="tablist">
                  <!-- <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="dashboard-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#dashboard"
                      type="button"
                      role="tab"
                      aria-controls="dashboard"
                      aria-selected="true"
                    >
                      Dashboard
                      <span><i data-feather="chevron-right"></i></span>
                    </button>
                  </li> -->
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="orders-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#orders"
                      type="button"
                      role="tab"
                      aria-controls="orders"
                      aria-selected="false"
                    >
                      Ordenes
                      <span><i data-feather="chevron-right"></i></span>
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <router-link to="/lista-deseos" class="nav-link">
                      Lista de deseos
                      <span><i data-feather="chevron-right"></i></span>
                    </router-link>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="savedAddress-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#savedAddress"
                      type="button"
                      role="tab"
                      aria-controls="savedAddress"
                      aria-selected="false"
                    >
                      Direcciones
                      <span><i data-feather="chevron-right"></i></span>
                    </button>
                  </li>
                  <!-- <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="payment-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#payment"
                      type="button"
                      role="tab"
                      aria-controls="payment"
                      aria-selected="false"
                    >
                      Saved Card
                      <span><i data-feather="chevron-right"></i></span>
                    </button>
                  </li> -->
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Perfil
                      <span><i data-feather="chevron-right"></i></span>
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="security-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#security"
                      type="button"
                      role="tab"
                      aria-controls="security"
                      aria-selected="false"
                      @click="logout()"
                    >
                      Salir
                      <!-- <span><i data-feather="chevron-right"></i></span> -->
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-8 col-xl-9">
              <div class="right-content tab-content d-block" id="myTabContent">
                <!-- User Dashboard Start -->
                <div
                  class="tab-pane"
                  id="dashboard"
                  role="tabpanel"
                  aria-labelledby="dashboard-tab"
                >
                  <div class="dashboard-tab">
                    <div class="title-box3">
                      <h3>Bienvenido</h3>
              
                    </div>

                    <div class="row g-0 option-wrap">
                      <div class="col-sm-6 col-xl-4">
                        <a href="javascript:void(0)" data-class="orders" class="tab-box">
                          <img src="/assets/icons/svg/1.svg" alt="shopping bag" />
                          <h5>Ordenes</h5>
                         
                        </a>
                      </div>
                      <div class="col-sm-6 col-xl-4">
                        <a href="wishlist.html" data-class="wishlist" class="tab-box">
                          <img src="/assets/icons/svg/2.svg" alt="wishlist" />
                          <h5>Lista de deseos</h5>
                        </a>
                      </div>
                      <div class="col-sm-6 col-xl-4">
                        <a href="javascript:void(0)" data-class="savedAddress" class="tab-box">
                          <img src="/assets/icons/svg/3.svg" alt="address" />
                          <h5>Direcciones</h5>
                        </a>
                      </div>
                      <!-- <div class="col-sm-6 col-xl-4">
                        <a href="javascript:void(0)" data-class="payment" class="tab-box">
                          <img src="/assets/icons/svg/4.svg" alt="payment" />
                          <h5>Metodo de Pago</h5>
                        </a>
                      </div> -->
                      <div class="col-sm-6 col-xl-4">
                        <a href="javascript:void(0)" data-class="profile" class="tab-box">
                          <img src="/assets/icons/svg/5.svg" alt="profile" />
                          <h5>Perfil</h5>
   
                        </a>
                      </div>
            
                    </div>
                  </div>
                </div>
                <!-- User Dashboard End -->

                <!-- Order Tabs Start -->
                <div
                  class="tab-pane  show active"
                  id="orders"
                  role="tabpanel"
                  aria-labelledby="orders-tab"
                >
                  <div class="cart-wrap order-content">
                    <div class="title-box3">
                      <h3>Mis Ordenes</h3>
                      <!-- <p>
                        H thanks for placing a delivery order with Oslo! Your
                        order should be home with you in soon
                      </p> -->
                    </div>
  
                    <div class="order-wraper" v-if="false">
                      <button class="btn-outline btn-sm my-2" @click="backList()">ATRAS</button>
                      <template 
                              v-for="(ITEM, index) in order_selected.items"
                              :key="index"
                            >
                        <div class="order-box">
                          <!-- <div class="order-header">
                            <span><i data-feather="box"></i></span>
                            <div class="order-content">
                              <h5 class="order-status success">Delivered</h5>
                              <p>
                                Place July 15 2022 and Delivered on July 18 2022
                              </p>
                            </div>
                          </div> -->

                          <div class="order-info">
                            <div
                              class="product-details"
                              data-productDetail="product-detail"
                            >
                              <div class="img-box">
                                <img
                                  :src="ITEM.imagen"
                                  alt="product"
                                />
                              </div>
                              <div class="product-content">
                                <h5>{{ITEM.title}}</h5>
                                <p class="truncate-3">
                                  {{ITEM.summary}}
                                </p>
                                <span>Total : <span>$ {{ITEM.total}} </span></span>
                                <span>Cantidad : <span>{{ITEM.quantity}}</span></span>
                                <!-- <span>Estado:<span>{{ITEM.status}}</span></span> -->

                                <span>Orden Id : <span>{{order_selected.uniqd}}</span></span>
                              </div>
                            </div>

                            <div class="rating-box">
                              <span>
                                <!-- <template v-if="!ITEM.review">
                                  Aun no seleccionado 
                                </template>  -->
                                  <a href="javascript:void(0)" class="btn-review" v-if="!ITEM.review" @click="openReviewModel(ITEM)" data-bs-toggle="modal" data-bs-target="#viewReview">
                                      <!-- <i data-feather="star" ></i>

                                      <i data-feather="edit" v-if="ITEM.review"></i> -->
                                      Calificar producto
                                  </a>
                              </span>
                              <ul class="rating p-0 mb" v-if="ITEM.review">
                                <li>
                                  <i :class="{'fill': ITEM.review.rating >= 1}" data-feather="star"></i>
                                </li>
                                <li>
                                  <i :class="{'fill': ITEM.review.rating >= 2}" data-feather="star"></i>
                                </li>
                                <li>
                                  <i :class="{'fill': ITEM.review.rating >= 3}" data-feather="star"></i>
                                </li>
                                <li>
                                  <i :class="{'fill': ITEM.review.rating >= 4}" data-feather="star"></i>
                                </li>
                                <li>
                                  <i :class="{'fill': ITEM.review.rating >= 5}" data-feather="star"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </template>

                    </div>
                    
                    <div v-if="order_selected" class="d-flex align-items-start align-items-sm-center justify-content-between title-box3">
                        <div>
                            <h3>Detalles de la orden</h3>
                        </div>
                        <button class="btn btn-outline btn-sm white-space-no" @click="backList()">Volver</button>
                    </div>

                    <div v-if="order_selected" class="order-detail-wrap order-content">
                      <div class="col-12">
                          <div class="order-summery-wrap mt-0 order-data">
                              <div class="banner-box">
                                  <div class="media">
                                      <div class="img">
                                          <i data-feather="package"></i>
                                      </div>
                                      <div class="media-body">
                                          <h2>ORDEN #{{ order_selected.order_id_external ? order_selected.order_id_external : order_selected.id }}</h2>
                                          <span class="">Estado: <span>{{ order_selected.status }}</span> | {{ order_selected.created_at }}</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div class="row g-0 option-wrap">
                          <div class="col-sm-6 col-xl-4 detail-box-user">
                              <div class="order-box">
                                  <span class="pb-2"><span>Envío</span></span>
                                  <template v-if="order_selected.type_entrega == 1">
                                    <span>Sucursal</span>
                                    <span>{{ order_selected.sucursal }} </span>
                                  </template>
                                  <template v-if="order_selected.type_entrega == 2">
                                    <span>A domicilio </span>
                                    <span>{{ order_selected.client_general ? order_selected.client_general.departament  : (order_selected.client_info.departament) }}</span>
                                    <span>{{ order_selected.client_general ? order_selected.client_general.municipality  : (order_selected.client_info.municipality) }}</span>
                                    <span>{{ order_selected.client_general ? order_selected.client_general.address  : (order_selected.client_info.address) }}</span>
                                    <span>{{ order_selected.client_general ? order_selected.client_general.point_reference  : (order_selected.client_info.point_reference) }}</span>
                                  </template>
                              </div>
                          </div>
                          <div class="col-sm-6 col-xl-4 detail-box-user">
                              <div class="order-box">
                                  <span class="pb-2"><span>Método de pago</span></span>
                                  <template v-if="order_selected.type_payment == 1">
                                    <span>Transferencia</span>
                                  </template>
                                  <template v-if="order_selected.type_payment == 2">
                                    <span>Tarjeta</span>
                                  </template>
                                  <template v-if="order_selected.type_payment == 3">
                                    <span>Paypal</span>
                                  </template>

                                  <span class="pb-2 pt-2" v-if="order_selected.is_credito_fiscal"><span>CCF</span></span>

                                  <span v-if="order_selected.client_general.nit">NIT:<span>{{ order_selected.client_general.nit }}</span></span>
                                  <span v-if="order_selected.client_general.nrc">NRC:<span>{{ order_selected.client_general.nrc }}</span></span>
                                  <span v-if="order_selected.client_general.giro">Giro:<span>{{ order_selected.client_general.giro }}</span></span>
                              </div>
                          </div>
                          <div class="col-sm-6 col-xl-4 detail-box-user">
                              <div class="order-box">
                                  <span class="pb-2"><span>Resumen</span></span>
                                  <!-- <span>Subtotal:<span>$ 2124.00</span></span> -->
                                  <span>Envio:<span>$ {{ order_selected.tax }}</span></span>
                                  <span v-if="order_selected.type_payment == 3">Comisión Paypal:<span>$ {{ order_selected.comision_paypal }}</span></span>
                                  <span>Total:<span>$ {{ order_selected.total }}</span></span>
                              </div>
                          </div>
                      </div>
                  

                      <template v-for="sale_detail in order_selected.items" :key="sale_detail.id">
                        <div class="order-info">
                            <div class="product-details" data-productdetail="product-detail">
                                <div class="img-box">
                                    <div class="product-details" data-productdetail="product-detail">
                                        <div class="img-box"><img :src="sale_detail.imagen" alt="product"></div>
                                        <div class="product-content">
                                        <h5>{{ sale_detail.title }}</h5>
                                        <p class="truncate-2">
                                          {{ sale_detail.summary }}
                                        </p>
                                        <span>Precio: <span>${{ sale_detail.subtotal }}</span></span>
                                        <span>Cantidad: <span>{{ sale_detail.quantity }}</span></span>
                                        </div>
                                    </div>
                                    <div class="rating-box">
                                        <span>Opciones :</span>
                                        <ul class="rating p-0 mb" v-if="sale_detail.review">
                                          <li>
                                            <i :class="{'fill': sale_detail.review.rating >= 1}" data-feather="star"></i>
                                          </li>
                                          <li>
                                            <i :class="{'fill': sale_detail.review.rating >= 2}" data-feather="star"></i>
                                          </li>
                                          <li>
                                            <i :class="{'fill': sale_detail.review.rating >= 3}" data-feather="star"></i>
                                          </li>
                                          <li>
                                            <i :class="{'fill': sale_detail.review.rating >= 4}" data-feather="star"></i>
                                          </li>
                                          <li>
                                            <i :class="{'fill': sale_detail.review.rating >= 5}" data-feather="star"></i>
                                          </li>
                                        </ul>
                                        <a class="product-feedback" v-if="!sale_detail.review" @click="openReviewModel(sale_detail)" data-bs-toggle="modal" data-bs-target="#viewReview" >
                                            Escribir recomendación<img class="feedback-icon" src="/assets/images/Nuevos/review.svg" alt="Escribir recomendación"> </a>
                                        <a class="product-buyagain" href="javascript:void(0)" >
                                        Ordenar de nuevo<img class="feedback-icon" src="/assets/images/Nuevos/repeat.svg" alt="Ordenar de nuevo"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </template>

                    </div>

                    <!-- <div class="order-wraper" v-if="!order_selected">
                      <div class="items-list">
                        <table class="table cart-table m-md-0">
                          <thead>
                            <tr>
                              <th class="">ID</th>
                              <th class="">CFF</th>
                              <th class="">Tipo de Entrega</th>
                              <th class="">Tipo de Pago</th>
                              <th class="">Estado</th>
                              <th class="">Fecha registro</th>
                              <th class="">TOTAL</th>
                              <th class="">VER</th>
                            </tr>
                          </thead>
                          <tbody class="t-body-orders">
                            <template 
                              v-for="(ORDER, index) in ORDERS"
                              :key="index"
                            >
                              <tr style="cursor:pointer;" >
                                <td>
                                  <h6 class="title-color font-default2"><small>{{ORDER.uniqd}}</small></h6>
                                </td>

                                <td class="price"><small>{{ !ORDER.is_credito_fiscal ? 'NO' : 'SI' }}</small></td>

                                <td class="price"><small>{{ ORDER.type_entrega == 1 ? 'SUCURSAL' : 'DOMICILIO' }}</small></td>

                                <td class="price"><small>{{ ORDER.type_payment == 1 ? 'DEPOSITO' : '' }} {{ ORDER.type_payment == 2 ? 'TARJETA' : '' }} {{ ORDER.type_payment == 3 ? 'PAYPAL' : '' }}</small></td>

                                <td class="price"><small>{{ ORDER.status }}</small></td>

                                <td class="price text-nowrap"> <small>{{ ORDER.created_at }}</small> </td>

                                <td class="total text-nowrap">$ {{ORDER.total}} </td>

                                <td>
                                  <button class="btn btn-primary btn-sm" @click="trackingInfo(ORDER)" 
                                    v-if="ORDER.type_entrega == 2">

                                    <img src="/assets/images/Nuevos/track.svg">

                                  </button>
                                  <button
                                    class="btn btn-success btn-sm" @click="viewDetailSale(ORDER)">
                                    <img src="/assets/images/Nuevos/order.svg">
                                  </button>
                                  
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div> -->

                    <div class="order-wraper" v-if="!order_selected">
                        <div class="items-list" >
                            <table class="table cart-table m-md-0">
                                <thead>
                                    <tr>
                                        <th class="">Orden</th>
                                        <th class="">Fecha</th>
                                        <th class="">Tipo de entrega</th>
                                        <th class="">Método de pago</th>
                                        <th class="">Estado</th>
                                        <th class="">Total</th>
                                        <th class="">Opciones</th>
                                    </tr>
                                </thead>
                                <tbody class="t-body-orders">
                                  <template 
                                    v-for="(ORDER, index) in ORDERS"
                                    :key="index"
                                  >
                                    <tr style="cursor: pointer;">
                                        <td>
                                            <h6 class="title-color font-default2 text-nowrap">
                                                <small># {{ ORDER.order_id_external ? ORDER.order_id_external : ORDER.id }} </small>
                                            </h6>
                                        </td>
                                        <td class="detail text-nowrap"><small>{{ ORDER.created_at }}</small>
                                        </td>
                                        <td class="detail"><small>{{ ORDER.type_entrega == 1 ? 'Sucursal' : 'Domicilio' }}</small></td>

                                        <td class="detail"><small>{{ ORDER.type_payment == 1 ? 'Transferencia' : '' }} {{ ORDER.type_payment == 2 ? 'Tarjeta' : '' }} {{ ORDER.type_payment == 3 ? 'Paypal' : '' }}</small></td>

                                        <td class="detail">
                                        <small> <span class="badges badges-pill badges-theme">{{ ORDER.status }}</span>
                                        </small></td>
                                        
                                        
                                        <td class="total text-nowrap">$ {{ORDER.total}}</td>
                                        <td class="btn-color text-nowrap">
                                            <button class="btn btn-secondary btn-sm"  @click="trackingInfo(ORDER)" v-if="ORDER.type_entrega == 2"><img class="icon-perfil" width="64" height="64" src="/assets/images/Nuevos/track.svg" alt="track-order" /></button>
                                            <button class="btn btn-primary btn-sm" @click="viewDetailSale(ORDER)" ><img class="icon-perfil"  width="64" height="64" src="/assets/images/Nuevos/order.svg" alt="todo-list--v1" /></button> 
                                        </td>
                                    </tr>
                                  </template>
                                </tbody>
                            </table>
                        </div>
                    </div>

                  </div>
                </div>
                <!-- Order Tabs End -->

                <!-- Order Detail Tab Start -->
                <div
                  class="tab-pane"
                  id="orders-details"
                  role="tabpanel"
                  aria-labelledby="orders-details"
                >
                  <div class="order-detail-wrap order-content">
                    <div class="row g-3 g-md-4">
                      <div class="col-12">
                        <div class="order-summery-wrap mt-0 order-data">
                          <div class="banner-box">
                            <div class="media">
                              <div class="img">
                                <i data-feather="package"></i>
                              </div>
                              <div class="media-body">
                                <h2>Order Delivered</h2>
                                <span class="font-sm"
                                  >Delivered On July 15 2022</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="product-details">
                          <div class="img-box">
                            <img
                              src="/assets/images/fashion/product/front/4.jpg"
                              alt="product"
                            />
                          </div>
                          <div class="product-content">
                            <h5>Women’s long sleeve Jacket</h5>
                            <p class="truncate-3">
                              Versatile sporty slogans short sleeve quirky laid
                              back orange lux hoodies vests pins badges.
                              Versatile sporty slogans short sleeve quirky laid
                              back orange lux hoodies vests pins badges. Cutting
                              edge crops stone transparent.
                            </p>
                            <span>Prize : <span>$120.00</span></span>
                            <span>Size : <span>M</span></span>
                            <span>Order Id : <span>edf125qa1d35</span></span>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="order-data summery-wrap">
                          <div class="order-summery-box">
                            <h5 class="cart-title">
                              Price Details (1 Quantity)
                            </h5>
                            <ul class="order-summery">
                              <li>
                                <span>Bag total</span>
                                <span>$220.00</span>
                              </li>

                              <li>
                                <span>Bag savings</span>
                                <span class="theme-color">-$20.00</span>
                              </li>

                              <li>
                                <span>Coupon Discount</span>
                                <a href="offer.html" class="font-danger"
                                  >$120.00</a
                                >
                              </li>

                              <li>
                                <span>Delivery</span>
                                <span>$50.00</span>
                              </li>

                              <li class="pb-0">
                                <span>Total Amount</span>
                                <span>$270.00</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="row gy-3 gy-sm-0 g-3 g-md-4">
                          <div class="col-sm-6">
                            <div class="order-data general-details">
                              <!-- Payment Method Start -->
                              <div class="payment-method mt-0">
                                <h5 class="cart-title">Payment Method</h5>
                                <div class="payment-box">
                                  <img
                                    src="/assets/icons/png/1.png"
                                    alt="card"
                                  />
                                  <span class="font-sm title-color">
                                    **** **** **** 6502</span
                                  >
                                </div>
                              </div>
                              <!-- Payment Method End -->

                              <button class="btn-solid mb-line btn-sm mt-4">
                                Get Invoice <i class="arrow"></i>
                              </button>
                            </div>
                          </div>

                          <div class="col-sm-6">
                            <div class="order-data general-details">
                              <!-- Contact Start -->
                              <div class="payment-method mt-0">
                                <h5 class="cart-title">Contact Us</h5>

                                <div class="payment-box">
                                  <i data-feather="phone"></i>
                                  <span class="font-sm title-color">
                                    <a
                                      class="content-color fw-500"
                                      href="tel:2554-4454-5646"
                                      >2554-4454-5646</a
                                    >
                                  </span>
                                </div>

                                <div class="payment-box mt-3">
                                  <i data-feather="phone"></i>
                                  <span class="font-sm title-color">
                                    <a
                                      class="content-color fw-500"
                                      href="tel:5452-2545-2154"
                                      >5452-2545-2154</a
                                    >
                                  </span>
                                </div>

                                <div class="payment-box mt-3">
                                  <i data-feather="mail"></i>
                                  <span class="font-sm title-color">
                                    <a
                                      class="content-color fw-500"
                                      href="mailto:someone@example.com"
                                      >someone@example.com</a
                                    >
                                  </span>
                                </div>
                              </div>
                              <!-- Contact End -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="order-data general-details">
                          <!-- Address Section Start -->
                          <div class="address-ordered p-0">
                            <h5 class="cart-title">Order Address</h5>
                            <div class="address">
                              <h5 class="font-default title-color">
                                Nadine Vogt
                                <span class="badges badges-pill badges-theme"
                                  >Home</span
                                >
                              </h5>
                              <p class="font-default content-color">
                                <i data-feather="map-pin"></i> 1418 Riverwood
                                Drive, Suite 3245 Cottonwood, CA 96052, United
                                States
                              </p>
                            </div>
                          </div>
                          <!-- Address Section End -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Order Detail Tab End -->

                <!-- Saved Address Tabs Start -->
                <div
                  class="tab-pane"
                  id="savedAddress"
                  role="tabpanel"
                  aria-labelledby="savedAddress-tab"
                >
                  <div class="address-tab">
                    <div class="title-box3">
                      <h3 v-if="new_address == 1">Todas las direcciones</h3>
                      <h3 v-if="new_address == 2">Regitra una nueva dirección</h3>
                      <h3 v-if="new_address == 3">Actualizar dirección</h3>
                      <p>
                        Gestiona tus direcciónes de envio
                      </p>
                    </div>

                    <div class="row g-3 g-md-4 custom-form form-pill">
                      <template v-if="new_address == 2 || new_address == 3">
                        <!-- <div class="input-flex2">
                                <input type="text" name="coupon" class="form-control3" v-model="title" placeholder="Nombre de la ubicación">
                                <button type="button" @click="SaveAddress()" class="btn-green">GUARDAR</button>
                            </div> -->
                        <div class="col-6">
                          <div class="input-box1">
                            <label for="address">Nombre de la ubicación: (*)</label>
                            <input type="text" name="name_address" class="form-control" v-model="title" placeholder="Nombre de la ubicación">
                          </div>
                        </div>
                        <div class="col-6">
                          
                        </div>
                        <div class="col-6">
                            <div class="input-box1">
                                <label for="departamento">Departamento: (*)</label>
                                <select class="form-select form-control text-white" id="departamento" @change="changeLocation" v-model="departament">
                                    <option selected disabled value="">Selecciona...</option>
                                    <template
                                        v-for="(DEPARTAMENTO, index) in S_DEPARTAMENTOS"
                                        :key="index"
                                    >
                                        <option :value="DEPARTAMENTO.name">{{DEPARTAMENTO.name}}</option>
                                    </template>
                                </select>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="input-box1">
                                <label for="municipio">Municipio: (*)</label>
                                <select class="form-select form-control text-white" id="municipio" v-model="municipality">
                                    <option selected disabled value="">Choose...</option>
                                    <template
                                        v-for="(MUNICIPIO, index) in S_MUNICIPIOS"
                                        :key="index"
                                    >
                                        <option :value="MUNICIPIO.city">{{MUNICIPIO.city}}</option>
                                    </template>
                                </select>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="input-box1">
                                <label for="comentarios">Dirección: (*)</label>
                                <textarea
                                    class="form-control text-white"
                                    placeholder=""
                                    style="height: 100px" v-model="address"
                                ></textarea>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="input-box1">
                                <label for="comentarios">Punto de Referencia: (*)</label>
                                <textarea
                                    class="form-control text-white"
                                    placeholder=""
                                    style="height: 100px" v-model="point_reference"
                                ></textarea>
                            </div>
                        </div>

                        <div class="col-12 mb-30">
                            <div v-show="direccion_incorrecta">
                                <!--Alerta fuera de cobertura -->
                                <div class="alert-gps" >
                                    <img class="icon-alert-gps" src="/frontend/assets/images/cobertura-no-valida.png" alt="">
                                    <p class="alert-novalido">Ubicación fuera de cobertura</p>
                                </div>
                                <p class="alert-mensaje">Si consideras que tu ubicación o zona no tiene cobertura por algún error, puedes contáctarnos por medio de whatsapp <a class="alert-link" href="tel:79886164">7988-6164</a>. Seguiremos agregando nuevas zonas de cobertura, manténte pendiente. </p>
                                <!-- End -->
                            </div>
                            <!--Alerta con cobertura -->
                            <div class="alert-gps" v-show="direccion_correcta">
                                <img class="icon-alert-gps" src="/frontend/assets/images/cobertura-valida.png" alt="">
                                <p class="alert-valido">Ubicación con cobertura</p>
                            </div>
                            <!-- End -->

                            <!--Alerta GPS apagado -->
                            <div v-show="mapa_error">
                                <div class="alert-gps" >
                                    <img class="icon-alert-gps" src="/frontend/assets/images/gps-off.png" alt="">
                                    <p class="alert-apagado">Enciente tu GPS</p>
                                </div>
                                <p class="alert-mensaje">Enciende el GPS y actualiza la página para verificar si tenemos cobertura en tu ubicación.</p>
                            </div>
                            <!-- End -->
                            <div class="google-map" ref="googleMap" style="height: 300px"></div>
                        </div>

                        <div class="col-6">
                          <div class="btn-box">
                            <button class="btn-outline btn-sm" @click="createAddress(1)">Regresar</button>
                            <button class="btn-solid btn-sm mx-3" type="button" @click="saveAddress()" v-if="new_address == 2">
                              Guardar <i class="arrow"></i>
                            </button>
                            <button class="btn-solid btn-sm mx-3" type="button" @click="updateAddress()" v-if="new_address == 3">
                              Guardar Cambios <i class="arrow"></i>
                            </button>
                          </div>
                        </div>
                      </template>
                      <template v-if="new_address == 1">
                        <template v-for="(ADDRE,index) in ADDRESS" :key="index">
                          <div class="col-md-6 col-lg-12 col-xl-6">
                            <!-- checked -->
                            <div class="address-box">
                              <div class="radio-box">
                                <div>
                                  <input
                                    class="radio-input"
                                    type="radio"
                                    :id="'radio'+index"
                                    name="radio1"
                                  />
                                  <label class="radio-label" :for="'radio'+index"
                                    >{{ ADDRE.title }}</label
                                  >
                                </div>
                                <span class="badges badges-pill badges-theme"
                                  >{{ index+1 }}</span
                                >
                                <div class="option-wrap">
                                  <!-- data-bs-toggle="modal"
                                  data-bs-target="#edditAddress"
                                  data-bs-toggle="modal"
                                  data-bs-target="#conformation" -->
                                  <span
                                    class="edit"
                                    @click="editAddress(ADDRE)"
                                    ><img src="/assets/images/Nuevos/edit.svg"></span>
                                  <span
                                    class="delet ms-0"
                                    ><img src="/assets/images/Nuevos/delete.svg"></span>
                                </div>
                              </div>
                              <div class="address-detail">
                                <p class="content-color font-default">
                                  Dirección: {{ ADDRE.address }}
                                </p>
                                <p class="content-color font-default">
                                  Punto de referencia: {{ ADDRE.point_reference }}
                                </p>
                                
                                <span class="content-color font-default"
                                  >Departamento:
                                  <span class="title-color font-default fw-500">
                                    {{ ADDRE.departament }}</span>
                                </span>

                                <span class="content-color font-default"
                                  >Municipio:
                                  <span class="title-color font-default fw-500">
                                    {{ ADDRE.municipality }}</span>
                                </span>

                                <!-- <span class="content-color font-default mt-1"
                                  >Delivery:
                                  <span class="title-color font-default fw-500">
                                    2 March</span
                                  >
                                </span>
                                <span class="content-color font-default mt-1"
                                  >Cash on Delivery:
                                  <span class="title-color font-default fw-500">
                                    Available</span
                                  >
                                </span> -->
                              </div>
                            </div>
                          </div>
                        </template>

                        <!-- <div class="col-md-6 col-lg-12 col-xl-6">
                          <div class="address-box">
                            <div class="radio-box">
                              <div>
                                <input
                                  class="radio-input"
                                  type="radio"
                                  id="radio3"
                                  name="radio1"
                                />
                                <label class="radio-label" for="radio3"
                                  >Freddy J. Burns</label
                                >
                              </div>
                              <span class="badges badges-pill badges-theme"
                                >Home</span
                              >
                              <div class="option-wrap">
                                <span
                                  class="edit"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edditAddress"
                                  ><i data-feather="edit"></i
                                ></span>
                                <span
                                  class="delet ms-0"
                                  data-bs-toggle="modal"
                                  data-bs-target="#conformation"
                                  ><i data-feather="trash"></i
                                ></span>
                              </div>
                            </div>
                            <div class="address-detail">
                              <p class="content-color font-default">
                                198 Terry Lane Orlando, FL 32809
                              </p>
                              <p class="content-color font-default">
                                Germany,254685
                              </p>
                              <span class="content-color font-default"
                                >Mobile:
                                <span class="title-color font-default fw-500">
                                  353-582-5870</span
                                ></span
                              >

                              <span class="content-color font-default mt-1"
                                >Delivery:
                                <span class="title-color font-default fw-500">
                                  4 March</span
                                ></span
                              >
                              <span class="content-color font-default mt-1"
                                >Cash on Delivery:
                                <span class="title-color font-default fw-500">
                                  Available</span
                                ></span
                              >
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 col-lg-12 col-xl-6">
                          <div class="address-box">
                            <div class="radio-box">
                              <div>
                                <input
                                  class="radio-input"
                                  type="radio"
                                  id="radio2"
                                  name="radio1"
                                />
                                <label class="radio-label" for="radio2"
                                  >Nadine Vogt</label
                                >
                              </div>
                              <span class="badges badges-pill badges-theme"
                                >Office</span
                              >
                              <div class="option-wrap">
                                <span
                                  class="edit"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edditAddress"
                                  ><i data-feather="edit"></i
                                ></span>
                                <span
                                  class="delet ms-0"
                                  data-bs-toggle="modal"
                                  data-bs-target="#conformation"
                                  ><i data-feather="trash"></i
                                ></span>
                              </div>
                            </div>
                            <div class="address-detail">
                              <p class="content-color font-default">
                                Wachaustrasse 22 8045 WEINITZEN
                              </p>
                              <p class="content-color font-default">
                                Austria,35546
                              </p>
                              <span class="content-color font-default"
                                >Mobile:
                                <span class="title-color font-default fw-500">
                                  454-254-3654</span
                                ></span
                              >
                              <span class="content-color font-default mt-1"
                                >Delivery:
                                <span class="title-color font-default fw-500">
                                  5 March</span
                                ></span
                              >
                              <span class="content-color font-default mt-1"
                                >Cash on Delivery:
                                <span class="title-color font-default fw-500"
                                  >Not Available</span
                                ></span
                              >
                            </div>
                          </div>
                        </div> -->

                        <div class="col-md-6 col-lg-12 col-xl-6">
                          <div
                            class="address-box add-new d-flex flex-column gap-2 align-items-center justify-content-center"
                            @click="createAddress(2)"
                          >
                            <span class="plus-icon"
                              ><i data-feather="plus"></i
                            ></span>
                            <h4 class="theme-color font-xl fw-500">
                              Agregar una nueva dirección
                            </h4>
                          </div>
                        </div>
                      </template>

                    </div>
                  </div>
                </div>
                <!-- Saved Address Tabs End -->

                <!-- Payment Tabs Start -->
                <div
                  class="tab-pane"
                  id="payment"
                  role="tabpanel"
                  aria-labelledby="payment-tab"
                >
                  <div class="payment-tab">
                    <div
                      class="d-flex align-items-start align-items-sm-center justify-content-between title-box3"
                    >
                      <div>
                        <h3>Your Saved Card</h3>
                        <p>
                          here is your saved card, from here you can easily add
                          or modify your card
                        </p>
                      </div>
                      <button
                        class="btn btn-outline btn-sm white-space-no"
                        data-bs-toggle="modal"
                        data-bs-target="#addNewcard"
                      >
                        Add Card
                      </button>
                    </div>

                    <div class="payment-section">
                      <div class="row g-3 g-xl-4">
                        <div class="col-sm-6 col-xl-4">
                          <div
                            class="payment-card bg-theme-blue border-color-blue"
                          >
                            <div class="bank-info">
                              <img
                                class="bank"
                                src="/assets/icons/png/bank1.png"
                                alt="bank1"
                              />
                              <div class="card-type">
                                <img
                                  class="bank-card"
                                  src="/assets/icons/png/1.png"
                                  alt="card"
                                />
                              </div>
                            </div>

                            <div class="card-details">
                              <span>Card Number</span>
                              <h5>6458 50XX XXXX 0851</h5>
                            </div>

                            <div class="card-details-wrap">
                              <div class="card-details">
                                <span>Name On Card</span>
                                <h5>Josephin water</h5>
                              </div>

                              <div class="text-center card-details">
                                <span>Validity</span>
                                <h5>XX/XX</h5>
                              </div>
                            </div>

                            <div class="btn-box">
                              <span
                                data-bs-toggle="modal"
                                data-bs-target="#editCard"
                              >
                                <i data-feather="edit"></i
                              ></span>
                              <span
                                data-bs-toggle="modal"
                                data-bs-target="#conformation"
                                ><i data-feather="trash"></i
                              ></span>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-6 col-xl-4">
                          <div
                            class="payment-card bg-theme-orange border-color-orange"
                          >
                            <div class="bank-info">
                              <img
                                class="bank"
                                src="/assets/icons/png/bank2.png"
                                alt="bank1"
                              />
                              <div class="card-type">
                                <img
                                  class="bank-card"
                                  src="/assets/icons/png/2.png"
                                  alt="card"
                                />
                              </div>
                            </div>

                            <div class="card-details">
                              <span>Card Number</span>
                              <h5>2564 75XX XXXX 3545</h5>
                            </div>

                            <div class="card-details-wrap">
                              <div class="card-details">
                                <span>Name On Card</span>
                                <h5>Josephin water</h5>
                              </div>
                              <div class="text-center card-details">
                                <span>Validity</span>
                                <h5>XX/XX</h5>
                              </div>
                            </div>

                            <div class="btn-box">
                              <span
                                data-bs-toggle="modal"
                                data-bs-target="#editCard"
                                ><i data-feather="edit"></i
                              ></span>
                              <span
                                data-bs-toggle="modal"
                                data-bs-target="#conformation"
                                ><i data-feather="trash"></i
                              ></span>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-6 col-xl-4">
                          <div
                            class="payment-card bg-theme-yellow border-color-yellow"
                          >
                            <div class="bank-info">
                              <img
                                class="bank"
                                src="/assets/icons/png/bank3.png"
                                alt="bank1"
                              />
                              <div class="card-type">
                                <img
                                  class="bank-card"
                                  src="/assets/icons/png/5.png"
                                  alt="card"
                                />
                              </div>
                            </div>

                            <div class="card-details">
                              <span>Card Number</span>
                              <h5>8564 34XX XXXX 9564</h5>
                            </div>

                            <div class="card-details-wrap">
                              <div class="card-details">
                                <span>Name On Card</span>
                                <h5>Josephin water</h5>
                              </div>
                              <div class="text-center card-details">
                                <span>Validity</span>
                                <h5>XX/XX</h5>
                              </div>
                            </div>

                            <div class="btn-box">
                              <span
                                data-bs-toggle="modal"
                                data-bs-target="#editCard"
                                ><i data-feather="edit"> </i
                              ></span>
                              <span
                                data-bs-toggle="modal"
                                data-bs-target="#conformation"
                                ><i data-feather="trash"></i
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Payment Tabs End -->

                <!-- Profile Tabs Start  show active-->
                <div
                  class="tab-pane "
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div class="profile">
                    <div class="title-box3">
                      <h3>Información Básica</h3>
                    </div>

                    <form
                      action="javascript:void(0)"
                      class="custom-form form-pill"
                    >
                      <div class="row g-3 g-xl-4">
                        
                        <div class="col-6">
                          <div class="input-box1">
                            <label for="name">Nombres Completo: </label>
                            <input
                                class="form-control"
                                type="text"
                                v-model="name"
                                name="name"
                                id="name"
                            />
                          </div>
                        </div>

                        <div class="col-6">
                          <div class="input-box1">
                            <label for="name">Apellidos Completos: </label>
                            <input
                                class="form-control"
                                type="text"
                                v-model="surname"
                                name="surname"
                                id="surname"
                            />
                          </div>
                        </div>

                        <div class="col-sm-6">
                          <div class="input-box1">
                            <label for="email">Email</label>
                            <input
                              class="form-control"
                              id="email"
                              name="email"
                              v-model="email"
                              type="email"
                            />
                          </div>
                        </div>

                        <div class="col-sm-4">
                          <div class="input-box1">
                            <label for="mobile">Mobile</label>
                            <input
                              maxlength="10"
                              class="form-control"
                              id="mobile"
                              name="mobile"
                              v-model="mobile"
                              type="number"
                            />
                          </div>
                        </div>

                        <div class="col-4">
                           <label for="mobile">Birthday: </label>
                          <div class="input-box1 date" id="datepicker">
                            <input type="date" class="form-control" v-model="birthday" id="date"/>
                          </div>
                        </div>

                        <div class="col-12">
                          <div class="input-box1">
                            <label for="photo">Profile Photo</label>
                            <input class="form-control" id="photo" name="photo" @change="onFileChange" type="file" />
                          </div>
                        </div>

                        <div class="col-6">
                          <div class="input-box1">
                            <label for="name">NIT</label>
                            <input
                                class="form-control"
                                type="text"
                                name="nit"
                                v-model="nit"
                                placeholder="9999-999999-999-9"
                                @input="updateMaskNit"
                            />
                          </div>
                        </div>
                      
                        <div class="col-6">
                            <div class="input-box1">
                            <label for="name">NRC</label>
                            <input
                                class="form-control"
                                type="text"
                                name="nrc"
                                placeholder="999999-9"
                                v-model="nrc"
                                @input="updateMaskNrc"
                            />
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="input-box1">
                            <label for="name">Giro</label>
                            <input
                                class="form-control"
                                type="text"
                                name="giro"
                                v-model="gito"
                                maxlength="256"
                                placeholder=""
                            />
                            </div>
                        </div>
                        
                        <div class="col-8">
                          <div class="message-alert message-alert-checkout" v-if="NIT_NRC_ERROR_TEXT">
                                  <span class="close-button" @click="NIT_NRC_ERROR_TEXT = null">&times;</span>
                                  <p> {{ NIT_NRC_ERROR_TEXT }}</p>
                          </div>
                        </div>

                        <div class="col-12">
                          <div class="input-group">
                              <label>Dirección de Facturación*
                              </label>
                          </div>
                          <div class="input-flex">
                            <div class="input-group">
                                <label>Departamento:</label>
                                <select id="dpto" name="departamento" @change="changeLocation" v-model="departament_factura">
                                    <option selected disabled value="">Selecciona...</option>
                                    <template
                                        v-for="(DEPARTAMENTO, index) in S_DEPARTAMENTOS"
                                        :key="index"
                                    >
                                        <option :value="DEPARTAMENTO.name">{{DEPARTAMENTO.name}}</option>
                                    </template>
                                </select>
                            </div>
                            <div class="input-group">
                                <label>Municipio:</label>
                                <select id="municipio" name="municipio" v-model="municipality_factura">
                                    <option selected disabled value="">Choose...</option>
                                    <template
                                        v-for="(MUNICIPIO, index) in S_MUNICIPIOS"
                                        :key="index"
                                    >
                                        <option :value="MUNICIPIO.city">{{MUNICIPIO.city}}</option>
                                    </template>
                                </select>
                            </div>
                          </div>
                          <div class="input-group">
                              <label>Dirección Completa*
                              </label>
                              <input type="text" v-model="address_factura">
                          </div>
                        </div>
                        <!-- <div class="col-12">
                            <div class="input-box">
                                <label for="info_entrega">Información de entrega: </label>
                            </div>
                        </div> -->

                        <!-- <div class="col-6">
                            <div class="input-box">
                                <label for="departamento">Departamento</label>
                                <select class="form-select form-control" id="departamento" @change="changeLocation" v-model="departament">
                                    <option selected disabled value="">Selecciona...</option>
                                    <template
                                        v-for="(DEPARTAMENTO, index) in S_DEPARTAMENTOS"
                                        :key="index"
                                    >
                                        <option :value="DEPARTAMENTO.name">{{DEPARTAMENTO.name}}</option>
                                    </template>
                                </select>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="input-box">
                                <label for="municipio">Municipio</label>
                                <select class="form-select form-control" id="municipio" v-model="municipality">
                                    <option selected disabled value="">Choose...</option>
                                    <template
                                        v-for="(MUNICIPIO, index) in S_MUNICIPIOS"
                                        :key="index"
                                    >
                                        <option :value="MUNICIPIO.city">{{MUNICIPIO.city}}</option>
                                    </template>
                                </select>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="input-box">
                                <label for="comentarios">Dirección: </label>
                                <textarea
                                    class="form-control"
                                    placeholder=""
                                    style="height: 100px" v-model="address"
                                ></textarea>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="input-box">
                                <label for="comentarios">Punto de Referencia: </label>
                                <textarea
                                    class="form-control"
                                    placeholder=""
                                    style="height: 100px" v-model="point_reference"
                                ></textarea>
                            </div>
                        </div> -->
                      </div>

                      <div class="btn-box">
                        <button class="btn-outline btn-sm">Cancelar</button>
                        <button class="btn-solid btn-sm" type="button" @click="saveProfile()">
                          Guardar Cambios <i class="arrow"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- Profile Tabs End -->

                <!-- Security Tabs Start -->
                <div
                  class="tab-pane d-none"
                  id="security"
                  role="tabpanel"
                  aria-labelledby="security-tab"
                >
                  <div class="privacy-tab">
                    <div class="privacy box">
                      <div class="title-box3">
                        <h3>Privacy</h3>
                      </div>

                      <div class="setting-option">
                        <div class="content-box">
                          <h6 class="font-roboto">
                            Allows others to see my profile
                          </h6>
                          <p class="font-roboto">
                            all peoples will be able to see my profile.
                          </p>
                        </div>
                        <label class="switch">
                          <input
                            checked
                            type="checkbox"
                            name="chk1"
                            value="option1"
                            class="setting-check"
                          /><span class="switch-state"></span>
                        </label>
                      </div>

                      <div class="setting-option mt-3">
                        <div class="content-box">
                          <h6 class="font-roboto">
                            Who has save this profile only that people see my
                            profile
                          </h6>
                          <p class="font-roboto">
                            all peoples will not be able to see my profile.
                          </p>
                        </div>
                        <label class="switch">
                          <input
                            type="checkbox"
                            name="chk2"
                            value="option1"
                            class="setting-check"
                          /><span class="switch-state"></span>
                        </label>
                      </div>

                      <button class="btn-solid btn-sm">
                        Save Changes <i class="arrow"></i>
                      </button>
                    </div>

                    <div class="account-box">
                      <div class="title-box3">
                        <h3>Account settings</h3>
                      </div>

                      <div class="setting-option">
                        <div class="content-box">
                          <h6 class="font-roboto">
                            Deleting Your Account Will Permanently
                          </h6>
                          <p class="font-roboto">
                            Once your account is deleted, you will be logged out
                            and will be unable to log in back.
                          </p>
                        </div>
                        <label class="switch">
                          <input
                            type="checkbox"
                            name="chk3"
                            value="option2"
                            class="setting-check"
                            checked
                          /><span class="switch-state"></span>
                        </label>
                      </div>

                      <div class="setting-option mt-3">
                        <div class="content-box">
                          <h6 class="font-roboto">
                            Deleting Your Account Will Temporary
                          </h6>
                          <p class="font-roboto">
                            Once your account is deleted, you will be logged out
                            and you will be create new account.
                          </p>
                        </div>
                        <label class="switch">
                          <input
                            type="checkbox"
                            name="chk4"
                            value="option4"
                            class="setting-check"
                          /><span class="switch-state"></span>
                        </label>
                      </div>

                      <button class="btn-solid btn-sm">
                        Save Changes <i class="arrow"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <!-- Security Tabs End -->
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Dashboard End -->
    </main>
    <!-- Main End -->
    <!-- View Product Modal Start -->
    <!-- View Product Modal End -->
    <!-- use the modal component, pass in the prop -->
    <TrackingPedido :show="modalOpen" @close="modalOpen = false" @confirmation="modalOpen = false">
      <template #header>
        <h3>TRACKING DEL PEDIDO</h3>
      </template>
      <template #body>
        <p>Puedes ver el movimiento de tu pedido hasta la llegada en tus manos</p>
        <div class="cart-wrap">
          <div class="location-map" v-if="tacking_selected">
            <!-- height: 650px; -->
            <iframe
                class="map-iframe"
                :src="tacking_selected"
                style="width: 100%;height: 350px;"
            ></iframe>
          </div>
        </div>
      </template>
    </TrackingPedido>

    <TrackingPedido :show="modalEntrega" @close="modalEntrega = false" @confirmation="modalEntrega = false">
      <template #header>
        <h3>TRACKING DEL PEDIDO</h3>
      </template>
      <template #body>
        <p>Puedes ver el movimiento de tu pedido hasta la llegada en tus manos</p>
        <div class="cart-wrap row">
          <div class="col-6 d-flex align-items-center">
            <div class="row justify-content-center">
              <div class="col-6">
                <div class="container">
                  <ul class="timeline" v-if="tacking_selected.procesado == 0">
                    <li class="" :class="{'incompleted sucess' : tacking_selected.status == 'PENDIENTE', 'warning': tacking_selected.status != 'PENDIENTE'}">
                      <small>{{ tacking_selected.created_at }}</small>
                      <p class="action">Pendiente</p>
                    </li>
                    <li class="" :class="{'incompleted success' : getStateActive(tacking_selected,'RECIBIDO'), 'warning': getStateWarning(tacking_selected,'RECIBIDO'), 'danger': getStateDanger(tacking_selected,'RECIBIDO')}">
                      <small>{{ getStateDate(tacking_selected,'RECIBIDO') }}</small>
                      <p class="action">Recibido</p>
                    </li>
                    <li class="" :class="{'incompleted success' : getStateActive(tacking_selected,'EN CAMINO'), 'warning': getStateWarning(tacking_selected,'EN CAMINO'), 'danger': getStateDanger(tacking_selected,'EN CAMINO')}">
                      <small>{{ getStateDate(tacking_selected,'EN CAMINO') }}</small>
                      <p class="action">En Camino</p>
                    </li>
                    <li class="" :class="{'incompleted success' : getStateActive(tacking_selected,'ENTREGADO'), 'warning': getStateWarning(tacking_selected,'ENTREGADO'), 'danger': getStateDanger(tacking_selected,'ENTREGADO')}">
                      <small>{{ getStateDate(tacking_selected,'ENTREGADO') }}-</small>
                      <p class="action">Entregado</p>
                    </li>
                  </ul>
                  <ul class="timeline" v-if="tacking_selected.procesado == 1">
                    <li class="incompleted sucess" >
                      <small>{{ tacking_selected.created_at }}</small>
                      <p class="action">Pendiente</p>
                    </li>
                    <li class="incompleted success">
                      <small>{{ getStateDate(tacking_selected,'RECIBIDO') }}</small>
                      <p class="action">Recibido</p>
                    </li>
                    <!-- <li class="" :class="{'incompleted success' : getStateActive(tacking_selected,'EN CAMINO'), 'warning': getStateWarning(tacking_selected,'EN CAMINO'), 'danger': getStateDanger(tacking_selected,'EN CAMINO')}">
                      <small>{{ getStateDate(tacking_selected,'EN CAMINO') }}</small>
                      <p class="action">En Camino</p>
                    </li> -->
                    <li class="incompleted success" >
                      <small>{{ getStateDate(tacking_selected,'ENTREGADO') }}-</small>
                      <p class="action">Entregado</p>
                    </li>
                  </ul>
                </div>
                <!-- <div class="row">
                  <div class="col-12 d-flex align-items-center my-3 rounded" :class="{'bg-success' : tacking_selected.status == 'RECIBIDO', 'bg-secondary': tacking_selected.status != 'RECIBIDO'}">
                    <div class="mx-4" v-if="tacking_selected.status == 'RECIBIDO'">
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48"
                      >
                        <path fill="#c8e6c9" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path fill="#4caf50" d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"></path>
                      </svg>
                    </div>

                    <div class="mx-4" v-if="tacking_selected.status != 'RECIBIDO'">
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0,0,256,256"
                      style="fill:#000000;">
                        <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(5.33333,5.33333)"><path d="M44,24c0,11.045 -8.955,20 -20,20c-11.045,0 -20,-8.955 -20,-20c0,-11.045 8.955,-20 20,-20c11.045,0 20,8.955 20,20z" fill="#888888"></path><path d="M34.586,14.586l-13.57,13.586l-5.602,-5.586l-2.828,2.828l8.434,8.414l16.395,-16.414z" fill="#232323"></path></g></g>
                      </svg>
                    </div>

                    <div>
                      <p class="m-0" style="color: white;">RECIBIDO</p>
                    </div>
                  </div>
                  <div class="col-12 d-flex align-items-center my-3 rounded" :class="{'bg-success' : tacking_selected.status == 'EN CAMINO', 'bg-secondary': tacking_selected.status != 'EN CAMINO'}">
                    
                    <div class="mx-4" v-if="tacking_selected.status == 'EN CAMINO'">
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48">
                        <path fill="#c8e6c9" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path fill="#4caf50" d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"></path>
                      </svg>
                    </div>

                    <div class="mx-4" v-if="tacking_selected.status != 'EN CAMINO'">
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0,0,256,256"
                      style="fill:#000000;">
                        <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(5.33333,5.33333)"><path d="M44,24c0,11.045 -8.955,20 -20,20c-11.045,0 -20,-8.955 -20,-20c0,-11.045 8.955,-20 20,-20c11.045,0 20,8.955 20,20z" fill="#888888"></path><path d="M34.586,14.586l-13.57,13.586l-5.602,-5.586l-2.828,2.828l8.434,8.414l16.395,-16.414z" fill="#232323"></path></g></g>
                      </svg>
                    </div>

                    <div>
                      <p class="m-0" style="color: white;">EN CAMINO</p>
                    </div>
                  </div>
                  <div class="col-12 d-flex align-items-center my-3 rounded" :class="{'bg-success' : tacking_selected.status == 'ENTREGADO', 'bg-secondary': tacking_selected.status != 'ENTREGADO'}">
                    <div class="mx-4" v-if="tacking_selected.status == 'ENTREGADO'">
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48">
                        <path fill="#c8e6c9" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path fill="#4caf50" d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"></path>
                      </svg>
                    </div>

                    <div class="mx-4" v-if="tacking_selected.status != 'ENTREGADO'">
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0,0,256,256"
                      style="fill:#000000;">
                        <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(5.33333,5.33333)"><path d="M44,24c0,11.045 -8.955,20 -20,20c-11.045,0 -20,-8.955 -20,-20c0,-11.045 8.955,-20 20,-20c11.045,0 20,8.955 20,20z" fill="#888888"></path><path d="M34.586,14.586l-13.57,13.586l-5.602,-5.586l-2.828,2.828l8.434,8.414l16.395,-16.414z" fill="#232323"></path></g></g>
                      </svg>
                    </div>

                    <div>
                      <p class="m-0" style="color: white;">ENTREGADO</p>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="google-map" ref="googleMap" style="height: 300px"></div>
          </div>
        </div>
      </template>
    </TrackingPedido>

  </div>
</template>
<script>
import SALVADOR from '../../assets/js/salvador'
import myApi from '../../plugins/axios'
import GoogleMapsApiLoader from "google-maps-api-loader"
import TrackingPedido from './modal/TrackingPedido.vue'
import store from '../../plugins/store'

export default {
  name: "ProfileClient",
  data() {
    return {
      S_MUNICIPIOS:[],
      BACKUP_MUNICIPIOS:[],
      S_DEPARTAMENTOS:[],
      //
      fullname: null,
      name: null,
      surname: null,
      email: null,
      mobile: null,
      nit: null,
      nrc: null,
      title: null,
      gito: null,
      departament:null,
      municipality:null,
      address:null,
      point_reference:null,
      birthday: null,
      // 
      photo_file: null,
      //   
      avatar: '/assets/images/avatar/avatar.jpg',
      ORDERS: [],
      order_selected: null,
      // 
      new_address: 1,
      ADDRESS: [],
      ADDRES_SELECTED: null,
      // MAPS
      mapa_error: false,
      direccion_correcta: false,
      direccion_incorrecta: false,
      google: null,
      map: null,
      marker:null,
      latitud: 0,
      id: null,
      longitud: 0,
      referencia:null,
      numDeltas : 50,
      delay : 5,
      i : 0,
      // 
      modalOpen: false,
      tacking_selected:null,
      // 
      modalEntrega: false,
      departament_factura: null,
      municipality_factura: null,
      address_factura: null,
      NIT_NRC_ERROR_TEXT: null,
    };
  },
  components:{
    // ReviewProduct,
    TrackingPedido,
  },
  methods: {
    logout(){
      store.dispatch('logout');
      setTimeout(() => {
          this.$router.push({
              name: 'Login',
          })
      }, 50);
    },
    openModal(){
      this.modalOpen = true
    },
    openModalEntrega(){
      this.modalEntrega = true
    },
    updateMaskNit(){
      let value = this.nit.replace(/\D/g, '');

      if (value.length > 0) {
          let maskedValue = '';

          if (value.length > 4) {
              maskedValue += value.substr(0, 4) + '-';
          } else {
              maskedValue += value;
          }

          if (value.length > 10) {
            maskedValue += value.substr(4, 6) + '-';
          } else {
              maskedValue += value.substr(4);
          }

          if (value.length > 13) {
              maskedValue += value.substr(10, 3) + '-';
          } else {
              maskedValue += value.substr(10);
          }

          if (value.length > 14) {
              maskedValue += value.substr(13, 1);
          } else {
              maskedValue += value.substr(13);
          }

          this.nit = maskedValue;
      }
    },
    updateMaskNrc(){
        let value = this.nrc.replace(/\D/g, '');

        if (value.length > 0) {
            let maskedValue = '';

            if (value.length > 6) {
                maskedValue += value.substr(0, 6) + '-';
            } else {
                maskedValue += value;
            }

            if (value.length > 7) {
                maskedValue += value.substr(6, 1);
            } else {
                maskedValue += value.substr(6);
            }

            this.nrc = maskedValue;
        }
    },
    isNitValid(){
        var re = /^[0-9]{4}-[0-9]{6}-[0-9]{3}-[0-9]{1}$/;
        return re.test(this.nit);
    },
    isNRCValid(){
        var re = /^[0-9]{1,6}-[0-9]{1}$/;
        return re.test(this.nrc);
    },
    getStateDate(tacking_selected,status){
      if(tacking_selected.order_states.length > 0){
        let STATUS = tacking_selected.order_states.find((item) => item.state == status);
        if(STATUS){
          return STATUS.created_at;
        }
      }
      return "------";
    },
    getStateActive(tacking_selected,status){
      if(tacking_selected.order_states.length > 0){
        let STATUS = tacking_selected.order_states.find((item) => item.state == status);
        if(STATUS){
          if(STATUS.state == tacking_selected.status){
            return true;
          }
        }
      }
      return false;
    },
    getStateWarning(tacking_selected,status){
      if(tacking_selected.order_states.length > 0){
        let STATUS = tacking_selected.order_states.find((item) => item.state == status);
        // let STATUS_CURRENT = tacking_selected.order_states.find((item) => item.state == tacking_selected.status);
        if(STATUS){
          if(STATUS.state != tacking_selected.status){
            return true;
          }
        }
      }
      return false;
    },
    getStateDanger(tacking_selected,status){
      if(tacking_selected.order_states.length > 0){
        let STATUS = tacking_selected.order_states.find((item) => item.state == status);
        if(!STATUS){
          return true;
        }
      }
      return false;
    },
    async trackingInfo(ORDER){
      // console.log(ORDER);
      try {
        let data = {
          uniqd: ORDER.uniqd,
        }
        this.tacking_selected = null;
  
        if(ORDER.client_info || ORDER.client_guest){
          if(ORDER.client_info && (ORDER.client_info.departament == "San Salvador" || ORDER.client_info.departament == "La Libertad")){
            if(ORDER.client_info.municipality == "San Salvador" || ORDER.client_info.municipality == "Santa Tecla"){
                this.tacking_selected = ORDER;
                this.initGoogleMapEntrega(ORDER);
                this.openModalEntrega();
                return;
            }
          }
          if(ORDER.client_guest && (ORDER.client_guest.departament == "San Salvador" || ORDER.client_guest.departament == "La Libertad")){
            if(ORDER.client_guest.municipality == "San Salvador" || ORDER.client_guest.municipality == "Santa Tecla"){
                this.tacking_selected = ORDER;
                this.initGoogleMapEntrega(ORDER);
                this.openModalEntrega();
                return;
            }
          }
        }
        const resp = await myApi.post('ecommerce/tracking_info',data);
        console.log(resp);
        
        if(resp.data.tracking){
          this.tacking_selected = resp.data.URL;
          this.openModal();
        }else{
          this.$notify({
            title: "TRACKING",
            text: "EL PEDIDO TODAVIA NO TIENE UNA ASIGNACIÓN DE ENTREGA , INTENTE MAS TARDE, GRACIAS :)",
            duration: 3500,
            type:'warn',
          });
        }
      } catch (error) {
        console.log(error);
        this.$notify({
          title: "DEBUG",
          text: "SE PRESENTO UNA INCIDENCIA AL MOMENTO DE PROCESAR LA COMPRA , INTENTELO NUEVAMENTE O SINO CONSULTE CON SU PROVEEDOR",
          duration: 3500,
          type:'error',
        });
      }
    },
    async showProfile(){
        const resp = await myApi.post('ecommerce/show_profile',{});
        console.log(resp);

        this.name = resp.data.client.name;
        this.birthday = resp.data.client.birthday;
        this.surname = resp.data.client.surname;
        this.email = resp.data.client.email;
        this.mobile = resp.data.client.mobile;
        this.nit = resp.data.client.nit;
        this.nrc = resp.data.client.nrc;
        this.gito = resp.data.client.gito;
        // this.departament = resp.data.client.departament;
        // this.municipality = resp.data.client.municipality;
        // this.address = resp.data.client.address;
        this.departament_factura= resp.data.client.departament;
        this.municipality_factura= resp.data.client.municipality;
        this.address_factura= resp.data.client.address_f;

        // this.point_reference = resp.data.client.point_reference;

        this.fullname = resp.data.client.full_name;

        this.avatar = resp.data.client.avatar;
        this.changeLocation({target:{
            value: this.departament_factura,
        }})

        this.ORDERS = resp.data.orders.data;
        this.ADDRESS = resp.data.client.address;
        // 
        setTimeout(() => {
          const script2 = document.createElement('script');
          script2.src = '../../assets/js/feather/feather.min.js';
          document.body.appendChild(script2);
          script2.onload = () => {
              console.log('archivo.js cargado 2');
          }
        }, 50);
    },
    createAddress(val){
      this.new_address = val;
      if(this.new_address == 2 || this.new_address == 3){
        this.initGoogleMap();
      }
      this.ADDRES_SELECTED = null;
      this.departament = null;
      this.municipality = null;
      this.address = null;
      this.point_reference = null;
      // 
      setTimeout(() => {
        const script2 = document.createElement('script');
        script2.src = '../../assets/js/feather/feather.min.js';
        document.body.appendChild(script2);
        script2.onload = () => {
            console.log('archivo.js cargado 2');
        }
      }, 50);
    },
    editAddress(ADDRE){
      this.new_address = 3;
      this.departament = ADDRE.departament;
      this.municipality = ADDRE.municipality;
      this.address = ADDRE.address;
      this.point_reference = ADDRE.point_reference;
      this.ADDRES_SELECTED = ADDRE;
      this.title = ADDRE.title;

      this.initGoogleMap();

      setTimeout(() => {
        console.log(ADDRE);
        if(ADDRE.latitud){
          this.latitud = Number(ADDRE.latitud);
          this.longitud  = Number(ADDRE.longitud);
          this.initializeMap(this.latitud,this.longitud);
        }
      }, 50);

      this.changeLocation({target:{
        value: this.departament,
      }})
    },
    async updateAddress(){
      try {
        if(!this.departament || !this.municipality || !this.address || !this.point_reference || !this.title){
          this.$notify({
            title: "VALIDACIÓN",
            text: "PARA REGISTRAR UNA DIRECCIÓN TODAS LOS CAMPOS SON NECESARIOS",
            duration: 4000,
            type:'error',
          });
          return;
        }
        let data = {
          latitud: this.latitud,
          longitud: this.longitud,
          departament: this.departament,
          municipality: this.municipality,
          address: this.address,
          point_reference: this.point_reference,
          title: this.title,
        };
        const resp = await myApi.put('ecommerce/profile_address/'+this.ADDRES_SELECTED.id,data);
        console.log(resp);
        let INDEX =  this.ADDRESS.findIndex((item) => item.id == this.ADDRES_SELECTED.id);
        this.ADDRESS[INDEX] = resp.data.address;
        this.$notify({
          title: "SE REGISTRO LOS CAMBIOS DE LA DIRECCIÓN A SU PANEL",
          // text: "SE REGISTRO LOS CAMBIOS DE LA DIRECCIÓN A SU PANEL",
          duration: 4000,
          type:'success',
        });
        // this.departament = null;
        // this.municipality = null;
        // this.address = null;
        // this.point_reference = null;
      } catch (error) {
        console.log(error);
        this.$notify({
            title: "VALIDACIÓN",
            text: "HUBO UN ERROR A NIVEL INTERNO, PORFAVOR COMUNICARSE CON SU PROVEEDOR",
            duration: 4000,
            type:'error',
        });
      }
    },
    async saveAddress(){
      try {
        if(!this.departament || !this.municipality || !this.address || !this.point_reference || !this.title){
          this.$notify({
            title: "VALIDACIÓN",
            text: "PARA REGISTRAR UNA DIRECCIÓN TODAS LOS CAMPOS SON NECESARIOS",
            duration: 4000,
            type:'error',
          });
          return;
        }
        let data = {
          latitud: this.latitud,
          longitud: this.longitud,
          departament: this.departament,
          municipality: this.municipality,
          address: this.address,
          point_reference: this.point_reference,
          title: this.title,
        };
        const resp = await myApi.post('ecommerce/profile_address',data);
        console.log(resp);
        this.ADDRESS.push(resp.data.address);
        this.$notify({
          title: "SE AGREGO UNA NUEVA DIRECCIÓN A SU PANEL",
          // text: "SE AGREGO UNA NUEVA DIRECCIÓN A SU PANEL",
          duration: 4000,
          type:'success',
        });
        this.departament = null;
        this.municipality = null;
        this.address = null;
        this.point_reference = null;
      } catch (error) {
        console.log(error);
        this.$notify({
            title: "VALIDACIÓN",
            text: "HUBO UN ERROR A NIVEL INTERNO, PORFAVOR COMUNICARSE CON SU PROVEEDOR",
            duration: 4000,
            type:'error',
        });
      }
    },
    changeLocation(event){
        let MUNICIPIO_SELECTED = event.target.value;
        let MUNUCIPIOS = this.BACKUP_MUNICIPIOS.filter(item => item.state == MUNICIPIO_SELECTED);
        this.S_MUNICIPIOS = MUNUCIPIOS;
    },
    onFileChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length){
            return;
        }
        this.photo_file = files[0];
    },
    async saveProfile(){
        if(this.nit && !this.isNitValid()){
            this.NIT_NRC_ERROR_TEXT = "El NIT ingresado no tiene el formato correcto";
            return;
        }
        if(this.nrc && !this.isNRCValid()){
            this.NIT_NRC_ERROR_TEXT = "El NRC ingresado no tiene el formato correcto!";
            return;
        }
        let formData = new FormData();
        formData.append("name",this.name);
        if(this.surname){
            formData.append("surname",this.surname);
        }
        formData.append("email",this.email);

        if(this.mobile){
            formData.append("mobile",this.mobile);
        }
        if(this.nit){
            formData.append("nit",this.nit);
        }
        if(this.nrc){
            formData.append("nrc",this.nrc);
        }
        if(this.gito){
            formData.append("gito",this.gito);
        }

        if(this.departament_factura){
            formData.append("departament",this.departament_factura);
        }
        if(this.municipality_factura){
            formData.append("municipality",this.municipality_factura);
        }
        if(this.address_factura){
            formData.append("address",this.address_factura);
        }
        // if(this.point_reference){
        //     formData.append("point_reference",this.point_reference);
        // }
        if(this.photo_file){
            formData.append("photo_file",this.photo_file);
        }
        if(this.birthday){
          formData.append("birthday",this.birthday);
        }
        const resp = await myApi.post('ecommerce/profile',formData);

        if(resp.data.error){
            for (let key in resp.data.error) {
            this.$notify({
                    title: "VALIDACIÓN",
                    text: resp.data.error[key],
                    duration: 4000,
                    type:'error',
            });
            }
        }else{
            console.log(resp);
            // this.$cookies.set("carts", []);
            // this.emitter.emit("cart-shop", []);
            this.NIT_NRC_ERROR_TEXT = null;
            this.$notify({
                title: "PERFIL DEL CLIENTE SE ACTUALIZO",
                // text: `LA INFORMACION DEL PERFIL SE GUARDO`,
                duration: 3500,
            });
        }


    },
    viewDetailSale(ORDER){
      this.order_selected = ORDER;
      const script2 = document.createElement('script');
      script2.src = '../../assets/js/feather/feather.min.js';
      document.body.appendChild(script2);
      script2.onload = () => {
          console.log('archivo.js cargado 2');
      }
    },
    backList(){
      this.order_selected = null;
    },
    openReviewModel(ITEM){
      // console.log(ITEM);
      this.emitter.emit('review-detail-modal', ITEM);
    },
    initializeMap(lat,lng) {
      const mapContainer = this.$refs.googleMap

      this.map = new this.google.maps.Map(mapContainer, {
          center: {lat: lat, lng: lng},
          gestureHandling: 'greedy',
          zoom: 16
      })
      this.marker = new this.google.maps.Marker({
          position: {
              lat: lat,
              lng: lng
          },
          map: this.map,
          title: "Latitude:"+ 0 +" | Longitude:"+ 0
      });

      // var self = this;

      this.map.addListener('click', (mapsMouseEvent) => {

          let lat = Number(mapsMouseEvent.latLng.lat().toString());
          let lng = Number(mapsMouseEvent.latLng.lng().toString());
          console.log(lat,lng);
          var result = {
              lat : lat,
              lng : lng
          };
          // self.validar(lat,lng)
          this.efecto(result);
          this.direccion_correcta = true;
          // this.moveMarker();

      });
    },
    efecto(result){
      this.i = 0;
      this.deltaLat = (result.lat - this.latitud)/ this.numDeltas;
      this.deltaLng = (result.lng - this.longitud)/ this.numDeltas;
      this.moveMarker();
    },
    moveMarker(){
      this.latitud += this.deltaLat;
      this.longitud += this.deltaLng;
      this.marker.setTitle("Latitude:" + this.latitud + " | Longitude:"+ this.longitud);
      this.marker.setPosition({
          lat: this.latitud,
          lng: this.longitud
      });
      if(this.i !== this.numDeltas){
          this.i++;
          setTimeout(this.moveMarker(), this.delay);
      }
    },
    async initGoogleMap(){
      // 
      const googleMapApi = await GoogleMapsApiLoader({
          apiKey: 'AIzaSyA6MRhgLWpKf7XfNNUP3H9yz-ts9R3hvD8',
      });
      this.google = googleMapApi
      var that = this
      function onError() {
          that.mapa_error = true;
      }
      function onSuccess(geo) {
          // const position = {
          //     lat: geo.coords.latitude,
          //     lng: geo.coords.longitude
          // };
          // Reposition the map to the detected location
          that.latitud =  Number(geo.coords.latitude)
          that.longitud =  Number(geo.coords.longitude)
          that.mapa_error = false;
          that.initializeMap(that.latitud,that.longitud);
          // that.validar(that.latitud,that.longitud)
      }

      if (!navigator.geolocation) {
          onError();
      } else {
          navigator.geolocation.getCurrentPosition(onSuccess, onError);
      }
      // this.obtener_direcciones();
      // this.initializeMap(this.latitud,this.longitud);
    },
    async initGoogleMapEntrega(ORDER = null){
      // 
      const googleMapApi = await GoogleMapsApiLoader({
          apiKey: 'AIzaSyA6MRhgLWpKf7XfNNUP3H9yz-ts9R3hvD8',
      });
      this.google = googleMapApi
      var that = this
      function onError() {
          that.mapa_error = true;
      }
      function onSuccess(geo) {
          // const position = {
          //     lat: geo.coords.latitude,
          //     lng: geo.coords.longitude
          // };
          // Reposition the map to the detected location
          console.log(geo,ORDER);
          if(ORDER.status == 'ENTREGADO'){
            that.latitud = Number(ORDER.latitud) ;
            that.longitud = Number(ORDER.longitud) ;
          }else{
            that.latitud =  13.678767566370581;//Number(geo.coords.latitude)
            that.longitud =  -89.27604134734102;//Number(geo.coords.longitude)
          }
          that.mapa_error = false;
          that.initializeMap(that.latitud,that.longitud);
          // that.validar(that.latitud,that.longitud)
      }

      if (!navigator.geolocation) {
          onError();
      } else {
          navigator.geolocation.getCurrentPosition(onSuccess, onError);
      }
      // this.obtener_direcciones();
      // this.initializeMap(this.latitud,this.longitud);
    },
  },
  created() {},
  mounted() {
    this.BACKUP_MUNICIPIOS = SALVADOR.MUNICIPIOS;
    this.S_DEPARTAMENTOS = SALVADOR.DEPARTAMENTOS;
    this.showProfile();
    this.emitter.on('review-modal-refresh',(data) => {
      let backup = this.order_selected;
      this.order_selected = null;
      setTimeout(() => {
        this.viewDetailSale(backup);
        let INDEX = this.order_selected.items.findIndex(item => item.id == data.item.id);
        this.order_selected.items[INDEX].review = data.review;
      }, 50);
    });
    
    // this.initGoogleMap();

    // setTimeout(() => {
    //       const script = document.createElement('script');
    //         script.src = '../../assets/js/bootstrap/bootstrap.bundle.min.js';
    //         document.body.appendChild(script);
    //         script.onload = () => {
    //           console.log('archivo.js cargado 1');
    //         }
    //   }, 50);
  },
};
</script>

<style>
/* .t-body-orders tr:hover{
  background: #ebebeb;
} */

.timeline li {
  position: relative;
  padding-bottom: 32px;
  padding-left: 32px;
}
.timeline li:before {
  content: "";
  position: absolute;
  display: block;
  top: 1.7rem;
  left: 0;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 100%;
}
.timeline li:not([class]):before {
  box-shadow: inset 0px 0px 0px 2px #cccccc;
}
.timeline li:after {
  content: "";
  position: absolute;
  display: block;
  top: calc(1.7rem + 12px);
  left: 3px;
  bottom: -1.5rem;
  width: 2px;
  background-color: #cccccc;
}
.timeline li:last-child > .action {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: bold;
}
.timeline li:last-child:before {
  top: 1.825rem;
}
.timeline li:last-child:after {
  content: none;
}
.timeline li.success:before {
  background-color: #739e41;
}
.timeline li.success:not(.incompleted):after {
  background-color: #739e41;
}
.timeline li.warning:before {
  background-color: #f2a51a;
}
.timeline li.warning:not(.incompleted):after {
  background-color: #739e41;
}
.timeline li.danger:before {
  background-color: #f14c4c;
}

</style>