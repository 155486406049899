<template>
    <div>
        <!-- Mobile Menu Footer Start -->
      <footer class="mobile-menu-footer d-sm-none">
        <ul>
          <li>
            <a href="/" class="active">
              <img src="/assets/images/Nuevos/index.svg">
              <span>Inicio</span>
            </a>
          </li>
          <li>
            <a href="/shop/categorie/2?text=Gamer" class="search-link">
              <img src="/assets/images/Nuevos/gamer.svg">
              <span>Gamer</span>
            </a>
          </li>
          <li>
            <a href="/shop/tag?text=nuevo">
              <img src="/assets/images/Nuevos/new.svg">
              <span>Nuevos</span>
            </a>
          </li>
          <li>
            <a href="/cart">
              <img src="/assets/images/Nuevos/cart.svg">
              <span>Carrito</span>
            </a>
          </li>
          <li>
            <a href="/perfil-cliente" v-if="user">
              <img src="/assets/images/Nuevos/account.svg">
              <span >Perfil</span>
            </a>
            <a href="/login" v-if="!user">
              <img src="/assets/images/Nuevos/user-plus.svg">
              <span >Iniciar</span>
            </a>
          </li>
        </ul>
      </footer>
      <!-- Mobile Menu Footer End -->
        <!-- Document Footer Start -->
        <footer class="footer-document ratio_asos mb-xxl">
            <div>
                <div class="container-lg">
                <div class="main-footer">
                    <div class="row gy-3 gy-md-4 gy-xl-0">

                    <div class="col-lg-3 col-md-6 order-lg-1 order-md-1">
                        <div class="content-box">
                        <img class="logo" src="/assets/images/logos/logo-w.png" alt="logo-white" />
                        <ul>
                            <li><img src="/assets/images/Nuevos/location.svg"> <span> Plaza Merliot / Metrocentro / Plaza Mundo</span></li>
                            <li  v-if="config_general">
                            <img src="/assets/images/Nuevos/Phone.svg"><a class="nav" :href="config_general.link_phone"><span>{{ config_general.phone }}</span></a>
                            </li>
                            <li  v-if="config_general">
                            <img src="/assets/images/Nuevos/email.svg"><a class="nav" :href="'mailto:'+config_general.email"><span>{{ config_general.email }}</span></a>
                            </li>
                            <li>
                              <img src="/assets/images/inicio/Logos-footer.png" alt="Correo" />
                            </li>
                        </ul>
                        </div>
                    </div>
                    

                    <div class="nav-footer col-lg-2 col-md-6 order-lg-2 order-md-2">
                        <div class="nav content-box d-md-block">
                        <h5 class="heading-footer">Categorias</h5>
                        <ul>
                            <li v-for="(categorie_cookie, index) in categorie_cookies" :key="index">
                              <a class="nav" :href="'/shop/categorie/'+categorie_cookie.id+'?text='+categorie_cookie.name">{{ categorie_cookie.name }} </a>
                            </li>
                            
                        </ul>
                        </div>
                    </div>

                    

                    <div class="nav-footer col-lg-2 col-md-6 order-md-3 order-lg-3">
                        <div class="nav d-md-block content-box">
                        <h5 class="heading-footer">Avisos y Políticas</h5>
                        <ul>
                            <li><a class="nav" href="/condiciones-de-ofertas">Condiciones Ofertas</a></li>
                            <li><a class="nav" href="/aviso-de-marca-comercial">Aviso de Marca</a></li>
                            <li><a class="nav" href="/politica-de-garantia">Garantia RMA</a></li>
                            <li><a class="nav" href="/politica-de-privacidad">Privacidad</a></li>
                            <li><a class="nav" href="/entregas-y-envios">Delivery info</a></li>
                            <li><a class="nav" href="/termino-y-condiciones">Terminos y Condiciones</a></li>
                        </ul>
                        </div>
                    </div>

                    <div class="nav-footer col-lg-2 col-md-6 order-md-4 order-lg-4">
                        <div class="nav d-md-block content-box">
                        <h5 class="heading-footer">Nosotros</h5>
                        <ul>
                            <!-- <li><a class="nav" href="/empleos">Empleos</a></li>
                             -->
                             <li><a class="nav" href="/historia">Historia</a></li>
                            <li><a class="nav" href="/sucursales">Sucursales</a></li>
                            <li><a class="nav" href="/servicios">Servicios</a></li>
                            <li><a class="nav" href="/contactos">Contacto</a></li>
                            <li><a class="nav" href="/blog">Blog</a></li>
                        </ul>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 order-md-5 order-lg-5">
                        <div class="content-box">
                        <h5 class="heading-footer">Siguenos</h5>
                        <div class="follow-wrap" v-if="config_general">
                            <ul>
                              <!-- https://www.instagram.com/zonadigitalsv/
                              https://www.facebook.com/zonadigitalsv
                              https://www.tiktok.com/@zonadigitalsv
                              https://twitter.com/zonadigitalsv
                              https://www.youtube.com/c/zonadigitalSV -->

                              <li>
                                  <a :href="config_general.instagram" target="_blank" > <img src="/assets/icons/svg/social/inta.svg" alt="Instagram" /> </a>
                              </li>  
                              <li>
                                  <a :href="config_general.facebook" target="_blank"> <img src="/assets/icons/svg/social/fb.svg" alt="Facebook" /> </a>
                              </li>
                              <li>
                                  <a :href="config_general.tiktok" target="_blank"> <img src="/assets/icons/svg/social/tiktok.svg" alt="Tiktok" /> </a>
                              </li>
                              
                              <!-- <li>
                                  <a :href="config_general." target="_blank"> <img src="/assets/icons/svg/social/tw.svg" alt="Twitter" /> </a>
                              </li> -->
                              
                              <li>
                                  <a :href="config_general.youtube" target="_blank"> <img src="/assets/icons/svg/social/youtube.svg" alt="Youtube" /> </a>
                              </li>
                            </ul>
                        </div>

                        <div class="subscribe-box">
                            <h5 class="heading-footer">Suscríbete</h5>
                            <p>Enteráte de ofertas y productos nuevos</p>
                        </div>

                        <form action="javascript:void(0)" class="footer-form">
                            <input required type="email" class="form-control" placeholder="Ingresa tu correo..." />
                            <button type="submit" class="btn-solid">SUSCRIBIRME </button>
                        </form>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="sub-footer">
                    <div class="row gy-3">
                    <div class="col-md-6">
                        <ul>
                        
                        
                        </ul>
                    </div>
                    <div class="col-md-6">
                        <p class="mb-0">Copyright © 2023 Zona Digital El Salvador . Todos los derechos reservados.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </footer>
        <!-- Document Footer End -->


        <!-- Tap To Top Button Start -->
        <div class="tap-to-top-box hide">
        <button class="tap-to-top-button"><i data-feather="chevrons-up"></i></button>
        </div>
        <!-- Tap To Top Button End -->
    </div>
</template>

<script>
import myApi from '../../plugins/axios'
import store from '../../plugins/store'

export default {
    name: 'FooterSection',
    data(){
      return {
        categorie_cookies: [],
        config_general: null,
        user: null,
      }
    },
    methods: {
      async listConfigFooter(){
        try {
          const resp = await myApi.get(
            "ecommerce/config_footer/"
          );
          console.log(resp);
          this.categorie_cookies = resp.data.categories;
          this.config_general = resp.data.config_general;
          this.$cookies.set('categories',this.categorie_cookies);
          this.$cookies.set('config_general',{
            phone: this.config_general.phone,
            link_phone: this.config_general.link_phone,
            email: this.config_general.email,
            // 
            facebook: this.config_general.facebook,
            instagram: this.config_general.instagram,
            tiktok: this.config_general.tiktok,
            youtube: this.config_general.youtube,
            address: this.config_general.address,
          });
        } catch (error) {
            console.log(error);
        }
      },
    },
    created(){

    },
    mounted(){
      this.user = store.state.user;

      let CATEGORIES = this.$cookies.get("categories") ? this.$cookies.get("categories") : [];
      let CONFIG_GENERAL = this.$cookies.get("config_general") ? this.$cookies.get("config_general") : [];
      if(CATEGORIES.length == 0){
          this.listConfigFooter()
      }else{
          this.categorie_cookies = CATEGORIES;
          this.config_general = CONFIG_GENERAL ;
      }
    }
}
</script>