<template>
  <div class="">
    <!-- <HeaderSection /> -->
    <!-- Main Start -->
    <main class="main">
      <!-- Home Banner Section Start -->
      <section class="home-slider-common ratio_40 p-0">
        <div class="swiper-container home-slider">
          <div class="swiper-wrapper">

            <template v-for="(banner, index) in banners" :key="index">
              <div class="swiper-slide" v-if="banner.type == 1 && !isMobile()">
                <!-- <div class="banner">
                  <div> -->
                    <!--  v-lazy="{ src: banner.imagen, loading: '/assets/svg_load/image-preloader.svg' }" :src="banner.imagen" -->
                    <template v-if="banner.links">
                      <a :href="banner.links" >
                        <img class="bg-img img-fluid" 
                        :src="banner.imagen"
                         alt="slide 3" />
                      </a>
                    </template>
                    <img v-if="!banner.links" :src="banner.imagen" class="bg-img img-fluid" 
                    alt="slide 1" />
                  <!-- </div>
                </div> -->
              </div>
            </template>
            <template v-for="(banner, index) in banners" :key="index">
              <div class="swiper-slide" v-if="banner.type == 2 && isMobile()">
                <!-- <div class="banner">
                  <div> -->
                    <!--  v-lazy="{ src: banner.imagen, loading: '/assets/svg_load/image-preloader.svg' }" :src="banner.imagen" -->
                    <template v-if="banner.links">
                      <a :href="banner.links" >
                        <img class="bg-img img-fluid" 
                        :src="banner.imagen"
                         alt="slide 3" />
                      </a>
                    </template>
                    <img v-if="!banner.links" :src="banner.imagen" class="bg-img img-fluid" 
                    alt="slide 1" />
                  <!-- </div>
                </div> -->
              </div>
            </template>
            <template v-if="banners.length == 0">
              <div class="swiper-slide">
                <!-- <div class="banner">
                  <div> -->
                    <img src="/assets/svg_load/image-preloader.svg"  class="bg-img img-fluid" 
                    alt="slide 1" />
                  <!-- </div>
                </div> -->
              </div>
            </template>
            <!-- Slide Start -->
            <!-- <div class="swiper-slide">
              <div class="banner">
                <div>
                <img class="bg-img img-fluid" src="/assets/images/banner/Banner_principal.png" alt="slide 1" />
                </div>
              </div>
            </div> -->
            <!-- Slide End -->


          </div>
          
            <!-- Flechas de navegación -->
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
            <!-- Puntos de paginación -->
            <div class="swiper-pagination"></div>

        </div>
      </section>
      <!-- Home Banner Section End -->

      <!-- Categories Section Start -->
      <!-- <div class="catagories-section">
        <div class="swiper home-catagories-slider">
          <div class="swiper-wrapper mb-0">
            <template v-for="(categorie, index) in categories" :key="index">
              <div class="swiper-slide">
                <div class="catagories">
                  <img class="leaf-shape" src="assets/svg/leaf.svg" alt="zd" />
                  <a :href="'/tienda/categoria/'+categorie.id+'?text='+categorie.name">
                    <div class="img-wrap">
                      <img :src="categorie.miniatura" alt="zd" />
                    </div>
                    <div class="btn-box">
                      <span class="catagories-name"> {{ categorie.name }} </span>
                      <div class="btn-special">SHOP <i class="arrow"></i></div>
                    </div>
                  </a>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div> -->
      <div class="shop-page">
        <div class="container-lg">
          <div class="catagories-section mt-0">
            <div class="swiper-box">
              <div class="swiper product-slider">
                <div class="swiper-wrapper">
                  <template v-for="(categorie, index) in categories" :key="index">
                    <div class="swiper-slide">
                      <div class="catagories catagories-shop" :class="{'active_categorie': categorie_selected == categorie.id}">
                          <a :href="'/shop/categorie/'+categorie.id+'?text='+categorie.name">
                          <div class="img-wrap">
                            <img :src="categorie.miniatura" alt="zd" />
                          </div>
                          <div class="btn-box">
                            <span class="catagories-name"> {{ categorie.name }} </span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div class="swiper-button">
                <button class="swiper-next">
                  <i data-feather="arrow-left"></i>
                </button>
                <button class="swiper-prev"><i data-feather="arrow-right"></i></button>
              </div>
            </div>
          </div>
        </div>
      <!-- </div> -->
      <!-- Categories Section End -->

      <!-- Categories Section Start -->
      <!-- <div class="catagories-section">
        <div class="swiper home-catagories-slider">
          <div class="swiper-wrapper mb-0">
            <div class="swiper-slide">
              <div class="catagories">
                <a href="/tienda">
                  <div class="img-wrap">
                    <img src="/assets/images/categories/Zona-Gamer-400X290.png" alt="Zona Gamer" />
                  </div>
                  <div class="btn-box">
                    <span class="catagories-name"> Zona Gamer </span>
                    <div class="btn-special">VER <i class="arrow"></i></div>
                  </div>
                </a>
              </div>
            </div>

            <div class="swiper-slide">
              <div class="catagories">
                <a href="/tienda">
                  <div class="img-wrap">
                  <img src="/assets/images/categories/Zona-Computo-400X290.png" alt="Zona Computo" />
                  </div>
                  <div class="btn-box">
                    <span class="catagories-name"> Zona Computo</span>
                    <div class="btn-special">VER <i class="arrow"></i></div>
                  </div>
                </a>
              </div>
            </div>

            <div class="swiper-slide">
              <div class="catagories">
                <a href="/tienda">
                  <div class="img-wrap">
                  <img src="/assets/images/categories/Zona-Redes-400X290.png" alt="Zona Redes" />
                  </div>
                  <div class="btn-box">
                    <span class="catagories-name"> Zona de Redes </span>
                    <div class="btn-special">VER <i class="arrow"></i></div>
                  </div>
                </a>
              </div>
            </div>

            <div class="swiper-slide">
              <div class="catagories">
                <a href="/tienda">
                  <div class="img-wrap">
                    <img src="/assets/images/categories/Zona-Electronica-400X290.png" alt="Zona Electrónica" />
                  </div>
                  <div class="btn-box">
                    <span class="catagories-name"> Zona Electrónica </span>
                    <div class="btn-special">VER <i class="arrow"></i></div>
                  </div>
                </a>
              </div>
            </div>

            <div class="swiper-slide">
              <div class="catagories">
                <a href="/tienda">
                  <div class="img-wrap">
                    <img src="/assets/images/categories/Zona-Hardware-400X290.png" alt="Zona Hardware" />
                  </div>
                  <div class="btn-box">
                    <span class="catagories-name">Zona Hardware </span>
                    <div class="btn-special">VER <i class="arrow"></i></div>
                  </div>
                </a>
              </div>
            </div>

            <div class="swiper-slide">
              <div class="catagories">
                <a href="/tienda">
                  <div class="img-wrap">
                    <img src="/assets/images/categories/Zona-Home-Office-400X290.png" alt="Zona Home Office" />
                  </div>
                  <div class="btn-box">
                    <span class="catagories-name">Zona Home Office </span>
                    <div class="btn-special">VER <i class="arrow"></i></div>
                  </div>
                </a>
              </div>
            </div>

            <div class="swiper-slide">
              <div class="catagories">
                <a href="/tienda">
                  <div class="img-wrap">
                    <img src="/assets/images/categories/Zona-Movil-400X290.png" alt="Zona Móvil" />
                  </div>
                  <div class="btn-box">
                    <span class="catagories-name">Zona Móvil </span>
                    <div class="btn-special">VER <i class="arrow"></i></div>
                  </div>
                </a>
              </div>
            </div>

            <div class="swiper-slide">
              <div class="catagories">
                <a href="/tienda">
                  <div class="img-wrap">
                    <img src="/assets/images/categories/Zona-Outlet-400X290.png" alt="Zona Home Outlet" />
                  </div>
                  <div class="btn-box">
                    <span class="catagories-name">Zona Outlet </span>
                    <div class="btn-special">VER <i class="arrow"></i></div>
                  </div>
                </a>
              </div>
            </div>



          </div>
        </div>
      </div> -->
      <!-- Categories Section End -->

     <!-- Collection Banner 5 Start v-lazy="{ src: imagen_1_b, loading: '/assets/svg_load/image-preloader.svg' }"-->
     <section class="special-product-4">
        <div class="container-lg">
          

          <div class="row g-2 g-lg-3 g-xxl-4 ratio_asos" v-if="config_general">
            <div class="col-md-6">
              <a :href="config_general && config_general.imagen_1 ? config_general.imagen_1 : '/shop'" class="img-box big-ratio">
                <img class="bg-img" 
                :src="imagen_1_b"
                 alt="banner" />
                 <!-- :src="imagen_1_b" -->
              </a>
            </div>

            <div class="col-md-6">
              <div class="row g-2 g-lg-3 g-xxl-4">
                <div class="col-6">
                  <a :href="config_general && config_general.imagen_2 ? config_general.imagen_2 : '/shop'" class="img-box sm-ratio">
                  <img class="bg-img" 
                  :src="imagen_2_b"
                   alt="banner" />
                  </a>
                  <!-- :src="imagen_2_b" -->
                </div>

                <div class="col-6">
                  <a :href="config_general && config_general.imagen_3 ? config_general.imagen_3 : '/shop'" class="img-box sm-ratio">
                  <img class="bg-img" 
                  :src="imagen_3_b"
                   alt="banner" />
                   <!-- :src="imagen_3_b" -->
                  </a>
                </div>

                <div class="col-12">
                  <a :href="config_general && config_general.imagen_4 ? config_general.imagen_4 : '/shop'" class="img-box banner-ratio">
                  <img class="bg-img" 
                  :src="imagen_4_b"
                  alt="banner" />
                  </a>
                  <!-- :src="imagen_4_b"  -->
                </div>
              </div>
            </div>
          </div>
          <div class="row g-2 g-lg-3 g-xxl-4 ratio_asos" v-if="!config_general">
            <div class="col-md-6">
              <a href="#" onclick="return false;" class="img-box big-ratio">
                <img class="bg-img" 
                 src="/assets/svg_load/image-preloader.svg"
                 alt="banner" />
              </a>
            </div>

            <div class="col-md-6">
              <div class="row g-2 g-lg-3 g-xxl-4">
                <div class="col-6">
                  <a href="#" onclick="return false;" class="img-box sm-ratio">
                  <img class="bg-img" 
                  src="/assets/svg_load/image-preloader.svg"
                   alt="banner" />
                  </a>
                </div>

                <div class="col-6">
                  <a href="#" onclick="return false;" class="img-box sm-ratio">
                  <img class="bg-img" 
                  src="/assets/svg_load/image-preloader.svg"
                   alt="banner" />
                  </a>
                </div>

                <div class="col-12">
                  <a href="#" onclick="return false;" class="img-box banner-ratio">
                  <img class="bg-img" 
                  src="/assets/svg_load/image-preloader.svg"
                  alt="banner" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Collection Banner 5 End -->


      <!-- Top Product Section Start -->
      <section class="pb-0" v-if="false">
        <div class="container-lg">
          <div class="title-box">
            <h2 class="unique-heading">TOP COLLECTION</h2>
            <span class="title-divider1"><span class="squre"></span><span class="squre"></span></span>
            <p>A conscious collection made entirely from food crop waste, recycled cotton, other more sustainable materials.</p>
          </div>
          <div class="nav-tabs-star">
            <ul class="nav nav-pills" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="woman-tab" data-bs-toggle="pill" data-bs-target="#woman" type="button" role="tab" aria-controls="woman" aria-selected="true">Women</button>
              </li>

              <li><i data-feather="star"></i></li>

              <li class="nav-item" role="presentation">
                <button class="nav-link" id="man-tab" data-bs-toggle="pill" data-bs-target="#man" type="button" role="tab" aria-controls="man" aria-selected="false">Man</button>
              </li>

              <li><i data-feather="star"></i></li>

              <li class="nav-item" role="presentation">
                <button class="nav-link" id="kids-tab" data-bs-toggle="pill" data-bs-target="#kids" type="button" role="tab" aria-controls="kids" aria-selected="false">Kids</button>
              </li>
            </ul>
            <!-- Tabs Filter End -->

            <!-- Tab Content Start -->
            <div class="tab-content ratio_asos" id="pills-tabContent">
              <!-- Women Tab Content Start -->
              <div class="tab-pane fade show active" id="woman" role="tabpanel" aria-labelledby="woman-tab">
                <div class="swiper product-slider">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="product in products" :key="product._id">
                      <CardProductA v-if="product" :product="product"></CardProductA>
                      <!-- <div class="product-card">
                        <div class="img-box">

                          <ul class="thumbnail-img">
                            <li class="active thumb">
                                <img v-bind:src="product.image" alt="thumbnail" />
                            </li>
                            <template v-for="(imagen,index) in product.images" :key="index">
                              <li class="thumb" >
                                <img v-bind:src="imagen.imagen" alt="thumbnail" />
                              </li>
                            </template>
                           
                          </ul>

                          <router-link :to="'/detalle/'+product.slug" class="primary-img">
                            <img class="img-fluid bg-img" v-bind:src="product.image" alt="product" />
                          </router-link>
                          
                          <span class="arrow-label-wrap" v-if="product.name_tag"> 
                            <span class="arrow-label" :style="{background: product.color_tag+' !important'}"> {{product.name_tag}}</span> 
                          </span>

                          <div class="rating">

                            <span>{{ product.avg_rating }} <i data-feather="star"></i> </span>
                            <span>{{ product.reviews_count }}</span>
                          </div>

                          <ul class="option-wrap">

                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="Lista Deseos" @click="addWishList(product)">
                              <a href="javascript:void(0)" class="wishlist-btn">
                                <i data-feather="heart"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="Comparar">
                              <a href="javascript:void(0)" @click="goToCompare(product)">
                                <i data-feather="repeat"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="Ver Producto">
                              <a href="javascript:void(0)" @click="openDetailProduct(product)" data-bs-toggle="modal" data-bs-target="#viewModal">
                                <i data-feather="eye"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Cart">
                              <a href="javascript:void(0)" @click="addCart(product)" class="">
                                <i data-feather="shopping-bag"></i>
                              </a>
                            </li>
                          </ul>
                        </div>

                        <div class="content-box">
                          <router-link :to="'/detalle/'+product.slug">
                              <p>{{ product.categorie_first.name }}</p>
                              <h5>{{ product.title }}</h5>
                              <span>{{ getAmountTotal(product) }} USD</span> 
                              <del v-if="product.discount_g">{{ getAmountDiscount(product) }} USD</del>
                          </router-link>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- Women Tab Content End -->

              <!-- Man Tab Content Start -->
              <div class="tab-pane fade" id="man" role="tabpanel" aria-labelledby="man-tab">
                <div class="swiper product-slider">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide">
                      <div class="product-card">
                        <div class="img-box">
                          <!-- Thumbnail -->
                          <ul class="thumbnail-img">
                            <li class="active thumb"><img src="assets/images/fashion/product/front/7.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-1.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-2.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-3.jpg" alt="thumbnail" /></li>
                          </ul>

                          <a href="product.html" class="primary-img"><img class="img-fluid bg-img" src="assets/images/fashion/product/front/5.jpg" alt="product" /> </a>

                          <!-- Rating -->
                          <div class="rating">
                            <span>4.5 <i data-feather="star"></i> </span>
                            <span>245</span>
                          </div>

                          <!-- Option -->
                          <ul class="option-wrap">
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Wishlist">
                              <a href="javascript:void(0)" class="wishlist-btn">
                                <i data-feather="heart"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="Compare">
                              <a href="compare.html"><i data-feather="repeat"></i></a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="View Product">
                              <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewModal">
                                <i data-feather="eye"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Cart">
                              <a href="javascript:void(0)" class="addtocart-btn">
                                <i data-feather="shopping-bag"></i>
                              </a>
                            </li>
                          </ul>
                        </div>

                        <!-- Content Box -->
                        <div class="content-box">
                          <a href="product.html">
                            <p>Chopard</p>
                            <h5>Shirt short mini dresses</h5>
                            <span>$120.00</span> <del>$150.00</del>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="swiper-slide">
                      <div class="product-card">
                        <div class="img-box">
                          <!-- Thumbnail -->
                          <ul class="thumbnail-img">
                            <li class="active thumb"><img src="assets/images/fashion/product/front/7.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-1.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-2.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-3.jpg" alt="thumbnail" /></li>
                          </ul>

                          <a href="product.html" class="primary-img"><img class="img-fluid bg-img" src="assets/images/fashion/product/front/6.jpg" alt="product" /> </a>

                          <!-- Rating -->
                          <div class="rating">
                            <span>4.5 <i data-feather="star"></i> </span>
                            <span>165</span>
                          </div>

                          <!-- Option -->
                          <ul class="option-wrap">
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Wishlist">
                              <a href="javascript:void(0)" class="wishlist-btn">
                                <i data-feather="heart"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="Compare">
                              <a href="compare.html"><i data-feather="repeat"></i></a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="View Product">
                              <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewModal">
                                <i data-feather="eye"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Cart">
                              <a href="javascript:void(0)" class="addtocart-btn">
                                <i data-feather="shopping-bag"></i>
                              </a>
                            </li>
                          </ul>
                        </div>

                        <!-- Content Box -->
                        <div class="content-box">
                          <a href="product.html">
                            <p>C & A</p>
                            <h5>Shirt short mini dresses</h5>
                            <span>$140.00</span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="swiper-slide">
                      <div class="product-card">
                        <div class="img-box">
                          <!-- Thumbnail -->
                          <ul class="thumbnail-img">
                            <li class="active thumb"><img src="assets/images/fashion/product/front/7.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-1.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-2.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-3.jpg" alt="thumbnail" /></li>
                          </ul>

                          <a href="product.html" class="primary-img"><img class="img-fluid bg-img" src="assets/images/fashion/product/front/7.jpg" alt="product" /> </a>

                          <!-- Arrow label -->
                          <span class="arrow-label-wrap"> <span class="arrow-label bg-theme-sun"> NEW</span> </span>

                          <!-- Rating -->
                          <div class="rating">
                            <span>4.5 <i data-feather="star"></i> </span>
                            <span>215</span>
                          </div>

                          <!-- Option -->
                          <ul class="option-wrap">
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Wishlist">
                              <a href="javascript:void(0)" class="wishlist-btn">
                                <i data-feather="heart"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="Compare">
                              <a href="compare.html"><i data-feather="repeat"></i></a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="View Product">
                              <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewModal">
                                <i data-feather="eye"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Cart">
                              <a href="javascript:void(0)" class="addtocart-btn">
                                <i data-feather="shopping-bag"></i>
                              </a>
                            </li>
                          </ul>
                        </div>

                        <!-- Content Box -->
                        <div class="content-box">
                          <a href="product.html">
                            <p>Bluezone</p>
                            <h5>Women’s long sleeve shirt</h5>
                            <span>$100.00</span> <del>$140.00</del>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="swiper-slide">
                      <div class="product-card">
                        <div class="img-box">
                          <!-- Thumbnail -->
                          <ul class="thumbnail-img">
                            <li class="active thumb"><img src="assets/images/fashion/product/front/7.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-1.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-2.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-3.jpg" alt="thumbnail" /></li>
                          </ul>

                          <a href="product.html " class="primary-img"><img class="img-fluid bg-img" src="assets/images/fashion/product/front/8.jpg" alt="product" /> </a>

                          <!-- Rating -->
                          <div class="rating">
                            <span>4.5 <i data-feather="star"></i> </span>
                            <span>136</span>
                          </div>

                          <!-- Option -->
                          <ul class="option-wrap">
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Wishlist">
                              <a href="javascript:void(0)" class="wishlist-btn">
                                <i data-feather="heart"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="Compare">
                              <a href="compare.html"><i data-feather="repeat"></i></a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="View Product">
                              <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewModal">
                                <i data-feather="eye"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Cart">
                              <a href="javascript:void(0)" class="addtocart-btn">
                                <i data-feather="shopping-bag"></i>
                              </a>
                            </li>
                          </ul>
                        </div>

                        <!-- Content Box -->
                        <div class="content-box">
                          <a href="product.html">
                            <p>Christian Louboutin</p>
                            <h5>Concrete Jungle Pack</h5>
                            <span>$120.00</span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="swiper-slide">
                      <div class="product-card">
                        <div class="img-box">
                          <!-- Thumbnail -->
                          <ul class="thumbnail-img">
                            <li class="active thumb"><img src="assets/images/fashion/product/front/7.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-1.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-2.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-3.jpg" alt="thumbnail" /></li>
                          </ul>

                          <a href="product.html" class="primary-img"><img class="img-fluid bg-img" src="assets/images/fashion/product/front/9.jpg" alt="product" /> </a>

                          <!-- Arrow label -->
                          <span class="arrow-label-wrap"> <span class="arrow-label bg-theme-theme"> NEW</span> </span>

                          <!-- Rating -->
                          <div class="rating">
                            <span>4.5 <i data-feather="star"></i> </span>
                            <span>149</span>
                          </div>

                          <!-- Option -->
                          <ul class="option-wrap">
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Wishlist">
                              <a href="javascript:void(0)" class="wishlist-btn">
                                <i data-feather="heart"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="Compare">
                              <a href="compare.html"><i data-feather="repeat"></i></a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="View Product">
                              <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewModal">
                                <i data-feather="eye"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Cart">
                              <a href="javascript:void(0)" class="addtocart-btn">
                                <i data-feather="shopping-bag"></i>
                              </a>
                            </li>
                          </ul>
                        </div>

                        <!-- Content Box -->
                        <div class="content-box">
                          <a href="product.html">
                            <p>Oozio Style</p>
                            <h5>Lightweight water-resistant jacket</h5>
                            <span>$220.00</span> <del>$230.00</del>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="swiper-slide">
                      <div class="product-card">
                        <div class="img-box">
                          <!-- Thumbnail -->
                          <ul class="thumbnail-img">
                            <li class="active thumb"><img src="assets/images/fashion/product/front/7.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-1.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-2.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-3.jpg" alt="thumbnail" /></li>
                          </ul>

                          <a href="product.html" class="primary-img"><img class="img-fluid bg-img" src="assets/images/fashion/product/front/9.jpg" alt="product" /> </a>

                          <!-- Arrow label -->
                          <span class="arrow-label-wrap"> <span class="arrow-label bg-theme-theme"> NEW</span> </span>

                          <!-- Rating -->
                          <div class="rating">
                            <span>4.5 <i data-feather="star"></i> </span>
                            <span>149</span>
                          </div>

                          <!-- Option -->
                          <ul class="option-wrap">
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Wishlist">
                              <a href="javascript:void(0)" class="wishlist-btn">
                                <i data-feather="heart"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="Compare">
                              <a href="compare.html"><i data-feather="repeat"></i></a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="View Product">
                              <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewModal">
                                <i data-feather="eye"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Cart">
                              <a href="javascript:void(0)" class="addtocart-btn">
                                <i data-feather="shopping-bag"></i>
                              </a>
                            </li>
                          </ul>
                        </div>

                        <!-- Content Box -->
                        <div class="content-box">
                          <a href="product.html">
                            <p>Oozio Style</p>
                            <h5>Lightweight water-resistant jacket</h5>
                            <span>$220.00</span> <del>$230.00</del>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Man Tab Content End -->

              <!-- Kids Tab Content Start -->
              <div class="tab-pane fade" id="kids" role="tabpanel" aria-labelledby="kids-tab">
                <div class="swiper product-slider">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide">
                      <div class="product-card">
                        <div class="img-box">
                          <!-- Thumbnail -->
                          <ul class="thumbnail-img">
                            <li class="active thumb"><img src="assets/images/fashion/product/front/7.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-1.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-2.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-3.jpg" alt="thumbnail" /></li>
                          </ul>

                          <a href="product.html" class="primary-img"><img class="img-fluid bg-img" src="assets/images/fashion/product/front/5.jpg" alt="product" /> </a>

                          <!-- Rating -->
                          <div class="rating">
                            <span>4.5 <i data-feather="star"></i> </span>
                            <span>245</span>
                          </div>

                          <!-- Option -->
                          <ul class="option-wrap">
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Wishlist">
                              <a href="javascript:void(0)" class="wishlist-btn">
                                <i data-feather="heart"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="Compare">
                              <a href="compare.html"><i data-feather="repeat"></i></a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="View Product">
                              <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewModal">
                                <i data-feather="eye"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Cart">
                              <a href="javascript:void(0)" class="addtocart-btn">
                                <i data-feather="shopping-bag"></i>
                              </a>
                            </li>
                          </ul>
                        </div>

                        <!-- Content Box -->
                        <div class="content-box">
                          <a href="product.html">
                            <p>Chopard</p>
                            <h5>Shirt short mini dresses</h5>
                            <span>$120.00</span> <del>$150.00</del>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="swiper-slide">
                      <div class="product-card">
                        <div class="img-box">
                          <!-- Thumbnail -->
                          <ul class="thumbnail-img">
                            <li class="active thumb"><img src="assets/images/fashion/product/front/7.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-1.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-2.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-3.jpg" alt="thumbnail" /></li>
                          </ul>

                          <a href="product.html" class="primary-img"><img class="img-fluid bg-img" src="assets/images/fashion/product/front/6.jpg" alt="product" /> </a>

                          <!-- Rating -->
                          <div class="rating">
                            <span>4.5 <i data-feather="star"></i> </span>
                            <span>165</span>
                          </div>

                          <!-- Option -->
                          <ul class="option-wrap">
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Wishlist">
                              <a href="javascript:void(0)" class="wishlist-btn">
                                <i data-feather="heart"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="Compare">
                              <a href="compare.html" data-bs-toggle="modal"><i data-feather="repeat"></i></a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="View Product">
                              <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewModal">
                                <i data-feather="eye"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Cart">
                              <a href="javascript:void(0)" class="addtocart-btn">
                                <i data-feather="shopping-bag"></i>
                              </a>
                            </li>
                          </ul>
                        </div>

                        <!-- Content Box -->
                        <div class="content-box">
                          <a href="product.html">
                            <p>C & A</p>
                            <h5>Shirt short mini dresses</h5>
                            <span>$140.00</span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="swiper-slide">
                      <div class="product-card">
                        <div class="img-box">
                          <!-- Thumbnail -->
                          <ul class="thumbnail-img">
                            <li class="active thumb"><img src="assets/images/fashion/product/front/7.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-1.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-2.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-3.jpg" alt="thumbnail" /></li>
                          </ul>

                          <a href="product.html" class="primary-img"><img class="img-fluid bg-img" src="assets/images/fashion/product/front/7.jpg" alt="product" /> </a>

                          <!-- Arrow label -->
                          <span class="arrow-label-wrap"> <span class="arrow-label bg-theme-sun"> NEW</span> </span>

                          <!-- Rating -->
                          <div class="rating">
                            <span>4.5 <i data-feather="star"></i> </span>
                            <span>215</span>
                          </div>

                          <!-- Option -->
                          <ul class="option-wrap">
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Wishlist">
                              <a href="javascript:void(0)" class="wishlist-btn">
                                <i data-feather="heart"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="Compare">
                              <a href="compare.html"><i data-feather="repeat"></i></a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="View Product">
                              <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewModal">
                                <i data-feather="eye"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Cart">
                              <a href="javascript:void(0)" class="addtocart-btn">
                                <i data-feather="shopping-bag"></i>
                              </a>
                            </li>
                          </ul>
                        </div>

                        <!-- Content Box -->
                        <div class="content-box">
                          <a href="product.html">
                            <p>Bluezone</p>
                            <h5>Women’s long sleeve shirt</h5>
                            <span>$100.00</span> <del>$140.00</del>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="swiper-slide">
                      <div class="product-card">
                        <div class="img-box">
                          <!-- Thumbnail -->
                          <ul class="thumbnail-img">
                            <li class="active thumb"><img src="assets/images/fashion/product/front/7.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-1.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-2.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-3.jpg" alt="thumbnail" /></li>
                          </ul>

                          <a href="product.html " class="primary-img"><img class="img-fluid bg-img" src="assets/images/fashion/product/front/8.jpg" alt="product" /> </a>

                          <!-- Rating -->
                          <div class="rating">
                            <span>4.5 <i data-feather="star"></i> </span>
                            <span>136</span>
                          </div>

                          <!-- Option -->
                          <ul class="option-wrap">
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Wishlist">
                              <a href="javascript:void(0)" class="wishlist-btn">
                                <i data-feather="heart"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="Compare">
                              <a href="compare.html"><i data-feather="repeat"></i></a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="View Product">
                              <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewModal">
                                <i data-feather="eye"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Cart">
                              <a href="javascript:void(0)" class="addtocart-btn">
                                <i data-feather="shopping-bag"></i>
                              </a>
                            </li>
                          </ul>
                        </div>

                        <!-- Content Box -->
                        <div class="content-box">
                          <a href="product.html">
                            <p>Christian Louboutin</p>
                            <h5>Concrete Jungle Pack</h5>
                            <span>$120.00</span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="swiper-slide">
                      <div class="product-card">
                        <div class="img-box">
                          <!-- Thumbnail -->
                          <ul class="thumbnail-img">
                            <li class="active thumb"><img src="assets/images/fashion/product/front/7.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-1.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-2.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-3.jpg" alt="thumbnail" /></li>
                          </ul>

                          <a href="product.html" class="primary-img"><img class="img-fluid bg-img" src="assets/images/fashion/product/front/9.jpg" alt="product" /> </a>

                          <!-- Arrow label -->
                          <span class="arrow-label-wrap"> <span class="arrow-label bg-theme-theme"> NEW</span> </span>

                          <!-- Rating -->
                          <div class="rating">
                            <span>4.5 <i data-feather="star"></i> </span>
                            <span>149</span>
                          </div>

                          <!-- Option -->
                          <ul class="option-wrap">
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Wishlist">
                              <a href="javascript:void(0)" class="wishlist-btn">
                                <i data-feather="heart"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="Compare">
                              <a href="compare.html"><i data-feather="repeat"></i></a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="View Product">
                              <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewModal">
                                <i data-feather="eye"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Cart">
                              <a href="javascript:void(0)" class="addtocart-btn">
                                <i data-feather="shopping-bag"></i>
                              </a>
                            </li>
                          </ul>
                        </div>

                        <!-- Content Box -->
                        <div class="content-box">
                          <a href="product.html">
                            <p>Oozio Style</p>
                            <h5>Lightweight water-resistant jacket</h5>
                            <span>$220.00</span> <del>$230.00</del>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="swiper-slide">
                      <div class="product-card">
                        <div class="img-box">
                          <!-- Thumbnail -->
                          <ul class="thumbnail-img">
                            <li class="active thumb"><img src="assets/images/fashion/product/front/7.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-1.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-2.jpg" alt="thumbnail" /></li>
                            <li class="thumb"><img src="assets/images/fashion/product/front/7-3.jpg" alt="thumbnail" /></li>
                          </ul>

                          <a href="product.html" class="primary-img"><img class="img-fluid bg-img" src="assets/images/fashion/product/front/9.jpg" alt="product" /> </a>

                          <!-- Arrow label -->
                          <span class="arrow-label-wrap"> <span class="arrow-label bg-theme-theme"> NEW</span> </span>

                          <!-- Rating -->
                          <div class="rating">
                            <span>4.5 <i data-feather="star"></i> </span>
                            <span>149</span>
                          </div>

                          <!-- Option -->
                          <ul class="option-wrap">
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Wishlist">
                              <a href="javascript:void(0)" class="wishlist-btn">
                                <i data-feather="heart"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="Compare">
                              <a href="compare.html"><i data-feather="repeat"></i></a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="View Product">
                              <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#viewModal">
                                <i data-feather="eye"></i>
                              </a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Cart">
                              <a href="javascript:void(0)" class="addtocart-btn">
                                <i data-feather="shopping-bag"></i>
                              </a>
                            </li>
                          </ul>
                        </div>

                        <!-- Content Box -->
                        <div class="content-box">
                          <a href="product.html">
                            <p>Oozio Style</p>
                            <h5>Lightweight water-resistant jacket</h5>
                            <span>$220.00</span> <del>$230.00</del>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Kids Tab Content End -->
            </div>
            <!-- Tab Content End -->
          </div>
          <!-- Tabs End -->
        </div>
      </section>
      <!-- Top Product Section End -->

      <!-- New Arrived Section Start -->
      <section class="ratio_asos">
        <div class="container-lg">
          <div class="title-box">
            <h2 class="unique-heading">⚡ Productos Nuevos ⚡</h2>
            <button class="see-all">
              <router-link :to="'/shop/tag?text=nuevos'">Ver todos ➜</router-link>
            </button>
          </div>

          <Carousel v-bind="settings" :breakpoints="breakpoints">
            <Slide v-for="(product, index) in products"
                :key="index">
              <div class="carousel__item">
                <CardProductA v-if="product" :product="product"></CardProductA>
              </div>
            </Slide>

            <template #addons>
              <Navigation />
            </template>
          </Carousel>

          <!-- <div class="swiper product-slider">
            <div class="swiper-wrapper">
              
              <div class="swiper-slide" v-for="product in products" :key="product._id">
                <CardProductA v-if="product" :product="product" :is_not_detail="true"></CardProductA>
              </div>

            </div>
          </div> -->
        </div>
      </section>
      <!-- New Arrived Section End -->

      <!-- New Arrived Section Start -->
      <section class="ratio_asos">
        <div class="container-lg">
          <div class="title-box">
            <h2 class="unique-heading">👍 Productos Recomendados</h2>
            <button class="see-all">
              <router-link :to="'/shop/tag?text=destacado'">Ver todos ➜</router-link>
            </button>
          </div>

          <!-- <div class="swiper product-slider">
            <div class="swiper-wrapper">
              
              <div class="swiper-slide" v-for="product in product_recomendados" :key="product._id">
                <CardProductA v-if="product" :product="product" :is_not_detail="true"></CardProductA>
              </div>

            </div>
          </div> -->

          <Carousel v-bind="settings" :breakpoints="breakpoints">
            <Slide v-for="(product, index) in product_recomendados"
                :key="index">
              <div class="carousel__item">
                <CardProductA v-if="product" :product="product"></CardProductA>
              </div>
            </Slide>

            <template #addons>
              <Navigation />
            </template>
          </Carousel>

        </div>
      </section>
      <!-- New Arrived Section End -->

      <!-- New Arrived Section Start -->
      <section class="ratio_asos">
        <div class="container-lg">
          <div class="title-box">
            <h2 class="unique-heading">🔥 Los Más Hot De Zona</h2>
            <button class="see-all">
              <router-link :to="'/shop/best-sellers'">Ver todos ➜</router-link>
            </button>
          </div>

          <Carousel v-bind="settings" :breakpoints="breakpoints">
            <Slide v-for="(product, index) in product_hots_c"
                :key="index">
              <div class="carousel__item">
                <CardProductA v-if="product" :product="product"></CardProductA>
              </div>
            </Slide>

            <template #addons>
              <Navigation />
            </template>
          </Carousel>

          <!-- <div class="swiper product-slider">
            <div class="swiper-wrapper">
              
              <div class="swiper-slide" v-for="product in product_hots_c" :key="product._id">
                <CardProductA v-if="product" :product="product" :is_not_detail="true"></CardProductA>
              </div>

            </div>
          </div> -->
        </div>
      </section>
      <!-- New Arrived Section End -->

      <!-- Brand We Love Section Start -->
      <!-- <section class="brand-section pb-0 pan3 wow fadeInUp wow-section-overflow" data-wow-delay="0.3s">
        <div class="container-lg">
          <div class="title-box">
            <h2 class="unique-heading">ULTIMAS OFERTAS !</h2>
            <span class="title-divider1"><span class="squre"></span><span class="squre"></span></span>
            <p>Aprovecha estos descuentos exclusivos.</p>
          </div>
          <div class="section-wrap">
            <template v-for="(product_offerta, index) in product_hots_ofertas" :key="index">
              <div class="row gy-4 gy-sm-5 g-0 g-lg-4">
                <div class="col-lg-5 ratio_94">
                  <div class="brand-wrap">
                    <img class="img-fluid bg-img" :src="product_offerta.imagen" alt="banner" />
                    <div class="sale-card">
                      <div class="sale-card-wrap">
                        <div class="sale-name bg-theme-sun">
                          <h5><a :href="'/discount/'+product_offerta.slug" >{{ product_offerta.title }}</a> </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div class="col-lg-7">
                    <div class="swiper product-slider-offertas">
                      <div class="swiper-wrapper">
                        
                        <div class="swiper-slide" v-for="(product_off, index) in product_offerta.products.data" :key="index">
                          <CardProductA v-if="product_off" :product="product_off"></CardProductA>
                        </div>

                      </div>
                    </div>
                </div>
              </div>
            </template>

          </div>
        </div>
      </section> -->
      <!-- Brand We Love Section End -->

      <template v-for="(product_offerta, index) in product_hots_ofertas" :key="index">
        <section  class="border oferta ratio_asos">
          <div class=flex-marcas>
              <h3 class="title-oferta">
                <a :href="'/discount/'+product_offerta.slug"  :style="{'color': product_offerta.color_tag +'!important' }" >{{ product_offerta.title }}</a>
              </h3>
              <div class="back-to2">
                      <button class="btn" >
                          <a :style="{'background': product_offerta.color_tag +'!important' }" class="back-shop2" :href="'/discount/'+product_offerta.slug">Ver todos ➜</a>
                      </button>
                </div>
          </div>
          <div class="row ofertas-flex">  
          
              <div class="col-md-6 oferta-img"> 
                <!-- :src="product_offerta.imagen" -->
                  <!-- <img  
                  v-lazy="{ src: product_offerta.imagen }"
                   alt=""> -->
                   <img  
                  :src="product_offerta.imagen"
                   alt="">
                   <!-- <ProgressiveImage 
                   class="img-fluid bg-img" style="min-height: 332px;"  :src="product_offerta.imagen" /> -->
              </div>

              <div class="col-md-6 slider-ofertas">
                <Carousel v-bind="settings" :breakpoints="breakpointsOffert">
                  <Slide v-for="(product_off, index) in product_offerta.products.data" 
                    :key="index">
                    <div class="carousel__item">
                      <CardProductA v-if="product_off" :product="product_off"></CardProductA>
                    </div>
                  </Slide>

                  <template #addons>
                    <Navigation />
                  </template>
                </Carousel>
                  <!-- <div class="swiper product-slider-offertas">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(product_off, index) in product_offerta.products.data" :key="index">
                          <CardProductA v-if="product_off" :product="product_off"></CardProductA>
                        </div>
                      </div>
                  </div>
                -->
              </div>
          </div>
        </section>
      </template>

      <section class="marcas">
          <div class="title-box"> 
              <h2 class="unique-heading">Nuestras marcas</h2>
              <button class="see-all">
              <router-link :to="'/shop/brands'">Ver todos ➜</router-link>
            </button>
          </div>
          <div class="swiper marca-slider">
              <div class="swiper-wrapper">                 
                      <!-- <div >
                          <a :href="'/shop/brands/'+marca.id+'?text='+marca.name" class="brand-box"><img
                          class="img-fluid bg-white"
                          :src="marca.imagen"
                          alt="brand"></a>
                      </div> 
                    :src="marca.banner" 
                    :src="marca.imagen"
                    -->
                  
                  <template v-for="(marca, index) in marca_descatado" :key="index">
                    <div class="swiper-slide">
                      <router-link :to="'/shop/brands/'+marca.id+'?text='+marca.name" v-if="marca.banner" >
                        <img 
                        :src="marca.banner" 
                        height="100%" width="100%" alt="">
                      </router-link>

                      <router-link :to="'/shop/brands/'+marca.id+'?text='+marca.name" v-if="!marca.banner">
                        <img  
                        :src="marca.imagen"
                        height="100%" width="100%"  alt="">
                      </router-link>
                    </div>
                  </template>

              </div>
          </div>
      </section>

      <section class="videos">
        <div class="container-lg">
            <!-- <div class="title-box4">
                <h4 class="heading font-2xl">Videos (reels/historias)</h4>
            </div> -->

            <div class="row justify-content-center">

              <!-- <template
                      v-for="(video, index) in listVideo"
                      :key="index"
                      >
                <div class="col-3">
                  <a href="javascript:void(0)" @click="openVideoProduct(video)" data-bs-toggle="modal" data-bs-target="#viewModalVideo">
                      <video height="360" style="width: 100%;">
                          <source :src="video.video" type="video/mp4">
                      </video>
                    </a>
                </div>
              </template> -->

              <div class="col-12">
                <Carousel v-bind="settings" :breakpoints="breakpointsVideos">
                  <Slide v-for="(video, index) in listVideo"
                      :key="index">
                    <div class="carousel__item">
                      <!-- <a href="javascript:void(0)" > -->
                        <!-- <video height="360" style="width: 100%;">
                            <source :src="video.video" @click="openVideoProduct(video)" data-bs-toggle="modal" data-bs-target="#viewModalVideo" type="video/mp4">
                        </video> -->
                        <div v-html="video.embed"></div>
                      <!-- </a> -->
                    </div>
                  </Slide>

                  <template #addons>
                    <Navigation />
                  </template>
                </Carousel>
              </div>
              
            </div>

            <div class="follow-us">
                <p>Síguenos en Instagram <a href="https://www.instagram.com/zonadigitalsv/" target="_blank">
                        <img src="../assets/icons/svg/social/inta.svg" alt="Instagram"> zonadigitalsv </a></p>
            </div>

        </div>
      </section>
    </div>
        <!-- Service Section start -->
        <section class="service-section services-flex">
          <div class="container-lg">
              <div class="row g-3 g-md-4 g-lg-0">
                  <div class="col-6 col-lg-3">
                      <div class="service-box">
                          <div class="media">
                          <img class="img-fluid" src="/assets/images/features/envios-a-todo-el-pais.png" alt="Envios rápidos a todo el pais" />
                          </div>
                      </div>
                  </div>

                  <div class="col-6 col-lg-3">
                      <div class="service-box">
                          <div class="media">
                          <img class="img-fluid" src="/assets/images/features/nuevos-productos.png" alt="Nuevos productos todas las semanas" />
                          </div>
                      </div>
                  </div>

                  <div class="col-6 col-lg-3">
                      <div class="service-box">
                          <div class="media">
                          <img class="img-fluid" src="/assets/images/features/soporte-tecnico.png" alt="Soporte técnico" />
                              
                          </div>
                      </div>
                  </div>

                  <div class="col-6 col-lg-3">
                      <div class="service-box">
                          <div class="media">
                          <img class="img-fluid" src="/assets/images/features/seguridad-ssl.png" alt="Compras en línea seguras con certificado SSL" />
                              
                          </div>
                      </div>
                  </div>

              </div>
          </div>
        </section>

      <!-- Service Section End -->
    </main>
    <!-- Main End -->

    <!-- View Product Modal Start -->
    <VideoProduct />
    <!-- View Product Modal End -->

    <!-- <FooterSection /> -->

    <!-- Notification Start -->
    <!-- <div class="notification-wrap addToCart">
      <div class="notification">
        <img src="assets/images/fashion/product/front/1.jpg" alt="product" />
        <div>
          <h5>Added To Cart</h5>
        </div>
      </div>
    </div> -->
    <!-- Notification End -->

    <!-- Notification Start -->
    <!-- <div class="notification-wrap addToWishlist">
      <div class="notification">
        <img src="assets/images/fashion/product/front/1.jpg" alt="product" />
        <div>
          <h5>Added To Wishlist</h5>
        </div>
      </div>
    </div> -->
    <!-- Notification End -->

    <!-- View Product Modal Start -->
    <DetailProduct />
    <!-- View Product Modal End -->
    <!-- <ReviewProduct /> -->
    <!-- Cookies Section Start -->
    <div class="cookie-bar cookies-bar-1 left-cookies d-none">
      <img src="assets/png/cookie.png" alt="cookies" />
      <div class="content">
        <h5>Cookies Consent</h5>
        <p>This website use cookies to ensure you get the best experience on our website.</p>
        <div class="cookie-buttons">
          <button class="btn-solid btn-sm mb-line cookies-accept">Accept <i class="arrow"></i></button>
          <a href="javascript:void(0)" class="btn-solid btn-sm btn-outline cookies-accept">Learn more</a>
        </div>
      </div>
    </div>
    <!-- Cookie Section End -->

    <!-- Tap To Top Button Start -->
    <div class="tap-to-top-box hide">
      <button class="tap-to-top-button"><i data-feather="chevrons-up"></i></button>
    </div>
    <!-- Tap To Top Button End -->
  </div>
</template>

<script>
// import HeaderSection from './layouts/HeaderSection.vue'
// import FooterSection from './layouts/FooterSection.vue'
import myApi from '../plugins/axios'
import DetailProduct from '../views/guest/modal/DetailProduct.vue'
import CardProductA from '../components/cards/CardProductA.vue'
// import ReviewProduct from '../views/guest/modal/ReviewProduct.vue'
// import CartResource from '../resource/cart'
// import WishListResource from '../resource/wishlist'
// import CompareResource from '../resource/compare'
import store from '../plugins/store'
import VideoProduct from './modal/VideoProduct.vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default {
  name: 'HomeSection',
  components: {
    // HeaderSection,
    // FooterSection,
    DetailProduct,
    CardProductA,
    // ReviewProduct,
    VideoProduct,
     // 
     Carousel,
      Slide,
      Navigation,
  },
  props: {
    msg: String
  },
  data() {
    return {
      products: [],
      categories: [],
      CARTS: [],
      WISHLIST: [],
      user: null,
      banners: [],
      config_general: null,
      // 
      imagen_1_b: '/assets/images/banner/Banner_v2.png',
      imagen_2_b: '/assets/images/banner/elige_tusilla.png',
      imagen_3_b: '/assets/images/banner/PC_BUILD.png',
      imagen_4_b: '/assets/images/banner/Banner_principal.png',
      // 
      product_hots_c: [],
      product_recomendados: [],
      product_hots_ofertas: [],
      listVideo: [],
      marca_descatado: [],
      // 
      product_loades: [1,2,3,4,5],
      INDEXBDS: [],
      // 
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      breakpoints: {
        700: {
          itemsToShow: 3.5,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 5,
          snapAlign: 'start',
        },
      },
      breakpointsOffert: {
        700: {
          itemsToShow: 2,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
      },
      breakpointsVideos: {
        575: {
          itemsToShow: 2,
          snapAlign: 'center',
        },
        767: {
          itemsToShow: 2,
          snapAlign: 'center',
        },
        1200: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
      },
    }
  },
  methods: {
    
    todetalle() {
      this.$router.push({
        name: 'DetailProduct',
      })
    },
    reloadMyJs(){
      const script_myjs = document.createElement('script');
      script_myjs.src = '../../assets/js/myjs/myjs.js';
      document.body.appendChild(script_myjs);
      script_myjs.onload = () => {
        console.log('archivo.js cargado script_myjs');
      }
      const script2 = document.createElement('script');
      script2.src = '../../assets/js/feather/feather.min.js';
      document.body.appendChild(script2);
      script2.onload = () => {
      console.log('archivo.js cargado 2');
      }
    },
    reloadMyHomeJs(){
      const script_myjs = document.createElement('script');
      script_myjs.src = '../../assets/js/myjs/sliderhome.js';
      document.body.appendChild(script_myjs);
      script_myjs.onload = () => {
        console.log('archivo.js cargado script_myjs');
      }
      const script6 = document.createElement("script");
      script6.src = "../../assets/js/myjs/loadredirect.js";
      document.body.appendChild(script6);
      script6.onload = () => {
        console.log("archivo.js cargado 7");
      };
    },
    async allProducts(){
      let resp = await myApi.get('ecommerce/home');
      console.log(resp);
      this.products  = resp.data.products.data;
      this.product_hots_c = resp.data.product_hots_c.data;
      this.product_recomendados = resp.data.product_recomendados.data;
      // console.log(this.config_general);
      if(!this.config_general){
        this.allBanners();
      }
      this.allHomeOfertas();
      if(this.categories.length == 0){
        this.categories = resp.data.categories;
        this.marca_descatado = resp.data.marcas_destacadas;
        // this.$cookies.set('categories_i',this.categories);
        let data = {
            dat: {
              name: 'categories_i',
              data: JSON.stringify(this.categories),
            },
            name: 'ecommerce',
        }
        await this.$store.dispatch('saveDat',data);
        let data2 = {
            dat: {
              name: 'marca_descatado_i',
              data: JSON.stringify(this.marca_descatado),
            },
            name: 'ecommerce',
        }
        console.log("A");
        await this.$store.dispatch('saveDat',data2);
        // this.$cookies.set('marca_descatado_i',this.marca_descatado);
      }
      // setTimeout(() => {
      //   this.reloadMyJs();
      // }, 50);
    },
    async allBanners(){
      let resp = await myApi.get('ecommerce/banners');
      console.log(resp);
      this.banners = resp.data.banners;

      this.config_general = resp.data.config_general;

      this.$cookies.set('banners_img_b',this.banners);
      this.$cookies.set('config_general_img_b',this.config_general);

      if(resp.data.config_general.imagen_1_b){
        this.imagen_1_b = resp.data.config_general.imagen_1_b;
      }
      if(resp.data.config_general.imagen_2_b){
        this.imagen_2_b = resp.data.config_general.imagen_2_b;
      }
      if(resp.data.config_general.imagen_3_b){
        this.imagen_3_b = resp.data.config_general.imagen_3_b;
      }
      if(resp.data.config_general.imagen_4_b){
        this.imagen_4_b = resp.data.config_general.imagen_4_b;
      }
      this.listVideo = resp.data.videos;
      this.$cookies.set('videos_img_b',this.listVideo);
      setTimeout(() => {
        this.reloadMyHomeJs()
        this.reloadMyJs();
      }, 500);
    },
    openVideoProduct(video = 1){
      console.log(video);
      this.emitter.emit('product-video-modal', video);
    },
    async allHomeOfertas(){
      let resp = await myApi.get('ecommerce/home_ofertas');
      console.log(resp);
      this.product_hots_ofertas = resp.data.product_hots_d;
      setTimeout(() => {
        this.reloadMyHomeJs();
      }, 500);
    },
    actualizarMetaetiquetas(titulo, descripcion, imagen,url) {
      const ogTitle = document.querySelector('meta[property="og:title"]');
      const ogDescription = document.querySelector('meta[property="og:description"]');
      const ogImage = document.querySelector('meta[property="og:image"]');
      const ogUrl = document.querySelector('meta[property="og:url"]');
      
      ogTitle.content = titulo;
      ogDescription.content = descripcion ? descripcion : '';
      if(imagen){
        ogImage.content = imagen;
      }
      ogUrl.content = url;
    },
    isMobile() {
      if(window.innerWidth <= 767 || screen.width <= 767) {
          return true;
      }
      else {
          return false;
      }
    }
  },
  async created() {
    await this.$store.dispatch('getDats','ecommerce');
    // console.log(this.$store.state.dats);
    // if (this.isMobile()) {
    //     console.log("MOBILE")
    // }else {
    //   console.log("desktok")
    // }
  },
  async mounted() {
  
    // this.allBanners();
    // this.$cookies.set('EXAMPLE',{ id:1, name:'Journal',session:'25j_7Sl6xDq2Kc3ym0fmrSSk2xV2XkUkX' });
    // PARA EL CARRITO
    let banners_img_b = this.$cookies.get("banners_img_b") ? this.$cookies.get("banners_img_b") : null;
    let config_general_img_b = this.$cookies.get("config_general_img_b") ? this.$cookies.get("config_general_img_b") : null;
    let videos_img_b = this.$cookies.get("videos_img_b") ? this.$cookies.get("videos_img_b") : null;
    // console.log(banners_img_b,config_general_img_b,videos_img_b);
    if(banners_img_b && config_general_img_b && videos_img_b){
        this.banners = banners_img_b;
        this.config_general = config_general_img_b;
        if(this.config_general.imagen_1_b){
          this.imagen_1_b = this.config_general.imagen_1_b;
        }
        if(this.config_general.imagen_2_b){
          this.imagen_2_b = this.config_general.imagen_2_b;
        }
        if(this.config_general.imagen_3_b){
          this.imagen_3_b = this.config_general.imagen_3_b;
        }
        if(this.config_general.imagen_4_b){
          this.imagen_4_b = this.config_general.imagen_4_b;
        }
        this.listVideo = videos_img_b;
        setTimeout(() => {
          this.reloadMyHomeJs()
          this.reloadMyJs();
        }, 500);
    }
    this.actualizarMetaetiquetas(
      'Zona Digital - PC Gamers | Computadoras | Accesorios y Más',
      'Lo más nuevo en PC Gaming, Hardware, Accesorios, Gadgets, Celulares, Consolas de Videojuegos y otros. Solo las mejoras marcas.',
      null,'https://storeszd.webunify.cloud/'
    );
    // let categories_i = this.$cookies.get("categories_i") ? this.$cookies.get("categories_i") : null;
    // let marca_descatado_i = this.$cookies.get("marca_descatado_i") ? this.$cookies.get("marca_descatado_i") : null;
    // 
    this.INDEXBDS = this.$store.state.dats;
    setTimeout(() => {
      let categories_i =  this.INDEXBDS.find((idb) => idb.name == 'categories_i');
      let marca_descatado_i = this.INDEXBDS.find((idb) => idb.name == 'marca_descatado_i');
      if(categories_i && marca_descatado_i){
        this.categories = JSON.parse(categories_i.data);
        this.marca_descatado = JSON.parse(marca_descatado_i.data);
      }
      console.log("b");
    }, 200);

    setTimeout(() => {
      this.allProducts();
    }, 250);

    this.emitter.on('cart-shop',data => {
         this.CARTS = data;
    });
    this.emitter.emit("cart-list", {});

    //PARA LA LISTA DE DESEO
    this.emitter.on('wish-list-product',data => {
         this.WISHLIST = data;
    });
    this.emitter.emit("wish-list-list", {});

    this.user = store.state.user;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
.marca-slider img{
  /* height: 100% !important; */
}
</style>
