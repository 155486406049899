<template>
    <div>
        <!-- Overlay -->
        <a href="javascript:void(0)" class="overlay-general overlay-common"></a>
        <!-- Header Start -->
        <header class="header-common">
            <!-- Top Header -->
            <!-- <div class="top-header">
                <div class="container-lg">
                <div class="topheader-wrap">
                    <ul class="social-list">
                    <li>
                    <a class="help" href="tel:219098-874"><i data-feather="phone"></i> (219) 098-874</a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/"> <img src="/assets/icons/svg/social/fb.svg" alt="fb" /> </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/"> <img src="/assets/icons/svg/social/inta.svg" alt="instagram" /> </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/"> <img src="/assets/icons/svg/social/tw.svg" alt="tw" /></a>
                    </li>
                    <li>
                        <a href="https://youtube.com/"> <img src="/assets/icons/svg/social/youtube.svg" alt="youtube" /></a>
                    </li>
                    
                    <li>
                        <a href="https://tiktok.com/"> <img src="/assets/icons/svg/social/tiktok.svg" alt="tiktok" /> </a>
                    </li>
                    </ul>
                    
                    
                </div>
                </div>
            </div> -->
            <!-- <span> Summer sale for all swim suits - off 50%! Shop Now</span> -->
            <!-- <span ></span> -->
            <template v-if="cintillo">
                <div class="top-header header-top-header" v-if="!cintillo.imagen || cintillo.active_imagen == 2"
                :style="{ 'background-color' :  cintillo.color_tag + '!important' }">
                    <p class="marquee" v-html="cintillo.description">
                    </p>
                </div>
                <div class="top-header" v-if="cintillo.imagen && cintillo.active_imagen == 1" 
                :style="{ 'background-image': 'url('+cintillo.imagen+')','background-color' :  cintillo.color_tag + '!important' }"
                    style="background-size: 60%;background-position: center;padding: 20px;"
                >
                </div>
            </template>
            
            <div class="container-lg d-none d-sm-block">
                <div class="nav-wrap">
                    <!-- Navigation Start -->
                    <nav class="navigation">
                        <div class="nav-section">
                        <div class="header-section">
                            <div class="navbar navbar-expand-xl navbar-light navbar-sticky p-0">
                            <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#primaryMenu" aria-controls="primaryMenu">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <a href="/" class="logo-link">
                                <img class="logo logo-dark" src="/assets/images/logos/logo.png" alt="logo" />
                                <img class="logo logo-light" src="/assets/images/logos/logo-w.png" alt="logo" />
                            </a>

                            </div>
                        </div>
                        </div>
                    </nav>
                    <!-- Navigation End -->

                    <!-- Menu Right Start  -->
                    <div class="menu-right">
                        
                        <!-- Icon Menu Start -->
                        <ul class="icon-menu">
                        <li>
                            <div class="search-bar">
                                <div class="search">
                                    <div class="search__input-wrapper">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" class="feather feather-search">
                                                <circle cx="11" cy="11" r="8"></circle>
                                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                            </svg></span>

                                        <input type="text" class="search__input" name="search" v-model="search_products" @keyup.enter="toSearchFilter" @keyup="searchAutomatic()"
                                            placeholder="Buscar productos..." autocomplete="off" required>

                                        <button class="search__clear-btn" @click="clearSearch()" aria-label="Clear search input">
                                            <i class="ion-close"></i>
                                        </button>
                                        <!-- <span id="clearButton22" class="hideClearButton">X</span> -->
                                    </div>
                                    <i class="ion-ios-search search__icon"></i>
                                </div>
                                <div class="suggestion">
                                    <div class="suggestion__content">
                                        <div class="sugerencias" v-if="words_suggestions.length > 0">
                                            <h5>Sugerencias:</h5>
                                            <div class="flex-sugerencia">
                                                <div class="item-sugerencia" v-for="(product_sugge, index) in words_suggestions" :key="index">
                                                    <a href="javascript:void(0)" @click="selectSugg(product_sugge)">{{ product_sugge }}
                                                    </a>
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div class="flex-scroll" v-if="categorie_suggestions.length > 0 || product_suggestions.length > 0">
                                            <div class="suggestion__content-left-side">
                                                <ul>
                                                    <li v-for="(categ_s, index) in categorie_suggestions" :key="index"> 
                                                        <a href="javascript:void(0)"  @click="selectCatgg(categ_s)">
                                                        {{ categ_s.name }}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="suggestion__content-right-side">

                                                <template v-for="(product_sugges, index) in product_suggestions" :key="index">
                                                    <div class="product-cart media">
                                                        <img
                                                            :src="product_sugges.imagen"
                                                            class="img-fluid blur-up lazyload" alt="">
                                                        <div class="media-body"><a
                                                                :href="'/product/'+product_sugges.slug" @click="searchCookie()">
                                                                <h6>{{ product_sugges.title }}</h6>
                                                            </a>

                                                            <p class="mb-0 mt-1 price"> $ {{ product_sugges.precio_general }} </p>
                                                        </div>
                                                    </div>
                                                </template>
                                                

                                                <div class="all-products">
                                                    <a :href="'/search?text='+search_products">Ver todos los {{ total_products }} productos</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="historial" v-if="product_suggestions.length == 0">
                                            <h5>Últimas Búsquedas:</h5>
                                            <ul>
                                                <li v-for="(search_hist, index) in search_historials" :key="index"> 
                                                    <img src="assets/icons/png/historial.png" alt=""> <a href="javascript:void(0)" @click="selectSugg(search_hist)">
                                                        {{ search_hist }}
                                                    </a>
                                                </li>
                                                
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <div class="box-mode">
                            <li class="light-icon">
                                <a href="#" class="btnTheme" v-if="btnTheme == 1" @click="btnMode(2)" onclick="return false;" id="light-btn">
                                    <img src="/assets/images/Nuevos/mode-light.svg">
                                </a>
                            </li>

                            <li class="dark-icon">
                                <a href="#" class="btnTheme" v-if="btnTheme == 2" @click="btnMode(1)"  onclick="return false;" id="dark-btn" >
                                    <img src="/assets/images/Nuevos/mode-dark.svg">
                                </a>
                            </li>
                        </div>

                        <li>

                            <div class="dropdown shopingbag">
                                <!-- <span class="notification-label">{{ COMPARES.length }}</span> -->
                            <router-link to="/comparar-producto" class="cart-button"><img src="/assets/images/Nuevos/compare.svg"> </router-link>
                                <a href="javascript:void(0)" class="overlay-cart overlay-common"></a>
                                <div class="onhover-cart-dropdown">
                                    <div class="onhover-show-div text-center">
                                    <div class="dropdown-header">
                                        <div class="control">
                                            <router-link to="/comparar-producto">Comparación</router-link>
                                        <button class="back-cart"><i data-feather="arrow-right"></i></button>
                                        </div>
                                    </div>

                                    <div class="card-wrap custom-scroll">
                                        <template v-for="(WISH, index) in COMPARES" :key="index">
                                            <div class="cart-card media" >
                                                <a href="javascript:void(0)" @click="goDetailProduct(WISH)"> <img v-bind:src="WISH.imagen" class="img-fluid" alt="product" /> </a>
                                                <div class="media-body">
                                                    <a href="javascript:void(0)" @click="goDetailProduct(WISH)"> <h6>{{WISH.title}}</h6></a>
                                                    <span>$ {{WISH.precio_general}}  </span>
                                                </div>
                                                <button class="remove-cart" @click="removeCompare(WISH)"><i data-feather="x"></i></button>
                                            </div>
                                        </template>
                                    </div>
                                    <!-- <a href="#" onclick="return false;" style="display: flex;justify-content: center;align-items: center;" v-if="COMPARES.length == 0"> 
                                        <img src="/assets/images/product/zeta-notfound.svg" width="128" class="img-fluid" alt="box"> 
                                    </a> -->
                                    <img src="/assets/images/product/zeta-notfound.svg" v-if="COMPARES.length == 0" width="70" class="img-fluid" alt="box"> 
                                    <div class="content1" v-if="COMPARES.length == 0">
                                        <a href="#" onclick="return false;">
                                            <h6>Tu lista de comparación</h6>
                                        </a>
                                    </div>
                                    <div class="dropdown-footer" v-if="COMPARES.length > 0">
                                        <div class="freedelevery">
                                        </div>
                                        <div class="total-price">
                                        </div>
                                        <div class="btn-group block-group">
                                            <router-link to="/comparar-producto" class="btn-solid">Ver Todos</router-link>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>

                        </li>

                        <li>
                            <div class="dropdown shopingbag">
                                <!-- <span class="notification-label">{{ WISHLIST.length }}</span> -->
                                <router-link to="/lista-deseos" class="cart-button"><img src="/assets/images/Nuevos/favs.svg"> </router-link>
                                <a href="javascript:void(0)" class="overlay-cart overlay-common"></a>
                                <div class="onhover-cart-dropdown">
                                    <div class="onhover-show-div text-center">
                                    <div class="dropdown-header">
                                        <div class="control">
                                            <router-link to="/lista-deseos">Lista de deseo</router-link>
                                        <button class="back-cart"><i data-feather="arrow-right"></i></button>
                                        </div>
                                    </div>

                                    <div class="card-wrap custom-scroll">
                                        <template v-for="(WISH, index) in WISHLIST" :key="index">
                                            <div class="cart-card media" >
                                                <a href="javascript:void(0)" @click="goDetailProduct(WISH)"> <img v-bind:src="WISH.imagen" class="img-fluid" alt="product" /> </a>
                                                <div class="media-body">
                                                    <a href="javascript:void(0)" @click="goDetailProduct(WISH)"> <h6>{{WISH.title}}</h6></a>
                                                    <span>$ {{WISH.precio_general}}  </span>
                                                </div>
                                                <button class="remove-cart" @click="removeWish(WISH)"><i data-feather="x"></i></button>
                                            </div>
                                        </template>
                                    </div>
                                    <img src="/assets/images/product/zeta-notfound.svg" v-if="WISHLIST.length == 0" width="70" class="img-fluid" alt="box"> 
                                    <div class="content1" v-if="WISHLIST.length == 0">
                                        <a href="#" onclick="return false;">
                                            <h6>Tu lista de deseo</h6>
                                        </a>
                                    </div>
                                    
                                    <div class="dropdown-footer" v-if="WISHLIST.length > 0">
                                        <div class="freedelevery">
                                        </div>
                                        <div class="total-price">
                                        </div>
                                        <div class="btn-group block-group">
                                            <router-link to="/lista-deseos" class="btn-solid">Ver Todos</router-link>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!-- class="user" -->
                        <li>
                            <div class="dropdown user-dropdown">
                            <a href="javascript:void(0)"><img src="/assets/images/Nuevos/account.svg"></a>
                            <ul class="onhover-show-div">

                            <li v-if="!user"><a @click="tologin()">Iniciar sesión</a></li>
                            <li v-if="!user"><a @click="toregister()">Registrarse</a></li>
                            <li v-if="user"><a @click="profile()">Mi Perfil</a></li>
                            <li v-if="user"><a @click="logout()">Salir</a></li>

                            </ul>
                            </div>
                        </li>

                        <!-- Cart Menu Start -->
                        <li>
                            <div class="dropdown shopingbag">
                            <a href="javascript:void(0)" class="cart-button"><img src="/assets/images/Nuevos/cart.svg"> <span class="notification-label">{{ CARTS.length }}</span></a>
                                <a href="javascript:void(0)" class="overlay-cart overlay-common"></a>
                                <div class="onhover-cart-dropdown">
                                    <div class="onhover-show-div text-center">
                                    <div class="dropdown-header">
                                        <div class="control">
                                        <a :href="'/cart'">Carrito de compra</a>
                                        <button class="back-cart"><i data-feather="arrow-right"></i></button>
                                        </div>
                                    </div>

                                    <div class="card-wrap custom-scroll">
                                        <template v-for="(CART, index) in CARTS" :key="index">
                                            <div class="cart-card media" >
                                                <a href="javascript:void(0)" @click="goDetailProduct(CART)"> <img v-bind:src="CART.imagen" class="img-fluid" alt="product" /> </a>
                                                <div class="media-body">
                                                    <a href="javascript:void(0)" @click="goDetailProduct(CART)"> <h6>{{CART.title}}</h6></a>
                                                    <span>$ {{CART.total}}  </span>
                                                    <div class="plus-minus">
                                                    <a href="javascript:void(0)" @click="minusCart(CART)"> <i class="sub" data-feather="minus"></i> </a>
                                                        <input type="number" :value="CART.quantity" min="1" max="10" />
                                                    <a href="javascript:void(0)" @click="plusCart(CART)"> <i class="add" data-feather="plus"></i> </a>
                                                    </div>
                                                </div>
                                                <button class="remove-cart" @click="removeCart(CART)"><i data-feather="x"></i></button>
                                            </div>
                                        </template>
                                    </div>

                                    <!-- <a href="#" onclick="return false;" style="display: flex;justify-content: center;align-items: center;" v-if="CARTS.length == 0"> 
                                        <img src="/assets/images/product/zeta-notfound.svg" width="128" class="img-fluid" alt="box"> 
                                    </a> -->
                                    <img src="/assets/images/product/zeta-notfound.svg" v-if="CARTS.length == 0" width="70" class="img-fluid" alt="box"> 
                                    <div class="content1" v-if="CARTS.length == 0">
                                        <a href="#" onclick="return false;">
                                            <h6>Tu carrito de compra</h6>
                                        </a>
                                    </div>

                                    <div class="dropdown-footer" v-if="CARTS.length > 0">
                                        <div class="freedelevery">
                                        <p class="terms-condition">ENVIO GRATIS! Continua comprando mas productos y agregalos al carrito <strong>$500</strong></p>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style="width: 70%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">70%</div>
                                        </div>
                                        </div>
                                        <div class="total-price">
                                        <span>Total</span>
                                        <span>$ {{TOTAL_SUM}} </span>
                                        </div>

                                        <div class="btn-group block-group">
                                        <a href="javascript:void(0)" @click="goToCar()" class="btn-solid">Ver Carrito</a>
                                        <a href="javascript:void(0)" @click="goToCheckout()" class="btn-solid">Pagar</a>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!-- Cart Menu End -->
                        
                        

                        </ul>
                        <!-- Icon Menu End -->
                    </div>
                    <!-- Menu Right End  -->
                </div>
            </div>

            <div class="bg-theme-gray-light2 d-none d-sm-block">
                <div class="container-lg bottom-header">
                    <div class="nav-wrap p-0">
                        <!-- Navigation Start -->
                        <nav class="navigation">
                        <div class="nav-section">
                            <div class="header-section">
                            <div class="navbar navbar-expand-xl navbar-light navbar-sticky p-0">

                            <div class="offcanvas offcanvas-collapse order-lg-2" id="primaryMenu">
                                <div class="offcanvas-header navbar-shadow">
                                    <h5 class="mt-1 mb-0">Menu</h5>
                                    <button class="btn-close lead" type="button" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                </div>
                                <div class="offcanvas-body">
                                    <!-- Menu-->
                                    <ul class="navbar-nav">
                                        
                                        

                                    <li class="nav-item">
                                        <a class="nav-link btn-special" id="overMobile" href="javascript:void(0)" @mouseover="showMegaMenu = true" >Categorías</a>

                                        <!-- <button class="nav-link btn-special" id="toggleButton">Categorías</button>

                                        <div id="accordion" style="display: none;" >
                                            
                                            <div class="accordion-item" v-for="(departament, index) in categorie_cookies" :key="index">
                                                <div class="accordion-header"> <a :href="'/shop/categorie/'+departament.id+'?text='+departament.name"
                                                    > {{ departament.name }}</a>
                                                </div>
                                                <div class="accordion-content">
                                                    <ul>
                                                    
                                                        <li class="accordion-subitem" v-for="(categorie, index2) in departament.categories" :key="index2">
                                                            <div class="accordion-subheader">
                                                                <a class="title-sub" 
                                                                    :href="'/shop/categorie/'+departament.id+'?text='+departament.name+'&categorie_second_id='+categorie.id"
                                                                > {{ categorie.name }}</a>
                                                            </div>
                                                            <div class="accordion-subcontent">
                                                                <ul>
                                                                    <li v-for="(sub_categorie, index3) in categorie.categories" :key="index3">
                                                                        <a :href="'/shop/categorie/'+departament.id+'?text='+departament.name+'&categorie_third_id='+sub_categorie.id">
                                                                            {{ sub_categorie.name }}
                                                                        </a>
                                                                    </li>
                                                                
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            
                                        </div> -->
                                    </li>

                                    <li class="nav-item">
                                        <a class="nav-link"  href="/shop/categorie/2?text=Gamer">Zona Gamer</a>
                                    </li>

                                    <li class="nav-item">
                                        <a class="nav-link"  href="/discount" >Ofertas</a>
                                    </li>

                                    <li class="nav-item">
                                        <a class="nav-link"  href="/shop/tag?text=nuevo" >Nuevos</a>
                                    </li>

                                    <li class="nav-item">
                                        <a class="nav-link"  href="/shop/tag?text=reingreso" >Reingresos</a>
                                    </li>
                                    
                                    <li class="nav-item">
                                        <a class="nav-link"  href="/shop/brands" >Marcas</a>
                                    </li>

                                    <li class="nav-item">
                                        <a class="nav-link"  href="/search?text=pc gamer" >Pc Gamer</a>
                                    </li>
                                    <hr>
                                    <div class="box-mode box-mode-mobile d-sm-block d-md-none">
                                            <li class="light-icon">
                                            <a href="#" class="btnTheme-mobile" v-if="btnTheme == 1" @click="btnMode(2)" onclick="return false;" id="light-btn-mobile">
                                                <img src="/assets/icons/svg/sol.svg" alt=""></a>
                                        </li>

                                        <li class="dark-icon">
                                            <a href="#" class="btnTheme-mobile" v-if="btnTheme == 2" @click="btnMode(1)"  onclick="return false;" id="dark-btn-mobile" >
                                                <img src="/assets/icons/svg/luna.svg" alt="">
                                            </a>
                                        </li>
                                    </div>

                                    </ul>
                                </div>
                            </div>

                            </div>
                            </div>
                        </div>
                        </nav>
                        <!-- Navigation End -->
                    </div>
                </div>
            </div>

            <div class=" d-block d-sm-none">
                <div class="nav-wrap">
                    <!-- Navigation Start -->
                    <nav class="navigation">
                        <div class="nav-section">
                            <div class="header-section">
                            <div class="navbar navbar-expand-xl navbar-light navbar-sticky p-0">
                                <div class="box-mode box-mode-mobile-2 d-sm-block d-md-none">
                                    <!-- <li class="dark-icon">
                                        <a href="#">
                                        <img src="/assets/icons/svg/luna.svg" alt="">
                                        </a>
                                    </li> -->
                                    <li class="light-icon" v-if="btnTheme == 1">
                                        <a href="#" class="btnTheme-mobile" @click="btnMode(2)" onclick="return false;" id="light-btn-mobile">
                                            <img src="/assets/images/Nuevos/mode-light.svg"></a>
                                    </li>

                                    <li class="dark-icon" v-if="btnTheme == 2">
                                        <a href="#" class="btnTheme-mobile" @click="btnMode(1)"  onclick="return false;" id="dark-btn-mobile" >
                                             <img src="/assets/images/Nuevos/mode-dark.svg">
                                        </a>
                                    </li>
                                    <li>
                                        <div class="dropdown whislist-dropdown" bis_skin_checked="1">
                                        <router-link to="/lista-deseos">
                                            <img src="/assets/images/Nuevos/favs.svg">
                                            <!-- <span class="notification-label">{{ WISHLIST.length }}</span> -->
                                        </router-link>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="dropdown whislist-dropdown" bis_skin_checked="1">
                                        <router-link to="/comparar-producto">
                                            <img src="/assets/images/Nuevos/compare.svg">
                                            <!-- <span class="notification-label">{{ COMPARES.length }}</span> -->
                                        </router-link>
                                        </div>
                                    </li>
                                </div>
                                <a href="/" class="logo-link">
                                    <img class="logo logo-dark" src="/assets/images/logos/logo.png" alt="logo" />
                                    <img class="logo logo-light" src="/assets/images/logos/logo-w.png" alt="logo" />
                                </a>
                            </div>
                            </div>
                        </div>
                    </nav>
                    <!-- Navigation End -->
                    <!--off canvas-->
                    <div class="bg-theme-gray-light2">
                        <div class="container-lg bottom-header">
                            <div class="nav-wrap p-0">
                            <!-- Navigation Start -->
                            <nav class="navigation">
                                <div class="nav-section">
                                    <div class="header-section">
                                        <div class="navbar navbar-expand-xl navbar-light navbar-sticky p-0">
                                        <div class="offcanvas offcanvas-collapse order-lg-2" id="primaryMenu2">
                                            <!-- Mobile-->
                                            <div class="offcanvas-header navbar-shadow">
                                                <button class="btn-close lead" type="button" data-bs-dismiss="offcanvas"
                                                    aria-label="Close"></button>
                                            </div>
                                            <div class="offcanvas-body">
                                                <!-- Menu-->
                                                <ul class="navbar-nav">
                                                    <li class="nav-item d-sm-block d-md-none">
                                                    <div id="accordion">
                                                        <template v-for="(departament, index) in categorie_cookies" :key="index">
                                                            <div class="accordion-item">
                                                                <div class="accordion-header">{{ departament.name }}</div>
                                                                <div class="accordion-content">
                                                                    <ul>
                                                                        <template v-for="(categorie, index2) in departament.categories" :key="index2">
                                                                            <li class="accordion-subitem">
                                                                                <div class="accordion-subheader">{{ categorie.name }}</div>
                                                                                <div class="accordion-subcontent">
                                                                                    <ul>
                                                                                        <template v-for="(sub_categorie, index3) in categorie.categories" :key="index3">
                                                                                            <li><a 
                                                                                                :href="'/shop/categorie/'+departament.id+'?text='+sub_categorie.name+'&categorie_third_id='+sub_categorie.id+'&categorie_s_second_id='+categorie.id"
                                                                                                >{{ sub_categorie.name }}</a></li>
                                                                                        </template>
                                                                                        <!-- <li><a href="#">Nintendo</a></li>
                                                                                        <li><a href="#">XBOX</a></li> -->
                                                                                    </ul>
                                                                                </div>
                                                                            </li>
                                                                        
                                                                        </template>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </template>
                                                        
                                                        
                                                    </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                            <!-- Navigation End -->
                            </div>
                        </div>
                    </div>
                    <!--off canvas-->
                </div>
                <div id="fixedDiv" class="fixed-res">
                    <div class="container box-search">
                        <div class="searchBar">
                            <!-- v-model="search_products" -->
                            <input type="text" @input="event => search_products = event.target.value" v-model="search_products" @keyup.enter="toSearchFilter" @keyup="searchAutomatic()"
                             class="searchBarInput" name="search" autocomplete="off"
                            placeholder="Buscar productos" />
                            <span id="clearButton" class="hideClearButton" @click="clearSearch()">X</span>
                            <div class="iconSearchContainer">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-search">
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                </svg>
                            </span>
                            </div>
                        </div>
                        <div id="res" class="results hideResults">
                            <div class="suggestion__content">
                                <!-- Lo que escribes: {{ search_products }} -->
                                <div class="sugerencias" v-if="words_suggestions.length > 0">
                                    <h5>Sugerencias:</h5>
                                    <div class="flex-sugerencia">
                                        <div class="item-sugerencia" v-for="(product_sugge, index) in words_suggestions" :key="index">
                                            <a href="javascript:void(0)" @click="selectSugg(product_sugge)">{{ product_sugge }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="sugerencias" v-if="categorie_suggestions.length > 0 || product_suggestions.length > 0">
                                    <h5>Categorías:</h5>
                                    <div class="suggestion__content-left-side">
                                        <ul>
                                            <li v-for="(categ_s, index) in categorie_suggestions" :key="index"> 
                                                <a href="javascript:void(0)"  @click="selectCatgg(categ_s)">
                                                {{ categ_s.name }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="flex-scroll" v-if="categorie_suggestions.length > 0 || product_suggestions.length > 0">
                                    <div class="suggestion__content-right-side">
                                        <template v-for="(product_sugges, index) in product_suggestions" :key="index">
                                            <div class="product-cart media">
                                                <img
                                                    :src="product_sugges.imagen"
                                                    class="img-fluid blur-up lazyload" alt="">
                                                <div class="media-body"><a
                                                        :href="'/product/'+product_sugges.slug" @click="searchCookie()">
                                                        <h6>{{ product_sugges.title }}</h6>
                                                    </a>

                                                    <p class="mb-0 mt-1 price"> $ {{ product_sugges.precio_general }} </p>
                                                </div>
                                            </div>
                                        </template>

                                        <div class="all-products">
                                            <a :href="'/search?text='+search_products">Ver todos los {{ total_products }} productos</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="historial" v-if="product_suggestions.length == 0">
                                    <h5>Últimas Búsquedas:</h5>
                                    <ul v-if="product_suggestions.length == 0">
                                        <li v-for="(search_hist, index) in search_historials" :key="index"> 
                                            <img src="assets/icons/png/historial.png" alt=""> <a href="javascript:void(0)" @click="selectSugg(search_hist)">
                                                {{ search_hist }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="menu-cintillo">
                        <ul class="navbar-nav">
                            <div>
                            <li class="nav-item">
                                <button class="navbar-toggler nav-link btn-special" type="button" data-bs-toggle="offcanvas"
                                    data-bs-target="#primaryMenu2" aria-controls="primaryMenu2">
                                Categorías
                                </button>
                            </li>
                            </div>
                            <div class="menu-scroll">
                                <li class="nav-item">
                                    <a class="nav-link"  href="/shop/categorie/2?text=Gamer">Zona Gamer</a>
                                </li>

                                <li class="nav-item">
                                    <a class="nav-link"  href="/discount" >Ofertas</a>
                                </li>

                                <li class="nav-item">
                                    <a class="nav-link"  href="/shop/tag?text=nuevo" >Nuevos</a>
                                </li>

                                <li class="nav-item">
                                    <a class="nav-link"  href="/shop/tag?text=reingreso" >Reingresos</a>
                                </li>
                                
                                <li class="nav-item">
                                    <a class="nav-link"  href="/shop/brands" >Marcas</a>
                                </li>

                                <li class="nav-item">
                                    <a class="nav-link"  href="/search?text=pc gamer" >Pc Gamer</a>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>

        </header>
        <MegaMenu v-if="showMegaMenu" :categorie_cookies="categorie_cookies"/>
        <!-- <MenuMobile /> -->


    </div>
</template>

<script>
// import * as rr from '../../assets/js/myjs.js'
// import mitt from 'mitt'
// const emitter = mitt()
import store from '../../plugins/store'
import myApi from '../../plugins/axios'
import CartResource from '../../resource/cart'
import WishListResource from '../../resource/wishlist'
import { debounce } from "debounce";
import Typo from 'typo-js';
// import SpanishDict from '../../assets/dictionaries/es/es.dic';
// import SpanishAff from '../../assets/dictionaries/es/es.aff';
import keywords from './keywords'
import MegaMenu from "./MegaMenu";

export default {
    name: 'HeaderSection',
    data(){
        return {
            info: null,
            CARTS:[],
            TOTAL_SUM:0,
            WISHLIST:[],
            user: null,
            search_products: null,
            product_suggestions: [],
            categorie_suggestions: [],
            search_historials: [],
            groups_coupons: [],
            typo: new Typo('es', false, false, { dictionaryPath: "../../assets/dictionaries" }),
            keywords: keywords,
            words_suggestions: [],
            categ_products: null,
            // 
            showMegaMenu: false,
            COMPARES: [],
            total_products: 0,
            btnTheme: localStorage.getItem('body') == 'dark' ? 1 : 2,
            categorie_cookies: [],
            // 
            cintillo: null,
        }
    },
    components: {
        MegaMenu,
    },
    methods: {
        btnMode(val){
          this.btnTheme = val;
          setTimeout(() => {
              this.emitter.emit('dark-ligth', {});
          }, 50);
        },
        goToCar(){
            this.$router.push({
                name: 'CartShop',
            })
        },
        goToCheckout(){
            this.$router.push({
                name: 'Checkout',
            })
        },
        tohome() {
            this.$router.push({
                name: 'Home',
            })
        },
        toregister(){
            this.$router.push({
                name: 'Register',
            })
        },
        tologin(){
            this.$router.push({
                name: 'Login',
            })
        },
        logout(){
            store.dispatch('logout');
            setTimeout(() => {
                this.$router.push({
                    name: 'Login',
                })
            }, 50);
        },
        profile(){
            setTimeout(() => {
                this.$router.push({
                    name: 'ProfileClient',
                })
            }, 50);
        },
        goDetailProduct(CART){
            this.$router.push({
                name: 'DetailProduct',
                params: {
                slug: CART.slug,
                }
            })
            setTimeout(() => {
                location.reload();
            }, 50);
        },
        minusCart(CART){
            if(CART.quantity == 1){
                this.$notify({
                    title: "Producto stock del carrito",
                    text: `Al producto no se le puede disminuir mas!`,//'${CART.title}' 
                    type:'error',
                    duration: 2000,
                });
                return;
            }
            let INDEX = this.CARTS.findIndex(product => product.uniqd == CART.uniqd);
            
            if(INDEX != -1){
                // this.CARTS[INDEX].quantity = this.CARTS[INDEX].quantity - 1;
                // this.CARTS[INDEX].total =  this.CARTS[INDEX].subtotal * this.CARTS[INDEX].quantity;
                // this.$cookies.set('carts',this.CARTS);
                // this.emitter.emit('cart-shop', this.CARTS);
                CartResource.add(this.CARTS,this.CARTS[INDEX],this.emitter,this.$notify,this.$cookies,-1)
            }
        },
        plusCart(CART){
            let INDEX = this.CARTS.findIndex(product => product.uniqd == CART.uniqd);
            if(INDEX != -1){
                // this.CARTS[INDEX].quantity = this.CARTS[INDEX].quantity + 1;
                // this.CARTS[INDEX].total =  this.CARTS[INDEX].subtotal * this.CARTS[INDEX].quantity;
                // this.$cookies.set('carts',this.CARTS);
                // this.emitter.emit('cart-shop', this.CARTS);
                CartResource.add(this.CARTS,this.CARTS[INDEX],this.emitter,this.$notify,this.$cookies,1)
            }
        },
        removeCart(CART){
            // let CARTS = this.$cookies.get("carts") ? this.$cookies.get("carts") : [];
            let INDEX = this.CARTS.findIndex(product => product.uniqd == CART.uniqd);

    
            if(!store.getters.loggedIn){
                if(INDEX != -1){
                    this.CARTS.splice(INDEX,1);
                }
                this.$cookies.set('carts',this.CARTS);
                this.$notify({
                    title: "Producto removido del carrito",
                    // text: `El producto '${CART.title}' se elimino del carrito correctamente!`,
                    type: 'error',
                    duration: 2000,
                });
            }else{
                if(INDEX != -1){
                    this.deleteCart(this.CARTS[INDEX],INDEX);
                }
            }
            this.emitter.emit('cart-shop', this.CARTS);

        },
        removeWish(WISH){
            let INDEX = this.WISHLIST.findIndex(product => product.uniqd == WISH.uniqd);
            
            // if(INDEX != -1){
            //   this.WISHLIST.splice(INDEX,1);
            // }

            // this.$cookies.set('wishlist',this.WISHLIST);
            if(!store.getters.loggedIn){
                if(INDEX != -1){
                    this.WISHLIST.splice(INDEX,1);
                }
                this.$cookies.set('wishlist',this.WISHLIST);
                this.$notify({
                    title: "Producto removido de lista de deseos",
                    // text: `Tu producto '${WISH.title}' se elimino de la lista de deseo!`,
                    duration: 2000,
                    type:'error'
                });
            }else{
                if(INDEX != -1){
                    this.deleteWish(this.WISHLIST[INDEX],INDEX);
                }
            }
            this.emitter.emit('wish-list-product', this.WISHLIST);

            // this.$notify({
            //     title: "LISTA DE DESEOS",
            //     text: `TU PRODUCTO '${WISH.title}' SE ELIMINO DE LA LISTA DE DESEO!`,
            //     duration: 1000,
            //     type:'warn'
            // });
        },
        removeCompare(COMPARE){
            let INDEX = this.COMPARES.findIndex(product => product.uniqd == COMPARE.uniqd);
            
            if(INDEX != -1){
                this.COMPARES.splice(INDEX,1);
            }

            this.$cookies.set('compares',this.COMPARES);
            this.emitter.emit('compare-product', this.COMPARES);

            this.$notify({
                // title: "COMPARACIÓN DE PRODUCTOS",
                // text: `TU PRODUCTO '${COMPARE.title}' SE ELIMINO DE LA LISTA DE COMPARACIÓN!`,
                title: "Producto removido de comparar",
                // text: `Tu producto '${COMPARE.title}' se elimino de la lista de comparación!`,
                duration: 4000,
                type:'error'
            });
        },
        async listCarts(){
            try {
                const resp = await myApi.get(
                    "ecommerce/carts/"
                );
                let CARTS = [];
                console.log(resp);
                resp.data.carts.data.forEach(cart => {
                    CARTS.push(CartResource.update(cart));
                });
                this.emitter.emit("cart-shop", CARTS);
                this.groups_coupons =  resp.data.groups_coupons;
                this.emitter.emit("groups_coupons", resp.data.groups_coupons);
            } catch (error) {
                console.log(error);
            }
        },
        async updateCarts(carts){
            let data = {
                CARTS: carts,
            }
            try {
                const resp = await myApi.post('ecommerce/update_carts',data);
                console.log(resp);
                this.emitter.emit("cart-shop", resp.data.CARTS);
                this.$notify({
                    title: "CUPON",
                    text: `TU LISTA DE CARRITO SE ACTUALIZO CORRECTAMENTE , ELIMINANDO AL CUPON SELECCIONADO`,
                    duration: 2000,
                });
            } catch (error) {
                this.$notify({
                    title: "VALIDACIÓN",
                    text: "HUBO UN ERROR A NIVEL INTERNO, PORFAVOR COMUNICARSE CON SU PROVEEDOR",
                    duration: 2000,
                    type:'error',
                });
            }
        },
        async addCart(data,update=null){
            try {
                const resp = await myApi.post(
                    "ecommerce/carts/",data
                );
                console.log(resp.data)
                let INDEX = this.CARTS.findIndex(product => product.uniqd == data.uniqd);
                if(INDEX != -1){
                    this.CARTS[INDEX] = CartResource.update(resp.data.cart);
                }else{
                    this.CARTS.push(CartResource.update(resp.data.cart))
                }
                this.emitter.emit("cart-shop", this.CARTS);
                if(!update){
                    this.$notify({
                        title: "Producto agregado al carrito",
                        // text: `Tu producto '${resp.data.cart.product.title}' se agrego al carito de compra!`,
                        duration: 25000,
                        type: 'success'
                    });
                }else{
                    this.$notify({
                        title: "Producto agregado al carrito",
                        // text: `Tu producto '${resp.data.cart.product.title}' se actualizo el carrito de compra!`,
                        duration: 25000,
                        type: 'success'
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        async deleteCart(data,index){
            try {
                const resp = await myApi.delete(
                    "ecommerce/carts/"+data.cart_id
                );
                console.log(resp);
                if(index != -1){
                    this.CARTS.splice(index,1);
                }
                this.$notify({
                    title: "Producto removido del carrito",
                    // text: `Tu producto '${data.title}' se elimino del carrito!`,
                    type: 'error',
                    duration: 2000,
                });
            } catch (error) {
                console.log(error);
            }
        },
        async listWishList(){
            try {
                const resp = await myApi.get(
                    "ecommerce/wishlists/"
                );
                let WISHLIST = [];
                resp.data.wishlists.forEach(wish => {
                    WISHLIST.push(WishListResource.update(wish));
                });
                this.emitter.emit("wish-list-product", WISHLIST);
            } catch (error) {
                console.log(error);
            }
        },
        async addWishList(data){
            try {
                const resp = await myApi.post(
                    "ecommerce/wishlists/",data
                );
                let INDEX = this.WISHLIST.findIndex(product => product.uniqd == data.uniqd);
                if(INDEX != -1){
                    this.WISHLIST[INDEX] = WishListResource.update(resp.data.wishlist);
                }else{
                    this.WISHLIST.push(WishListResource.update(resp.data.wishlist))
                }
                this.emitter.emit("wish-list-product", this.WISHLIST);
                this.$notify({
                    title: "Producto agregado a lista de deseos",
                    // text: `Tu producto '${resp.data.wishlist.product.title}' se agrego a lista de deseo!`,
                    duration: 4000,
                    type: 'success',
                });
            } catch (error) {
                console.log(error);
            }
        },
        async deleteWish(data,index){
            try {
                const resp = await myApi.delete(
                    "ecommerce/wishlists/"+data.id
                );
                console.log(resp);
                if(index != -1){
                    this.WISHLIST.splice(index,1);
                }
                this.$notify({
                    title: "Producto removido de lista de deseos",
                    // text: `Tu producto '${data.title}' se elimino de la lista de deseo!`,
                    duration: 4000,
                    type:'error',
                });
            } catch (error) {
                console.log(error);
            }
        },
        searchCookie(){
            let SEARCHLIST = this.$cookies.get("searchlist") ? this.$cookies.get("searchlist") : [];
            SEARCHLIST.unshift(this.search_products);
            if(SEARCHLIST.length > 9){
                SEARCHLIST.pop();
            }
            this.$cookies.set("searchlist",SEARCHLIST);
        },
        selectSugg(product_sugge){
            this.search_products = product_sugge;
            this.searchAutomatic();
        },
        selectCatgg(categ_s){
            this.categ_products = categ_s;
            this.querySearch(this.search_products);
        },
        toSearchFilter(){
            this.searchCookie();
            // if(this.words_suggestions.length > 0){
            //     this.search_products = this.words_suggestions[0];
            // }
            window.location.href = "/search?text="+this.search_products;
        },
        async querySearch(suggest_words = null) {
            try {
                const resp = await myApi.post(
                    "ecommerce/search_products/",{
                        search: suggest_words ? suggest_words :this.search_products,
                        categ_products: this.categ_products,
                    }
                );
                console.log(resp);
                this.total_products = resp.data.total_products;
                this.product_suggestions = resp.data.products;
                this.categorie_suggestions = resp.data.categories;
                this.categ_products = null;
                setTimeout(() => {
                    const script2 = document.createElement('script');
                    script2.src = '../../assets/js/feather/feather.min.js';
                    document.body.appendChild(script2);
                    script2.onload = () => {
                        console.log('archivo.js cargado 2');
                    }
                }, 50);
            } catch (error) {
                console.log(error);
                this.$notify({
                    title: "SERVIDOR",
                    text: `PRESENTAMOS PROBLEMA EN EL SERVIDOR , COMUNICARSE CON EL PROVEEDOR DE LA WEB`,
                    duration: 1000,
                });
            }
        },
        searchAutomatic:  debounce(async function(){ 
            try {
                // this.search_historials = this.$cookies.get("searchlist") ? this.$cookies.get("searchlist") : [];
                // this.corrections = this.typo.check(this.inputText);
                if(this.search_products && this.search_products.length > 1){
                    this.words_suggestions = this.keywords.keywords().filter(item => item.indexOf(this.search_products) != -1);
                    if(this.words_suggestions.length == 0 && this.search_products.length < 12){
                        const correcciones = this.typo.suggest(this.search_products);
                        console.log(correcciones);
                        this.words_suggestions = correcciones;
                        // if(correcciones.length == 0){
                        //     this.querySearch(this.search_products);
                        // }else{
                        //     this.querySearch(this.words_suggestions[0]);
                        // }
                        this.querySearch(this.search_products);
                    }else{
                        if(this.search_products.length >= 12){
                            this.querySearch(this.search_products);
                        }else{
                            this.querySearch(this.words_suggestions[0]);
                        }
                    }
                }else{
                    this.words_suggestions = [];
                    this.product_suggestions = [];
                    this.categorie_suggestions = [];
                    this.total_products = 0;
                }
                // console.log(this.typo.check(this.search_products));
            } catch (error) {
                console.log(error);
            }
        }, 250),
        async getDepartaments(){
            try {
                const resp = await myApi.get(
                    "ecommerce/departaments/");
                console.log(resp);

                this.categorie_cookies = resp.data.categorias;
                setTimeout(() => {
                    // this.$cookies.set('categories_add',);
                    var script578 = document.createElement('script');
                        script578.src = '../../assets/js/myjs/submenumobile.js';
                        document.body.appendChild(script578);
                        script578.onload = () => {
                        console.log('archivo.js cargado 56');
                    }
                    localStorage.setItem("departaments",JSON.stringify(this.categorie_cookies));
                }, 50);
            } catch (error) {
                console.log(error);
                this.$notify({
                    title: "SERVIDOR",
                    text: `PRESENTAMOS PROBLEMA EN EL SERVIDOR , COMUNICARSE CON EL PROVEEDOR DE LA WEB`,
                    duration: 1000,
                });
            }
        },
        initDepartaments(){
            let CATEGORIES = localStorage.getItem("departaments") ? JSON.parse(localStorage.getItem("departaments")) : [];
            if(CATEGORIES.length == 0){
                this.getDepartaments()
            }else{
                this.categorie_cookies = CATEGORIES;
                setTimeout(() => {
                    var script578 = document.createElement('script');
                        script578.src = '../../assets/js/myjs/submenumobile.js';
                        document.body.appendChild(script578);
                        script578.onload = () => {
                            console.log('archivo.js cargado 56');
                        }
                }, 50);
            }
        },
        clearSearch(){
            this.search_products = null;
            this.searchAutomatic();
        }
    },
    beforeUnmount() {
        this.emitter.off("hide-mega-menu");
    },
    created() {
        // this.typo = new Typo('es', false, false, { dictionaryPath: "../../assets/dictionaries" });
        this.initDepartaments()
    },
    async mounted() {
        let cintillo_b = this.$cookies.get("cintillo_b") ? this.$cookies.get("cintillo_b") : null;

        if(!cintillo_b){
            const resp = await myApi.post('ecommerce/config_all',{});
            console.log(resp);
            this.cintillo = resp.data.cintillo;
            this.$cookies.set('cintillo_b',this.cintillo);
        }else{
            this.cintillo = cintillo_b;
        }

        this.emitter.on("hide-mega-menu", () => {
            this.showMegaMenu = false;
        });
        // CARRITO DE COMPRA
        let CARTS = [];
        if(!store.getters.loggedIn){
            CARTS = this.$cookies.get("carts") ? this.$cookies.get("carts") : [];
            setTimeout(() => {
                this.emitter.emit("cart-shop", CARTS);
            }, 50);
        }else{
            this.listCarts();
        }

        // LISTA EL CARRITO
        this.emitter.on('cart-shop',data => {
            // console.log(data);
         this.CARTS = data;
         this.TOTAL_SUM = this.CARTS.reduce((sum,cart) => sum+ cart.total,0);
          const script2 = document.createElement('script');
            script2.src = '../../assets/js/feather/feather.min.js';
            document.body.appendChild(script2);
            script2.onload = () => {
            console.log('archivo.js cargado 2');
            }
        });
        // GUARDA EL CARRITO
        this.emitter.on('save-cart',data => {
            this.addCart(data);
        });
        // MANDA LA LISTA A LOS COMPONENTES
        this.emitter.on('cart-list',() => {
            this.emitter.emit("cart-shop",this.CARTS);
            this.emitter.emit("groups_coupons", this.groups_coupons);
        });
        // ACTUALIZAR EL CARRITO 
        this.emitter.on('update-carts', data => {
            this.updateCarts(data);
        }) 
        // WISHLIST
        // let WISHLIST = this.$cookies.get("wishlist") ? this.$cookies.get("wishlist") : [];
        // this.WISHLIST = WISHLIST;
        let WISHLIST = [];
        if(!store.getters.loggedIn){
            setTimeout(() => {
                WISHLIST = this.$cookies.get("wishlist") ? this.$cookies.get("wishlist") : [];
                this.emitter.emit("wish-list-product", WISHLIST);
            }, 100);
        }else{
            this.listWishList();
        }
        this.emitter.on('wish-list-product',data => {
         this.WISHLIST = data;
        });

        // GUARDA EL WISHLIST
        this.emitter.on('save-wish-list',data => {
            this.addWishList(data);
        });
        // MANDA LA LISTA A LOS COMPONENTES
        this.emitter.on('wish-list-list',() => {
            this.emitter.emit("wish-list-product",this.WISHLIST);
        });
        // 

        this.user = store.state.user;

        this.search_historials = this.$cookies.get("searchlist") ? this.$cookies.get("searchlist") : [];
        // 

        let COMPARES = this.$cookies.get("compares")
        ? this.$cookies.get("compares")
        : [];

        this.COMPARES = COMPARES;

        this.emitter.on("compare-product", (data) => {
            this.COMPARES = data;
        });

        var script56 = document.createElement('script');
        script56.src = '../../assets/js/myjs/submenu.js';
        document.body.appendChild(script56);
        script56.onload = () => {
            console.log('archivo.js cargado 56');
        }
    }
}
</script>
<style>
.shopingbag .onhover-show-div .card-wrap{
    height: auto;
    max-height: 342px !important;
    /* overflow: scroll; */
}
.label{
    color: black;
}
.header-top-header p{
    margin-bottom: 0px !important;
}

#toggleButton{
    display: none;
}
#overMobile{
    display: block;
}
@media screen and (max-width: 575px) {
    #toggleButton{
        display: block;
    }
    #overMobile{
        display: none;
    }
}
@media screen and (max-width: 450px) {
    #toggleButton{
        display: block;
    }
    #overMobile{
        display: none;
    }
}
@media screen and (max-width: 308px) {
    #toggleButton{
        display: block;
    }
    #overMobile{
        display: none;
    }
}
</style>