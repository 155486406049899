<template>
    <!-- Main Start -->
    <div class="main">
      <!-- Otp Section Start -->
      <section class="page-body p-0">
        <div class="row">
          <div class="order-2 order-lg-1 col-lg-5">
            <div class="content-box">
              <div>
                <h5>Verificación de codigo </h5>
                <p class="font-md content-color">Hemos enviado el código de verificación a su dirección de correo electrónico.</p>

                <span class="font-md edit" v-if="cookie">
                   {{ cookie.email }} <router-link to="/recuperar-credenciales" > <i data-feather="edit-3"></i></router-link></span>

                <form action="reset-password.html" class="custom-form form-pill">
                  <div class="input-box1 otp-input">
                    <input class="otp form-control" placeholder="0" type="text" required v-model="text_a" maxlength="5" />
                    <!-- <input class="otp form-control" placeholder="0" type="text" required v-model="text_b" maxlength="1" />
                    <input class="otp form-control" placeholder="0" type="text" required v-model="text_c" maxlength="1" />
                    <input class="otp form-control" placeholder="0" type="text" required v-model="text_d" maxlength="1" />
                    <input class="otp form-control" placeholder="0" type="text" required v-model="text_e" maxlength="1" /> -->
                  </div>

                  <button type="button" @click="verifiedCode" v-if="!loading" class="btn-solid rounded-pill line-none">Enviar Codigo <i class="arrow"></i></button>
                  <div class="loader-5 center" v-if="loading"><span></span></div>
                  <a href="/" class="btn-solid rounded-pill line-none btn-outline mt-3 d-flex justify-content-center">Inicio <i class="arrow"></i></a>
                </form>

                <!-- Count Down Start -->
                <!-- <div class="otp-countdown text-center">
                  <div class="content-color d-flex gap-2">
                    <a href="javascript:void(0)" class="resend-otp">Resend OTP </a> <span class="time d-flex gap-2"> In <span id="timer"></span></span>
                  </div>
                </div> -->
                <!-- Count Down End -->
              </div>
            </div>
          </div>

          <div class="order-1 order-lg-2 col-lg-7">
            <div class="img-box">
              <img class="bg-img" src="/assets/images/bg/bg-zd-000.jpg" alt="banner" />
            </div>
          </div>
        </div>
      </section>
      <!-- Otp Section End -->
    </div>
    <!-- Main End -->
</template>
<script>
import myApi from '../../../plugins/axios'
export default {
    name:'CodeOtp',
    data(){
        return {
            code:null,
            cookie: null,
            text_a: null,
            text_b: null,
            text_c: null,
            text_d: null,
            text_e: null,
            loading: false,
        }
    },
    methods: {
        async verifiedCode(){
          // ||
          //   !this.text_b ||
          //   !this.text_c ||
          //   !this.text_d ||
          //   !this.text_e
            if(!this.text_a ){
                this.$notify({
                        title: "VALIDACIÓN",
                        text: `NECESITAS INGRESAR TODOS LOS CAMPOS`,
                        duration: 3500,
                        type:'error'
                    });
                return;
            }

            try {
              // + this.text_b + this.text_c + this.text_d + this.text_e
                this.code = this.text_a ;
                this.loading = true;
                const resp = await myApi.post(
                    "ecommerce/verified_code/",
                    {
                        email: this.cookie.email,
                        code: this.code,
                    }
                );
                this.loading = false;
                console.log(resp);
                if(resp.data.message == 403){
                    this.$notify({
                        title: "VALIDACIÓN",
                        text: `${resp.data.message_text}`,
                        duration: 3500,
                        type:'error'
                    });
                }else{
                    this.$notify({
                        title: "CODIGO",
                        text: `EL CODIGO HA SIDO VALIDADO CORRECTAMENTE`,
                        duration: 3500,
                    });
                    this.$cookies.set("code_password",{email: this.cookie.email,code: this.code},"30MIN");
                    setTimeout(() => {
                        this.$router.push({
                            name: 'ResetPassword'
                        });
                    }, 50);
                }
            } catch (error) {
                this.$notify({
                    title: "INTERNO",
                    text: `SURGIO UN PROBLEMA EN EL SERVIDOR , COMUNICARSE CON EL SOPORTE DE LA PAGINA`,
                    duration: 3500,
                    type:'error'
                });
                console.log(error);
            }
        },
    },
    created(){
        this.cookie = this.$cookies.get("forgot_password") ? this.$cookies.get("forgot_password") : null;
        if(!this.cookie){
            this.$router.push({
                name: 'ForgotPassword',
            });
        }
        console.log(this.cookie);
    }
}
</script>