<template>
  <div>
    <!-- Main Start -->
    <main class="main">
      <!-- Breadcrumb Start -->
      <div class="breadcrumb-wrap">
        <div class="banner b-top bg-size">
          <img
            class="bg-img bg-top"
            src="/assets/images/inner-page/banner-p.jpg"
            alt="banner"
          />

          <div class="container-lg">
            <div class="breadcrumb-box">
              <div class="heading-box">
                <h1>Comparación de productos</h1>
              </div>
              <ol class="breadcrumb">
                <li><a href="/">Inicio</a></li>
                <li>
                  <a href="javascript:void(0)"
                    ><i data-feather="chevron-right"></i
                  ></a>
                </li>
                <li><a href="javascript:void(0)">Comparar</a></li>
                <!-- <li>
                  <a href="javascript:void(0)"
                    ><i data-feather="chevron-right"></i
                  ></a>
                </li>
                <li class="current"><a href="compare.html">Compare</a></li> -->
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- Breadcrumb End -->

      <!-- Compare Section Start -->
      <section>
        <div class="container-lg">
          <div class="row">
            <div class="col-12">
              <div class="comparemodal-body">
                <div class="table-wrapper table-responsive ratio_asos">
                  <table class="table table-compare">
                    <thead>
                      <tr>
                        <th></th>
                        <template
                          v-for="(COMPARE, index) in COMPARES"
                          :key="index"
                        >
                            <th class="remove" @click="removeCompare(COMPARE)"> X</th>
                        </template>
                        <!-- <th class="remove">Remove</th>
                        <th class="remove">Remove</th>
                        <th class="remove">Remove</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="table-product-details">
                        <td></td>

                        <template
                          v-for="(COMPARE, index) in COMPARES"
                          :key="index"
                        >
                          <td>
                            <div class="product-box">
                              <div class="product-image">
                                <a class="w-100 compare-image bg-size" :href="'/product/'+COMPARE.slug">
                                  <img
                                    :src="COMPARE.imagen"
                                    class="img-fluid bg-img"
                                    alt=""
                                  />
                                </a>
                              </div>

                              <div class="product-details">
                                <div class="product-title">
                                  <a :href="'/product/'+COMPARE.slug">
                                    <h6 class="truncate">
                                      {{ COMPARE.title }}
                                    </h6>
                                  </a>
                                </div>
                                <span class="font-light"
                                  >${{ COMPARE.precio_general }}</span
                                >
                                <!-- <del class="theme-color">$100.00</del> -->
                                <p class="content-color truncate-2">
                                  {{ COMPARE.summary }}
                                </p>
                                <!-- <a class="theme-color" href="product.html"
                                  >More Details...</a
                                > -->

                                <a
                                  href="javascript:void(0)"
                                  class="btn-solid btn-sm"
                                  @click="addCart(COMPARE)"
                                  >Agregar al carrito<i class="arrow"></i
                                ></a>
                              </div>
                            </div>
                          </td>
                        </template>
                      </tr>

                      <tr>
                        <td style="text-transform: none;">Rating</td>
                        <template
                          v-for="(COMPARE, index) in COMPARES"
                          :key="index"
                        >
                          <td>
                            <div class="d-flex align-items-center my0">
                              <ul class="rating">
                                <li>
                                  <i :class="{'fill': COMPARE.avg_rating >= 1}"  data-feather="star"></i>
                                </li>
                                <li>
                                  <i :class="{'fill': COMPARE.avg_rating >= 2}"  data-feather="star"></i>
                                </li>
                                <li>
                                  <i :class="{'fill': COMPARE.avg_rating >= 3}"  data-feather="star"></i>
                                </li>
                                <li>
                                  <i :class="{'fill': COMPARE.avg_rating >= 4}"  data-feather="star"></i>
                                </li>
                                <li>
                                  <i :class="{'fill': COMPARE.avg_rating >= 5}" data-feather="star"></i>
                                </li>
                              </ul>

                              <span class="font-light ms-2">({{ COMPARE.reviews_count }} reseñas)</span>
                            </div>
                          </td>
                        </template>
                      </tr>
                    
                      <template  v-for="(OPTION, index) in OPTIONS" :key="index">
                            <tr>
                                <td>{{OPTION.attribute_categorie.attribute.name}}</td>
                                <template
                                    v-for="(COMPARE, index) in COMPARES"
                                    :key="index"
                                    >
                                    <td>{{ getAttribute(COMPARE,OPTION) }}</td>
                                </template>
                            </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Compare Section End -->
    </main>
    <!-- Main End -->
  </div>
</template>
<script>
import myApi from '../../plugins/axios'
import CartResource from '../../resource/cart'
export default {
  name: "CompareProduct",
  data() {
    return {
      COMPARES: [],
      OPTIONS:[],
      ATTRIBUTES:[],
      CARTS: [],
    };
  },
  methods: {
    async allActionCompare(){
      let products = [];
      this.COMPARES.forEach(product => {
        products.push(product.uniqd);
      });
      let resp = await myApi.post('ecommerce/home-compare',{products:products});
      console.log(resp);
      this.OPTIONS = resp.data.OPTIONS;
      this.ATTRIBUTES = resp.data.ATTRIBUTES;
    },
    getAttribute(COMPARE,OPTION){
        let ATTRIBUTE = this.ATTRIBUTES.find(item => item.uniqd == COMPARE.uniqd && item.attribute_categorie_id == OPTION.attribute_categorie_id)
        if(ATTRIBUTE){
            return (ATTRIBUTE.value ? ATTRIBUTE.value : ATTRIBUTE.attribute_sub.name)+" "+(ATTRIBUTE.attribute_categorie.attribute.value ? ATTRIBUTE.attribute_categorie.attribute.value : "");
        }
        return "";
    },
    addCart(COMPARE){
        let CARTS = this.CARTS;//this.$cookies.get("carts") ? this.$cookies.get("carts") : [];
        CartResource.add(CARTS,COMPARE,this.emitter, this.$notify,this.$cookies,1);
        // let CARTS = this.$cookies.get("carts") ? this.$cookies.get("carts") : [];
        // // console.log(CARTS);
        // let INDEX = CARTS.findIndex(product => product.uniqd == COMPARE.uniqd);
        
        // if(INDEX != -1){
        //     CARTS[INDEX].quantity = CARTS[INDEX].quantity + 1;
        //     CARTS[INDEX].total =  CARTS[INDEX].subtotal * CARTS[INDEX].quantity;
        // }else{
        //     CARTS.push({
        //         title: COMPARE.title ,
        //         imagen: COMPARE.imagen,
        //         uniqd: COMPARE.uniqd ,
        //         slug: COMPARE.slug ,
        //         quantity: 1,
        //         price_unit: Math.round(COMPARE.precio_general),
        //         subtotal: Math.round(COMPARE.precio_general),
        //         total: Math.round(COMPARE.precio_general) * 1,
        //         type_discount: null,
        //         discount: null,
        //         code_cupon: null,
        //         code_discount: null,
        //     });
        // }

        // this.$cookies.set('carts',CARTS);

        // this.emitter.emit('cart-shop', CARTS);

        // this.$notify({
        //     title: "CARRITO DE COMPRA",
        //     text: `TU PRODUCTO '${COMPARE.title}' SE AGREGO AL CARRITO CORRECTAMENTE!`,
        //     duration: 1000,
        // });
    },
    removeCompare(COMPARE){
        let INDEX = this.COMPARES.findIndex(product => product.uniqd == COMPARE.uniqd);
        
        if(INDEX != -1){
            this.COMPARES.splice(INDEX,1);
        }

        this.$cookies.set('compares',this.COMPARES);
        this.emitter.emit('compare-product', this.COMPARES);

        this.$notify({
            // title: "COMPARACIÓN DE PRODUCTOS",
            // text: `TU PRODUCTO '${COMPARE.title}' SE ELIMINO DE LA LISTA DE COMPARACIÓN!`,
            title: "Producto removido de comparar",
            // text: `Tu producto '${COMPARE.title}' se elimino de la lista de comparación!`,
            duration: 4000,
            type:'error'
        });
    },
  },
  created() {},
  mounted() {
    let COMPARES = this.$cookies.get("compares")
      ? this.$cookies.get("compares")
      : [];
    this.COMPARES = COMPARES;
    this.emitter.on("compare-product", (data) => {
      this.COMPARES = data;
    });
    this.emitter.on('cart-shop',data => {
         this.CARTS = data;
    });
    this.emitter.emit("cart-list", {});
    setTimeout(() => {
        this.allActionCompare();
    }, 25);
    // this.OPTIONS = 
  },
};
</script>
<style>
.compare-image{
  height: 307px !important;
}
</style>